import React from 'react';
import PropTypes from 'prop-types';

const listItemClass = (disabled, active = false) =>
  `page-item ${disabled ? 'disabled' : ''} ${active ? 'active' : ''}`;

function ListItem({ text, value = text, disabled, active, onClick }) {
  return (
    <li
      disabled
      onClick={() => onClick({ page: value })}
      className={listItemClass(disabled, active)}
    >
      <a className="page-link" href="#">
        {text}
      </a>
    </li>
  );
}

ListItem.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  active: PropTypes.bool
};

ListItem.defaultProps = {
  onClick: () => {},
  text: undefined,
  value: undefined,
  disabled: false,
  active: false
};

export default ListItem;
