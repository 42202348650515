import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useUserContext } from 'contexts/Users';

function PrivateRoute({ component, access, ...rest }) {
  const { isAuthenticated, userPermission, isLoading } = useUserContext();

  const shouldRedirect = () => {
    const redirect = access && access.includes(userPermission) && isAuthenticated;
    return redirect;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (shouldRedirect()) {
          return React.createElement(component, props);
        }
        if (!isLoading) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  access: PropTypes.array,
  location: PropTypes.any
};

export default PrivateRoute;
