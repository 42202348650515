import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';

function SelectState({
  selectedState,
  selectedMultiState,
  setSelectedState,
  setSelectedMultiState,
  isMulti,
  isDisabled,
  className
}) {
  const states = [
    { label: 'new', value: 'NEW' },
    { label: 'discarded', value: 'DISCARDED' },
    { label: 'ready for cleaning', value: 'READY-FOR-CLEANING' },
    { label: 'clean', value: 'CLEAN' },
    { label: 'unknown', value: 'UNKNOWN' },
    { label: 'known', value: 'KNOWN' }
  ];

  const valueSelect = isMulti ? selectedMultiState : selectedState;
  const onSelectChange = isMulti ? setSelectedMultiState : setSelectedState;

  return (
    <Select
      value={valueSelect}
      onChange={onSelectChange}
      options={states}
      isDisabled={isDisabled}
      placeholder="Select State"
      className={className}
      isSearchable
      isClearable
      isMulti={isMulti}
      name="selectState"
    />
  );
}

SelectState.propTypes = {
  selectedState: PropTypes.object,
  selectedMultiState: PropTypes.array,
  setSelectedState: PropTypes.func,
  setSelectedMultiState: PropTypes.func,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool
};

SelectState.defaultProps = {
  selectedState: null,
  selectedMultiState: [],
  isMulti: false,
  className: '',
  isDisabled: false
};

export default SelectState;
