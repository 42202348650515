import React from 'react';
import { Card, Table, Container, Row, Col } from 'react-bootstrap';
import { getTaskResults } from 'api/taskResults';
import ListPaginator, { PAGE_SIZE } from 'components/Paginators/ListPaginator';
import ArrayDropdown from 'components/Buttons/ArrayDropdown';

let timeoutHandle;

function TaskResults() {
  const adjustDate = (date_created) => date_created.split('.')[0].replace('T', ' ');

  const TaskStatus = ['PENDING', 'SUCCESS', 'FAILURE'];

  const [state, setState] = React.useState({
    count: 0,
    page: 1,
    results: [],
    search: '',
    status: undefined
  });

  const setRequestParameters = ({
    page = state.page,
    status = state.status,
    search = state.search
  }) => {
    const parameters = {
      page,
      page_size: PAGE_SIZE
    };

    if (status) {
      parameters.status = status;
    }

    if (search) {
      parameters.search = search;
    }

    return parameters;
  };

  const loadTaskResults = (parameters) => {
    const params = setRequestParameters(parameters);

    getTaskResults(params)
      .then((response) => response.json())
      .then((data) => setState((prev) => ({ ...prev, ...data, page: params.page })));
  };

  const filterCallback = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value, page: 1 }));
    loadTaskResults({ [key]: value, page: 1 });
  };

  const onSearch = (value) => {
    setState((prev) => ({ ...prev, search: value }));

    clearTimeout(timeoutHandle);
    timeoutHandle = setTimeout(() => loadTaskResults({ search: value, page: 1 }), 500);
  };

  React.useEffect(() => loadTaskResults({}), []);

  const onClick = (item) => {
    filterCallback('status', item);
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <div className="row pb-2">
            <div className="col-xs-4">
              <ArrayDropdown onClick={onClick} defaultValue="Select Status" items={TaskStatus} />
            </div>
            <div className="col-xs-8 col-xl-6">
              <input
                type="search"
                onChange={(event) => onSearch(event.target.value)}
                className="form-control"
                name="label"
                placeholder="Seach by task name or task id"
                value={state.search}
              />
            </div>
          </div>
        </Col>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Task results (Total: {state.count})</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-4">
              {state.count > PAGE_SIZE && (
                <ListPaginator
                  total={state.count}
                  current={state.page}
                  previous={state.previous}
                  next={state.next}
                  changePage={loadTaskResults}
                />
              )}
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th className="border-0">Task Id</th>
                    <th className="border-0">Result</th>
                    <th className="border-0">Status</th>
                    <th className="border-0">Date Created</th>
                    <th className="border-0">Task Name</th>
                  </tr>
                </thead>
                <tbody>
                  {state.results.map((item) => (
                    <tr key={item.task_id}>
                      <td>{item.task_id}</td>
                      <td>{item?.result.replace(/(.{200})..+/, '$1…')}</td>
                      <td>{item.status}</td>
                      <td>{adjustDate(item.date_created)}</td>
                      <td>{item.task_name ? item.task_name.split('.').pop() : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default TaskResults;
