import { HttpStatus } from 'enums';
import { getDataWithToken } from './base';

const PINECONE_BASE_URL = `${process.env.REACT_APP_IMAGE_FINDER_BASE_URL}/pinecone/`;
const IF_BASE_URL = `${process.env.REACT_APP_IMAGE_FINDER_BASE_URL}/`;

export const authenticate = async () => {
  const url = `${process.env.REACT_APP_IMAGE_FINDER_BASE_URL}/token`;

  const body = new URLSearchParams({
    username: process.env.REACT_APP_IMAGE_FINDER_USER,
    password: process.env.REACT_APP_IMAGE_FINDER_PASSWORD
  });

  const response = await fetch(url, { method: 'post', body });
  if (response.status !== HttpStatus.SUCCESS) {
    throw new Error('Not authenticated');
  }

  const { access_token } = await response.json();
  return access_token;
};

export const getConfigs = async () => {
  const pathname = `available_configs/`;
  return getDataWithToken(pathname);
};

export const queryImage = async (props, imageFile, token) => {
  const pathname = 'query_image';
  const url = PINECONE_BASE_URL + pathname;

  const data = new FormData();
  data.append('image_file', imageFile, imageFile.name);
  const blob = new Blob([JSON.stringify(props)], { type: 'application/json' });

  data.append('config', blob);

  const response = await fetch(url, {
    method: 'post',
    body: data,
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Transfer-Encoding': 'base64'
    }
  });

  if (response.status !== HttpStatus.SUCCESS) {
    throw new Error("Can't query a image.");
  }

  const { matches } = await response.json();

  return matches;
};

export const addItem = async (props, pathname, token) => {
  const url = `${IF_BASE_URL + pathname}/`;
  const data = JSON.stringify(props);

  const response = await fetch(url, {
    method: 'post',
    body: data,
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
  if (!response.ok) {
    throw new Error('Can not be added');
  }
};

export const getData = async (token, pathname) => {
  const url = `${IF_BASE_URL + pathname}/`;
  const response = await fetch(url, {
    headers: {
      Authorization: `bearer ${token}`
    }
  });

  const data = await response.json();
  return data;
};

export const updateItem = async (props, pathname, id, token) => {
  const url = `${IF_BASE_URL}${pathname}/${id}`;
  const data = JSON.stringify(props);

  const response = await fetch(url, {
    method: 'put',
    body: data,
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
  if (!response.ok) {
    throw new Error('Can not be updated');
  }
};

export const deleteItem = async (pathname, id, token) => {
  const url = `${IF_BASE_URL}${pathname}/${id}`;

  const response = await fetch(url, {
    method: 'delete',
    headers: {
      Authorization: `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });

  if (!response.ok) {
    throw new Error('Can not be deleted');
  }
};
