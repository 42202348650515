import React, { useState } from 'react';
import { Card, Container, Row, Col, ListGroup, Alert } from 'react-bootstrap';
import JobFilters from 'views/JobProgress/JobProgressFilters';
import ReactFrappeChart from 'react-frappe-charts';
import { getJobTypes } from 'api/jobTypes';

function JobProgress() {
  const [jobTypesStats, setJobTypesStats] = useState([]);
  const [loading, setLoading] = useState(false);

  const filterCallback = (selectedType, selectedOption, selectedDate, checked) => {
    setLoading(true);
    const id = selectedOption.map((el) => el.value);

    getJobTypes({
      stats: selectedType,
      id__in: id.join(),
      active: checked,
      created__gte: selectedDate || ''
    }).then(async (response) => {
      setLoading(false);
      const data = await response.json();
      setJobTypesStats(data.results);
    });
  };

  const labels = [
    'Finished',
    'Assigned',
    'Started',
    'Awaiting QC',
    'Returned',
    'Not Assigned',
    'Finished without QC'
  ];
  const colors = ['#4e938e', '#8E4E93', '#9bb9ef', '#f4e79c', '#ed9ee1', '#a5abbd', '#D99151'];

  return (
    <Container fluid>
      <Row>
        <JobFilters filterCallback={filterCallback} isLoading={loading} />
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Body className="table-full-width table-responsive px-4">
              {jobTypesStats.length ? (
                <ListGroup variant="flush">
                  {jobTypesStats.map((el) => (
                    <div>
                      {(el.stats.FINISHED !== 0 ||
                        el.stats.ASSIGNED !== 0 ||
                        el.stats.FINISHED_WITHOUT_QC !== 0 ||
                        el.stats.STARTED !== 0 ||
                        el.stats.AWAITING_QC !== 0 ||
                        el.stats.RETURNED !== 0 ||
                        el.stats.NOT_ASSIGNED !== 0) && (
                        <ListGroup.Item key={el.id}>
                          {el.id}-{el.name}
                          <p style={{ color: '#9A9A9A', fontStyle: 'italic' }}>{el.description}</p>
                          <ReactFrappeChart
                            type="percentage"
                            colors={colors}
                            axisOptions={{
                              xAxisMode: 'tick',
                              yAxisMode: 'tick',
                              xIsSeries: 1
                            }}
                            height={140}
                            data={{
                              labels,
                              datasets: [
                                {
                                  values: [
                                    el.stats.FINISHED,
                                    el.stats.ASSIGNED,
                                    el.stats.STARTED,
                                    el.stats.AWAITING_QC,
                                    el.stats.RETURNED,
                                    el.stats.NOT_ASSIGNED,
                                    el.stats.FINISHED_WITHOUT_QC
                                  ]
                                }
                              ]
                            }}
                          />
                        </ListGroup.Item>
                      )}
                    </div>
                  ))}
                </ListGroup>
              ) : (
                <Alert variant="dark">No results</Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default JobProgress;
