import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import { getJobTypes } from '../../api/jobTypes';

function SelectJobType({ selectedJobType, setSelectedJobType, className, isDisabled, isClustering }) {
  const [jobTypes, setJobTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const parameters = isClustering ? { for_clustering:true, page_size: 999 }: { page_size: 999 };
    getJobTypes(parameters).then(async (response) => {
      const data = await response.json();
      const jobTypeList = data.results;
      const jobTypes = [];
      jobTypeList.forEach((el) => {
        jobTypes.push({
          value: el,
          label: `${el.name}-${el.predefined_classes_id}`,
          id: el.id
        });
      });
      setJobTypes(jobTypes);
      setIsLoading(false);
    });
  }, []);

  return (
    <Select
      value={selectedJobType}
      onChange={setSelectedJobType}
      className={className}
      options={jobTypes}
      placeholder="Select Job Type"
      isLoading={isLoading}
      isDisabled={isDisabled}
      isSearchable
      isClearable
      name="selectJobType"
    />
  );
}

SelectJobType.propTypes = {
  selectedJobType: PropTypes.object,
  setSelectedJobType: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  isClustering: PropTypes.bool
};

SelectJobType.defaultProps = {
  selectedJobType: null,
  className: '',
  isDisabled: false,
  isClustering: false
};

export default SelectJobType;
