import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';

function SelectStation({ selectedStation, setSelectedStation, isDisabled, className }) {
  const stations = [
    { label: 'pre filtering', value: 'PRE-FILTERING' },
    { label: 'cleaning', value: 'CLEANING' },
    { label: 'identification', value: 'IDENTIFICATION' },
    { label: 'fast lane', value: 'FAST LANE' }
  ];

  return (
    <Select
      value={selectedStation}
      onChange={setSelectedStation}
      isDisabled={isDisabled}
      className={className}
      options={stations}
      placeholder="Select Station"
      isSearchable
      isClearable
    />
  );
}

SelectStation.propTypes = {
  selectedStation: PropTypes.object,
  setSelectedStation: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string
};

SelectStation.defaultProps = {
  selectedStation: null,
  isDisabled: false,
  className: ''
};

export default SelectStation;
