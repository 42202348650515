import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

function ActionModal({
  modalAction,
  showModal,
  children,
  disabledAcceptButton,
  disabledCloseButton,
  size
}) {
  return (
    <Modal
      disabled={disabledCloseButton}
      centered
      size={size === 'large' ? 'lg' : 'md'}
      show={showModal}
      onHide={() => modalAction(false)}
    >
      <Modal.Body className="text-center">{children}</Modal.Body>
      <div className="modal-footer">
        <Button
          disabled={disabledCloseButton}
          className="btn"
          type="button"
          variant="link"
          onClick={() => modalAction(false)}
        >
          Close
        </Button>
        <Button
          disabled={disabledAcceptButton}
          className="btn btn-fill"
          type="button"
          variant="link"
          onClick={() => modalAction(true)}
        >
          Accept
        </Button>
      </div>
    </Modal>
  );
}

ActionModal.propTypes = {
  showModal: PropTypes.bool,
  modalAction: PropTypes.func,
  children: PropTypes.node,
  disabledAcceptButton: PropTypes.bool,
  disabledCloseButton: PropTypes.bool,
  size: PropTypes.string
};

ActionModal.defaultProps = {
  modalAction: () => {},
  showModal: false,
  children: undefined,
  disabledAcceptButton: true,
  disabledCloseButton: false
};

export default ActionModal;
