import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Select from './Select';
import { getAuditReportClients } from '../../api/auditReport';

function SelectClient({ selectedClient, setSelectedClient, className, isDisabled }) {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    getAuditReportClients().then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        await response
          .json()
          .then((data) => {
            const clientsList = data;
            const clients = [];
            clientsList.forEach((el) => {
              clients.push({ value: el, label: el });
            });
            setClients(clients);
          })
          .catch(() => setError(true));
      } else {
        setError(true);
      }
      setIsLoading(false);
    });
  }, []);

  return error ? (
    <div className="d-flex align-items-center">
      <div className={`${className} error-text `}>
        Client filter - error, <br />
        please refresh or try later
      </div>
    </div>
  ) : (
    <Select
      value={selectedClient}
      onChange={setSelectedClient}
      className={className}
      isDisabled={isDisabled}
      options={clients}
      placeholder="Clients"
      isLoading={isLoading}
      isSearchable
      isClearable
    />
  );
}

SelectClient.propTypes = {
  selectedClient: PropTypes.object,
  setSelectedClient: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool
};

SelectClient.defaultProps = {
  selectedClient: null,
  className: '',
  isDisabled: false
};

export default SelectClient;
