import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { getFeaturestorePhotoUrl } from 'api/featurestore';
import CropImage from 'components/Images/CropImageSVG';

function CropDetailsBody({ cropInfo }) {
  const { photo_id, featurestore_id } = cropInfo.description.properties;
  return (
    <div>
      <p>Crop id: {featurestore_id}</p>
      <p>Photo id: {photo_id}</p>
      <div className="row">
        <div className="col-xs-6 col-xl-6">
          <span>Crop photo:</span>
          <CropImage
            width="200px"
            height="200px"
            source={cropInfo?.source.crop}
            rectangle={cropInfo?.source.rect}
          />
        </div>
        <div className="col-xs-6 col-xl-6">
          <span>Main Photo:</span>
          {photo_id && (
            <img alt="" src={`${getFeaturestorePhotoUrl(photo_id)}`} width="200" height="200" />
          )}
        </div>
      </div>
    </div>
  );
}

CropDetailsBody.propTypes = {
  cropInfo: PropTypes.object
};

CropDetailsBody.defaultProps = {
  cropInfo: {}
};

function CropDetailsModal({ modalAction, showModal, cropInfo }) {
  return (
    <Modal className="modal-primary" show={showModal} centered onHide={() => modalAction(false)}>
      <Modal.Body className="modal-body">
        <CropDetailsBody cropInfo={cropInfo} />
      </Modal.Body>
      <div className="modal-footer">
        <Button className="btn" type="button" variant="link" onClick={() => modalAction(false)}>
          Close
        </Button>
      </div>
    </Modal>
  );
}

CropDetailsModal.propTypes = {
  showModal: PropTypes.bool,
  modalAction: PropTypes.func,
  cropInfo: PropTypes.object
};

CropDetailsModal.defaultProps = {
  modalAction: () => {},
  showModal: false,
  cropInfo: {}
};

export default CropDetailsModal;
