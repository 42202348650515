import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Sidebar from 'components/Sidebar/Sidebar';
import Footer from 'components/Footer/Footer';
import { useLayoutContext } from 'contexts/LayoutContext';

function Admin({ children }) {
  const [color] = React.useState('shelfwise');
  const { showSidebar } = useLayoutContext();

  const location = useLocation();
  const mainPanel = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (window.innerWidth < 993 && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
      const element = document.getElementById('bodyClick');
      element.parentNode.removeChild(element);
    }
  }, [location]);

  return (
    <div className="wrapper">
      {showSidebar && <Sidebar color={color} />}
      <div className={showSidebar ? 'main-panel' : 'col'} ref={mainPanel}>
        <AdminNavbar />
        <div className="content">{children}</div>
        <Footer />
      </div>
    </div>
  );
}

Admin.propTypes = {
  children: PropTypes.node.isRequired
};

export default Admin;
