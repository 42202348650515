export const CUSTOM_SELECT_STYLES = {
  control: (provided) => ({
    ...provided,
    minWidth: 180,
    maxWidth: 850,
    minHeight: 50
  })
};

export const CUSTOM_INPUT_STYLES = { width: 180, height: 50 };
