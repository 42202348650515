import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faImage, faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';

export function CropListPreviewButton({onClick, crop}) {
    return (
        <div className="crop-list-buttons">
            <span onClick={() => onClick(crop)}>
                <FontAwesomeIcon icon={faMagnifyingGlass}/>
            </span>
            <span onClick={() => window.open(crop.crop_path, '_blank', 'noopener,noreferrer')}>
                <FontAwesomeIcon icon={faImage}/>
            </span>
        </div>
    );
}

