import React, { useState, useRef } from 'react';
import { Card, Col, Overlay, InputGroup, Form } from 'react-bootstrap';
import { useUserContext } from 'contexts/Users';
import { getUserToken } from 'utils';

function User() {
  const { isLabeler, isQC, isAdmin, userInfo } = useUserContext();
  const token = getUserToken();
  const [passwordShown, setPasswordShown] = useState(false);
  const [copySuccess, setCopySuccess] = useState(null);
  const target = useRef(null);

  function togglePassword() {
    setPasswordShown(!passwordShown);
  }

  function updateClipboard(newClip) {
    navigator.clipboard.writeText(newClip).then(
      () => {
        setCopySuccess('Copied!');
      },
      () => {
        setCopySuccess('Copy failed!');
      }
    );
  }

  return (
    <div className="container">
      <div className="row">
        <Col md="8">
          <Card className="card-user">
            <div className="card-image">
              <img
                alt="..."
                src={require('assets/img/photo-1431578500526-4d9613015464.jpeg').default}
              />
            </div>
            <Card.Body>
              <div className="author">
                <img
                  alt="..."
                  className="avatar border-gray"
                  src={require('assets/img/faces/face-1.jpg').default}
                />
                <h5 className="title">
                  {userInfo.first_name} {userInfo.last_name}
                </h5>
                <p className="description">Username: {userInfo.username}</p>
                <p className="description">Email: {userInfo.email || 'labeler@shelfwise.ai'}</p>
                <p className="description">
                  Status:{' '}
                  {(isAdmin && 'Admin') || (isLabeler && 'Labeler') || (isQC && 'Labeler QC')}
                </p>
                <span className="description">
                  Token:{' '}
                  <span className="pt-3 mx-auto col-md-7 d-flex  text-center col-md-offset-3">
                    <InputGroup>
                      <Form.Control
                        type={passwordShown ? 'text' : 'password'}
                        placeholder="Input group example"
                        aria-label="Input group example"
                        aria-describedby="btnGroupAddon"
                        autoComplete="off"
                        disabled
                        style={{ color: '#333', cursor: 'default' }}
                        value={token}
                        noValidate
                      />
                      <InputGroup.Text
                        style={{ cursor: 'pointer' }}
                        onClick={() => togglePassword()}
                        id="btnGroupAddon"
                      >
                        {passwordShown ? 'Cancel' : 'Reveal'}
                      </InputGroup.Text>
                      <InputGroup.Text
                        ref={target}
                        onClick={() => updateClipboard(token)}
                        style={{ cursor: 'pointer' }}
                        id="btnGroupAddon2"
                      >
                        Copy
                      </InputGroup.Text>
                    </InputGroup>
                    <Overlay
                      transition="fade"
                      target={target.current}
                      show={copySuccess}
                      placement="right"
                    >
                      <span
                        style={{
                          backgroundColor: '#4e938e',
                          padding: '2px 10px',
                          color: 'white',
                          borderRadius: 3
                        }}
                      >
                        {copySuccess}
                      </span>
                    </Overlay>
                  </span>
                </span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </div>
  );
}

export default User;
