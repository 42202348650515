import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getJobTypes } from 'api/jobTypes';
import { Card, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import SpinnerButton from 'components/Buttons/SpinnerButton';
import moment from 'moment';

function JobFilters({ filterCallback, isLoading }) {
  const [state, setState] = useState({
    jobTypeList: [],
    jobsOptions: []
  });

  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [selectedType, setSelectedType] = useState(null);
  const [checked, setChecked] = useState(true);

  React.useEffect(() => {
    getJobTypes().then(async (response) => {
      const data = await response.json();
      const jobTypeList = data.results;
      const jobs = [];
      jobTypeList.forEach((el) => {
        jobs.push({ value: el.id, label: `${el.name}-${el.id}` });
      });
      setState((prev) => ({ ...prev, jobTypeList, jobsOptions: jobs }));
    });
  }, []);

  const today = moment().format('YYYY-MM-DD HH:mm:ss');
  const lastWeek = moment(Date.now() - 7 * 24 * 3600 * 1000).format('YYYY-MM-DD HH:mm:ss');
  const lastMonth = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
  const lastYear = moment().startOf('year').format('YYYY-MM-DD HH:mm:ss');

  const dateOptions = [
    { value: today, label: 'Today' },
    { value: lastWeek, label: 'Last 7 days' },
    { value: lastMonth, label: 'This month' },
    { value: lastYear, label: 'This year' },
    { value: '', label: 'All data' }
  ];

  const typeOptions = [
    { value: 'job', label: 'Job' },
    { value: 'cropjob', label: 'Crop Job' }
  ];

  const onClick = () => {
    filterCallback(selectedType.value, selectedOption, selectedDate.value, checked);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 180,
      maxWidth: 850,
      minHeight: 50
    })
  };

  const { jobsOptions } = state;
  const isDisabled = isLoading || !selectedType;

  return (
    <Col md="12">
      <Card className="strpied-tabled-with-hover pb-2">
        <Card.Header>
          <Card.Title as="h4">Filters:</Card.Title>
        </Card.Header>
        <Card.Body className="table-full-width table-responsive px-3 pt-0">
          <Row className="pr-3">
            <Select
              defaultValue={selectedOption}
              styles={customStyles}
              onChange={setSelectedOption}
              options={jobsOptions}
              isMulti
              placeholder="Select Job Type"
              isSearchable
              isClearable
              className="selectFixIndex ml-3 mt-2"
            />
            <Select
              defaultValue={selectedType}
              styles={customStyles}
              onChange={setSelectedType}
              options={typeOptions}
              placeholder="Select type"
              isSearchable
              isClearable
              className="ml-3 mt-2"
            />
            <Select
              defaultValue={selectedDate}
              styles={customStyles}
              onChange={setSelectedDate}
              options={dateOptions}
              placeholder="Select date"
              isSearchable
              isClearable
              className="ml-3 mt-2"
            />
            <div
              style={{ height: '50px' }}
              className="d-flex justify-content-center align-items-center 
                custom-control custom-switch custom-switch-md ml-3 mt-2"
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id="customSwitch1"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
              <label className="custom-control-label" htmlFor="customSwitch1">
                <div style={{ marginTop: '5px' }}>Active</div>
              </label>
            </div>
            <div className="d-flex ml-3 mt-2">
              <SpinnerButton
                style={{ height: '50px' }}
                isLoading={isLoading}
                disabled={isDisabled}
                onClick={onClick}
                value="search"
              />
            </div>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

JobFilters.propTypes = {
  filterCallback: PropTypes.func,
  isLoading: PropTypes.bool
};

JobFilters.defaultProps = {
  filterCallback: () => {},
  isLoading: false
};

export default JobFilters;
