import React from 'react';
import { useCropReviewProcessContext } from 'contexts/CropReviewProcess';
import { updateCropStatus } from 'api/crops';
import ActionModal from 'modals/ActionModal';
import JobTypeDropdown from 'components/Buttons/JobTypeDropdown';
import { updateLabelName } from 'api/featurestore';
import { JobState, UserType, HttpStatus } from 'enums';
import CropReviewProcessBase from './CropReviewProcessBase';
import CropSKUReference from './CropSKUReference';

function CropReviewing() {
  const {
    cropSource,
    cropInfo,
    predefinedSet,
    fetchNextCrop,
    setError,
    jobTypeList,
    jobType,
    onClickJobType
  } = useCropReviewProcessContext();

  const [state, setState] = React.useState({
    isLoading: false,
    showModal: false,
    newLabel: ''
  });

  const onAction = async (jobState, newLabel = cropInfo.new_label) => {
    const { id } = cropInfo;
    setState((prev) => ({ ...prev, isLoading: true }));

    const response = await updateCropStatus(UserType.REVIEWER, id, { state: jobState });

    if (JobState.FINISHED === jobState && response.status === HttpStatus.SUCCESS) {
      const { label_id } = cropInfo;
      const predefined = predefinedSet.filter((item) => item.name === newLabel).shift();

      if (!predefined) {
        setError(
          `BrandBank predefined class not defined properly for this job. Can you please verify it?`
        );
        return;
      }

      updateLabelName(label_id, {
        new_label: newLabel,
        new_uuid: predefined.uuid
      });
    }

    setState((prev) => ({ ...prev, isLoading: false }));
    fetchNextCrop(UserType.REVIEWER, jobType.id);
  };

  const modalAction = async (accepted) => {
    if (accepted) {
      onAction(JobState.FINISHED);
    }
    setState((prev) => ({ ...prev, showModal: false, newLabel: '' }));
  };

  const isButtonsDisabled = !cropSource;

  const isSubmitActionModalDisabled =
    cropSource?.object.description.name === '' ||
    state.newLabel === '' ||
    predefinedSet.filter((item) => item.name === state.newLabel).length === 0;

  return (
    <CropReviewProcessBase>
      <div className="col-xl-12">
        <h4>Reviewer action:</h4>
        <div className="row">
          <JobTypeDropdown
            selectedJobType={jobType}
            jobTypeList={jobTypeList}
            onClickJobType={(jobType) => onClickJobType(UserType.REVIEWER, jobType)}
          />
          <button
            disabled={state.isLoading || isButtonsDisabled}
            onClick={() => onAction(JobState.RETURNED)}
            type="button"
            className="pr-3 btn btn-fill btn-danger "
          >
            REJECT
          </button>
          &nbsp;&nbsp;
          <button
            disabled={state.isLoading || isButtonsDisabled}
            onClick={() => setState((prev) => ({ ...prev, showModal: true }))}
            type="button"
            className="pr-3 btn btn-fill btn-warning"
          >
            FIX
          </button>
          &nbsp;&nbsp;
          <button
            disabled={state.isLoading || isButtonsDisabled}
            onClick={() => onAction(JobState.FINISHED)}
            type="button"
            className="pr-3 btn btn-fill btn-success"
          >
            ACCEPT
          </button>
        </div>
        <ActionModal
          disabledAcceptButton={isSubmitActionModalDisabled}
          modalAction={modalAction}
          showModal={state.showModal}
        >
          <CropSKUReference
            predefinedSet={predefinedSet}
            newLabel={state.newLabel}
            selectedLabel={state.newLabel}
            onClick={(newLabel) => setState((prev) => ({ ...prev, newLabel: newLabel.name }))}
          />
          <div className="row">
            <input
              className="flex-fill"
              autoFocus
              list="brandbankDropdownInputId"
              value={state.newLabel}
              placeholder="New label"
              onChange={(e) => setState((prev) => ({ ...prev, newLabel: e.target.value }))}
            />
          </div>
        </ActionModal>
        &nbsp;&nbsp;
        <CropSKUReference
          action="cropView"
          predefinedSet={predefinedSet}
          newLabel={cropInfo?.new_label}
          selectedLabel={cropInfo?.new_label}
          onClick={(newLabel) => setState((prev) => ({ ...prev, newLabel: newLabel.name }))}
        />
      </div>
    </CropReviewProcessBase>
  );
}

export default CropReviewing;
