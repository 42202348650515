/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Alert, ListGroup, Button, Card, Carousel } from 'react-bootstrap';
import SpinnerButton from 'components/Buttons/SpinnerButton';
import {reProcess, getClusterDetails, getClusterSimilar} from 'api/clusters';
import { clusterFinder } from 'api/clusterFinder';
import ListPaginator, { PAGE_SIZE } from 'components/Paginators/ListPaginator';
import ModalWithReProcess from 'components/Modal/ModalWithReProcess';
import { useUserContext } from '../../contexts/Users';

function CropImage({ items, count, previous, next, prevParams, formData, isImageFinder }) {
  const { isViewer } = useUserContext();
  const [selectedItems, setSelectedItems] = useState([]);
  const [cropItems, setCropItems] = useState([]);
  const [carouselItems, setCarouselItems] = useState([]);
  const [page, setPage] = useState(1);
  const [showReProcessModal, setShowReProcessModal] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (!items) return;
    setCropItems(items.map((item) => ({ ...item })));
  }, [items]);

  const handleItemClick = (id) => {
    if (isViewer) return;
    const newSelectedItems = [...selectedItems];
    const index = newSelectedItems.indexOf(id);
    if (index === -1) {
      newSelectedItems.push(id);
    } else {
      newSelectedItems.splice(index, 1);
    }
    setSelectedItems(newSelectedItems);
  };

  const handleCarouselButtonClick = (e, id) => {
    getClusterDetails(id).then(async (response) => {
      const data = await response.json();
      setCarouselItems(data.points);
    });
    e.stopPropagation();
  };

  const handleSelectAll = () => {
    const allItemsIds = cropItems.map((item) => item.id);
    setSelectedItems(allItemsIds);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  const updateSelectedClusters = () => {
    setIsLoading(true);
    reProcess({ clusters_ids: selectedItems }).then(async (response) => {
      if (response.status === 200) {
        const data = await response.json();
        setUpdatedData(data);
        setShowReProcessModal(true);
        setSelectedItems([]);
        setErrorMessage('');
      } else {
        const data = await response.json();
        setErrorMessage(data.detail || 'Error');
      }
      setIsLoading(false);
    });
  };

  const loadData = (params) => {
    const newParams = { ...prevParams, page: params.page };
    const request = isImageFinder
        ? clusterFinder(newParams, formData)
        : getClusterSimilar(formData, newParams);

    request.then(async (response) => {
      const data = await response.json();
      setCropItems(data.results.map((item) => ({ ...item })));
      setPage(params.page);
    });
  };

  const carouselGroups = [];
  for (let i = 0; i < carouselItems.length; i += 3) {
    carouselGroups.push(carouselItems.slice(i, i + 3));
  }

  const disableSpinnerButton = isLoading || selectedItems.length === 0;

  return (
    <div>
      <Alert variant="danger" show={errorMessage} />
      <ModalWithReProcess
        show={showReProcessModal}
        data={updatedData}
        handleClose={() => {
          setShowReProcessModal(false);
        }}
      />
      {!cropItems || cropItems.length === 0 ? (
        <p>No results</p>
      ) : (
        <>
          {!isViewer && (
            <div className="mb-3">
              <Button variant="defoult" className="mr-2" onClick={handleSelectAll}>
                Select All
              </Button>
              <Button className="mr-2" variant="defoult" onClick={handleDeselectAll}>
                Deselect All
              </Button>
              <SpinnerButton
                value="Send to re-processing"
                disabled={disableSpinnerButton}
                isLoading={isLoading}
                variant="default"
                onClick={updateSelectedClusters}
              />
              <span className="ml-2">Selected: {selectedItems.length}</span>
            </div>
          )}
          <ListGroup style={{ maxHeight: '500px', overflow: 'auto' }}>
            {cropItems.map((item) => (
              <ListGroup.Item
                key={item.id}
                onClick={() => handleItemClick(item.id)}
                active={selectedItems.includes(item.id)}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Card style={{ width: '150px', height: '150px' }}>
                    <Card.Img
                      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                      src={item.preview_image_path}
                      alt={`Thumbnail ${item.id}`}
                    />
                  </Card>
                  <div style={{ marginLeft: '10px' }}>
                    <p style={{ lineHeight: 1 }}>Cluster ID: {item.id}</p>
                    <p style={{ lineHeight: 1 }}>Count of Points: {item.count_of_points}</p>
                    <p style={{ lineHeight: 1 }}>State: {item.state}</p>
                    <p style={{ lineHeight: 1 }}>BB UUID: {item.brandbank_uuid}</p>
                    <p style={{ lineHeight: 1 }}>Similarity Score: {item.similarity_score}</p>
                    <p style={{ lineHeight: 1 }}>Coverage Score: {item.coverage_score}</p>
                  </div>

                  <Button
                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                    variant="defoult"
                    onClick={(e) => handleCarouselButtonClick(e, item.id)}
                  >
                    <i className="fas fa-images" />
                  </Button>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          {count > PAGE_SIZE && (
            <ListPaginator
              total={count}
              className="mt-4"
              current={page}
              previous={previous}
              next={next}
              changePage={loadData}
            />
          )}
        </>
      )}
      {carouselGroups.length > 0 && (
        <Card>
          <Carousel className="mt-4">
            {carouselGroups.map((group, index) => (
              <Carousel.Item key={index}>
                <div className="d-flex justify-content-center">
                  {group.map((item) => (
                    <div key={item.id} style={{ margin: '0 5px', height: '200px' }}>
                      <img
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        src={item.crop_path}
                        alt={`Carousel image ${item.id}`}
                      />
                    </div>
                  ))}
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Card>
      )}
    </div>
  );
}

export default CropImage;
