import React, { useState, useEffect } from 'react';
import { Button, FormControl, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import DialogModal from 'components/Modal/Modal';
import ConfirmationModal from 'modals/ConfirmationModal';
import {
  removeCluster,
  rejectClusterWithComment,
  rejectCluster,
  assignAsLabeler,
  assignAsReviewer
} from 'api/clusters';

function IdentificationButtons({
  cluster,
  refreshData,
  setErrorMessage,
  userType,
  selectedUuid,
  stopLoading,
  stopLoadingFunction,
  prevSelectedLabelName
}) {
  const isLabeler = userType === 'labeler';
  const assignAsUser = isLabeler ? assignAsLabeler : assignAsReviewer;
  const isRejected = cluster.reject_flag;

  const [showModal, setShowModal] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [comment, setComment] = useState('');
  const { id } = cluster;
  const [modalShow, setModalShow] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [actionAdditionalParams, setActionAdditionalParams] = useState(null);
  const commentTooLong = comment.length > 1024;

  const handleCloseModalIfRequired = (actionType) => {
    if (actionType === 'rejectClusterWithComment') {
      setShowModal(false);
      setComment('');
    }
  };

  useEffect(() => {
    setIsBusy(stopLoading);
  }, [stopLoading]);

  const checkResponse = async (response, actionType) => {
    if (response.status === 200) {
      refreshData();
    } else {
      await response.json()
        .then((data) => setErrorMessage(data.detail || 'Error'))
        .catch(() => setErrorMessage('Error'))
    }
    handleCloseModalIfRequired(actionType);
    setIsBusy(false);
  };

  const handleDangerousAction = (actionType, params) => {
    setActionType(actionType);
    setActionAdditionalParams(params);
    setModalShow(true);
  };

  const handleAction = async (aActionType, additionalParams = {}) => {
    stopLoadingFunction(true);
    aActionType = aActionType || actionType;
    if (additionalParams && Object.keys(additionalParams).length === 0) {
      additionalParams = actionAdditionalParams;
    }
    const actionFunctions = {
      unknowProduct: assignAsUser,
      assignSelected: assignAsUser,
      reject: rejectCluster,
      rejectClusterWithComment: rejectClusterWithComment,
      removeCluster: removeCluster,
      accept: assignAsUser
    };

    const response = await actionFunctions[aActionType](id, additionalParams);
    checkResponse(response, aActionType);
    setActionType(null);
    setActionAdditionalParams(null);
  };

  return (
    <>
      <DialogModal
        title="Reject with comment"
        show={showModal}
        approve="approve"
        disabled={isBusy || commentTooLong}
        back="cancel"
        size="md"
        body={
          <Form validated="">
            <FormControl
              as="textarea"
              rows={3}
              validated
              required
              className="form-range mb-5"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            {commentTooLong && (
              <div className="error-text mt-n5 mb-3">
                comment can't be longer than 1024 symbols
              </div>
            )}
          </Form>
        }
        handleClose={() => setShowModal(false)}
        handleApprove={() => {
          handleAction('rejectClusterWithComment', { comment });
        }}
      />
      {id ? (
        <>
          <div className="text-center mt-4 mb-3">
            {!isLabeler ? (
              <>
                <Button
                  className="ml-2 mt-3"
                  variant="defoult"
                  style={{ fontSize: '13px' }}
                  disabled={isBusy}
                  onClick={() =>
                    handleAction(
                      'accept',
                      cluster.brandbank_uuid ? { brandbank_uuid: cluster.brandbank_uuid } : null
                    )
                  }>
                  Accept
                </Button>
                <Button
                  className="ml-2 mt-3"
                  style={{ fontSize: '13px' }}
                  variant="danger"
                  onClick={() => setShowModal(true)}>
                  Reject with comment
                </Button>
                <Button
                  style={{ fontSize: '13px' }}
                  className="ml-2 mt-3"
                  disabled={isBusy}
                  variant="danger"
                  onClick={() => handleAction('reject')}>
                  Reject
                </Button>
              </>
            ) : null}
            <Button
              className="ml-2 mt-3"
              style={{ fontSize: '13px' }}
              variant="danger"
              disabled={isBusy || (isRejected && !cluster.brandbank_uuid)}
              onClick={() => {
                handleDangerousAction('unknowProduct', !isLabeler ? { fix: true } : null);
              }}>
              Set as unknown product
            </Button>
            <Button
              style={{ fontSize: '13px' }}
              className="ml-2 mt-3"
              variant="defoult"
              disabled={
                !selectedUuid || isBusy || (isRejected && cluster.brandbank_uuid === selectedUuid)
              }
              onClick={() =>
                handleAction(
                  'assignSelected',
                  isLabeler
                    ? { brandbank_uuid: selectedUuid }
                    : { brandbank_uuid: selectedUuid, fix: true }
                )
              }>
              Assign selected class
            </Button>
          </div>
          {userType !== 'labeler' || isRejected ? (
            <div className="text-center mt-3 mb-3">
              {`Labeler selected uuid: ${cluster.brandbank_uuid || 'unknown'}`}
              <br />
              {`Labeler selected: ${prevSelectedLabelName || 'unknown'}`}
            </div>
          ) : null}
        </>
      ) : null}
      <ConfirmationModal
        showModal={modalShow}
        disabled={isBusy}
        modalAction={(value) => {
          value && handleAction();
          setModalShow(false);
        }}>
        <h3>Confirm your action</h3>
        <p>Do you really want to do this?</p>
      </ConfirmationModal>
    </>
  );
}

IdentificationButtons.propTypes = {
  cluster: PropTypes.object,
  refreshData: PropTypes.func,
  setErrorMessage: PropTypes.func,
  selectedCrops: PropTypes.array,
  userType: PropTypes.string,
  selectedUuid: PropTypes.string
};

IdentificationButtons.defaultProps = {
  cluster: {},
  refreshData: () => {},
  setErrorMessage: () => {},
  selectedCrops: [],
  userType: '',
  selectedUuid: ''
};

export default IdentificationButtons;
