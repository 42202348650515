import React from 'react';
import PropTypes from 'prop-types';
import Select from './Select';

function SelectAction({ selectedAction, setSelectedAction, isDisabled, className }) {
  const actions = [
    { label: 'assign', value: 'ASSIGN' },
    { label: 'assign as a labeler', value: 'ASSIGN_AS_LABELER' },
    { label: 'assign as a labeler with UUID', value: 'ASSIGN_AS_LABELER_WITH_UUID' },
    { label: 'assign as a reviewer', value: 'ASSIGN_AS_REVIEWER' },
    { label: 'assign as a reviewer with UUID', value: 'ASSIGN_AS_REVIEWER_WITH_UUID' },
    { label: 'clean', value: 'CLEAN' },
    { label: 'discard', value: 'DISCARD' },
    { label: 'new cluster', value: 'NEW_CLUSTER' },
    { label: 'reject', value: 'REJECT' },
    { label: 'remove cluster', value: 'REMOVE_CLUSTER' },
    { label: 'remove crops', value: 'REMOVE_CROPS' },
    { label: 'select', value: 'SELECT' },
    { label: 'send to cleaning QC', value: 'SEND_TO_CLEANING_QC' }
  ];

  return (
    <Select
      value={selectedAction}
      onChange={setSelectedAction}
      isDisabled={isDisabled}
      className={className}
      options={actions}
      placeholder="Select Action"
      isSearchable
      isClearable
    />
  );
}

SelectAction.propTypes = {
  selectedAction: PropTypes.object,
  setSelectedAction: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string
};

SelectAction.defaultProps = {
  selectedAction: null,
  isDisabled: false,
  className: ''
};

export default SelectAction;
