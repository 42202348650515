import React from 'react';
import PropTypes from 'prop-types';

function CropFromImage({ xMin, yMin, yMax, xMax, url, index, onLoaded }) {
  React.useEffect(() => {
    const myimage = new Image();

    myimage.onload = () => {
      try {
        const canvas = document.getElementById(`canvasId-${index}`);
        const ctx = canvas.getContext('2d');

        const sx = myimage.width * xMin;
        const sy = myimage.height * yMin;

        const sWidth = myimage.width * (xMax - xMin);
        const sHeight = myimage.height * (yMax - yMin);

        ctx.canvas.width = sWidth;
        ctx.canvas.height = sHeight;

        ctx.drawImage(myimage, sx, sy, sWidth, sHeight, 0, 0, sWidth, sHeight);

        onLoaded();
      } catch (error) {
        /* eslint no-empty: "error" */
      }
    };
    myimage.src = url;
  }, [url]);

  return (
    <canvas
      id={`canvasId-${index}`}
      style={{
        height: '250px',
        width: '150px'
      }}
    />
  );
}

CropFromImage.propTypes = {
  xMin: PropTypes.string,
  yMin: PropTypes.string,
  yMax: PropTypes.string,
  xMax: PropTypes.string,
  url: PropTypes.string,
  index: PropTypes.number,
  onLoaded: PropTypes.func
};

CropFromImage.defaultProps = {
  xMin: 0,
  yMin: 0,
  yMax: 0,
  xMax: 0,
  url: '',
  index: 0,
  onLoaded: () => {}
};

export default CropFromImage;
