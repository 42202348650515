import React, { lazy, Suspense } from 'react';
import { addItem, updateItem, deleteItem, getData } from 'api/imageFinder';
import { useImageFinderContext } from 'contexts/ImageFinderProcess';

const CrudTable = lazy(() => import('./CrudTable'));

function PeriodicTasks() {
  const [state, setState] = React.useState({
    tasks: [],
    isLoading: false,
    response: ''
  });

  const { getToken } = useImageFinderContext();

  const setError = (error) =>
    setState((prev) => ({
      ...prev,
      isLoading: false,
      error: error
    }));

  const taskArguments = {
    config_name: '',
    customer_id: '',
    detector: {
      host: '',
      label_min_score: { empty_space: 0 },
      min_score: 0,
      model_name: '',
      __class_type__: '',
      customer_id: ''
    },
    search_date_n_hours_offset: ''
  };

  const tasksNames = [
    { name: 'Index Config name', key: 'config_name', row: true },
    { name: 'Customer Id', key: 'customer_id', row: true },
    { name: 'Task', key: 'task', row: true, label: true },
    {
      name: 'Arguments',
      key: 'arguments',
      label: true,
      json: JSON.stringify(taskArguments, undefined, 2)
    },
    { name: 'Enabled', key: 'enabled', checked: true, row: true },
    { name: 'Crontab String', key: 'crontab_string', row: true, label: true }
  ];

  const changeTasksObj = (tasks) => {
    const copyArr = JSON.parse(JSON.stringify(tasks));
    copyArr.forEach((item) => {
      if (item.arguments) {
        const arg = item;
        arg.config_name = arg.arguments.config_name;
        arg.customer_id = arg.arguments.customer_id;
        return item;
      }
    });
    return copyArr;
  };

  const getTasks = async () => {
    const token = await getToken();
    let abortController = null;
    abortController = new AbortController();
    try {
      const tasks = await getData(token, 'periodic_task');
      setState((prev) => ({
        ...prev,
        tasks: changeTasksObj(tasks)
      }));
    } catch (error) {
      setError(error.toString());
    }
    return () => abortController.abort();
  };

  React.useEffect(() => {
    getTasks();
  }, []);

  const setData = async () => {
    const token = await getToken();
    getData(token, 'periodic_task').then((res) => {
      setState((prev) => ({
        ...prev,
        tasks: changeTasksObj(res),
        isLoading: false,
        response: 'success'
      }));
      setTimeout(
        setState((prev) => ({
          ...prev,
          response: ''
        })),
        100
      );
    });
  };

  const addTask = async (data) => {
    setState((prev) => ({ ...prev, isLoading: true }));
    const token = await getToken();
    addItem(data, 'periodic_task', token)
      .then(() => {
        setData();
      })
      .catch(() => {
        setState((prev) => ({ ...prev, isLoading: false, response: 'error' }));
      });
  };

  const updateIndex = async (data, id) => {
    setState((prev) => ({ ...prev, isLoading: true }));
    const token = await getToken();
    updateItem(data, 'periodic_task', id, token)
      .then(() => {
        setData();
      })
      .catch(() => {
        setState((prev) => ({ ...prev, isLoading: false, response: 'error' }));
      });
  };

  const deleteTask = async (id) => {
    setState((prev) => ({ ...prev, isLoading: true }));
    const token = await getToken();
    deleteItem('periodic_task', id, token)
      .then(() => {
        setData();
      })
      .catch(() => {
        setState((prev) => ({ ...prev, isLoading: false, response: 'error' }));
      });
  };

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <CrudTable
        rowNames={state.tasks}
        titleNames={tasksNames}
        addItem={addTask}
        updateItem={updateIndex}
        deleteItem={deleteTask}
        isLoading={state.isLoading}
        taskArguments={taskArguments}
        response={state.response}
      />
    </Suspense>
  );
}

export default PeriodicTasks;
