import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { Button, Alert } from 'react-bootstrap';
import { clusterFinder } from 'api/clusterFinder';
import SpinnerButton from 'components/Buttons/SpinnerButton';
import { PAGE_SIZE } from 'components/Paginators/ListPaginator';

export default function UploadImage({ assignJobType, handleResponce, selectedState }) {
  const [images, setImages] = useState([]);
  const maxNumber = 5;
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (imageList) => {
    setImages(imageList);
  };

  const handleUpload = () => {
    if (images.length === 0) {
      alert('Please upload at least one image.');
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    images.forEach((data, index) => {
      formData.append(`images`, data.file);
      formData.append(`data_url${index}`, data.data_url);
    });
    const selectedStateValue = selectedState.map((state) => state.value);
    const params =
      selectedStateValue.length > 0
        ? {
            predefined_classes: assignJobType,
            blocked: false,
            state__in: selectedStateValue,
            page: 1,
            page_size: PAGE_SIZE
          }
        : { predefined_classes: assignJobType, blocked: false, page: 1, page_size: PAGE_SIZE };
    clusterFinder(params, formData).then(async (response) => {
      setIsLoading(false);
      const data = await response.json();
      handleResponce(data, params, formData);
    });
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url">
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors
        }) => (
          <div className="upload__image-wrapper">
            {errors && (
              <Alert variant="danger">
                {errors.maxNumber && <p>Maximum number of images exceeded ({maxNumber}).</p>}
                {errors.acceptType && <p>Invalid file type. Allowed types: jpg, jpeg, png, gif.</p>}
                {errors.maxFileSize && <p>Maximum file size exceeded (5MB).</p>}
                {errors.resolution && <p>Selected file does not match the desired resolution.</p>}
              </Alert>
            )}
            <div className="mb-4">
              <Button
                variant="default"
                style={isDragging ? { color: 'red' } : undefined}
                onClick={onImageUpload}
                {...dragProps}>
                Click or Drop here
              </Button>
              &nbsp;
              {imageList.length ? (
                <Button variant="default" onClick={onImageRemoveAll}>
                  Remove all images
                </Button>
              ) : null}
            </div>
            {imageList.map((image, index) => (
              <div key={image} className="image-item mb-4">
                <img src={image.data_url} alt="" width="107" />
                <div className="image-item__btn-wrapper">
                  <Button variant="default" onClick={() => onImageUpdate(index)}>
                    <i className="fas fa-edit" />
                  </Button>
                  <Button variant="default" onClick={() => onImageRemove(index)}>
                    <i className="fas fa-trash" />
                  </Button>
                </div>
              </div>
            ))}
            <SpinnerButton
              value="Search"
              disabled={isLoading || !assignJobType || Object.keys(assignJobType).length === 0}
              isLoading={isLoading}
              variant="default"
              onClick={handleUpload}
            />
          </div>
        )}
      </ImageUploading>
    </div>
  );
}
