import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useUserContext } from 'contexts/Users';
import { getJobTypes, getAppVersion } from 'api/jobTypes';
import { Card, Col, Row } from 'react-bootstrap';

const DashboardAll = lazy(() => import('./DashboardAll'));
const DashboardLabeler = lazy(() => import('./DashboardLabeler'));

function Dashboard() {
  const userContext = useUserContext();
  const { labelerJobTypes, setAssignJobTypes, isLabeler, isQC } = userContext;
  const [previouseJobTypes, setPreviouseJobTypes] = useState([]);
  const [allowedJobTypes, setAllowedJobTypes] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [cropJobs, setCropJobs] = useState([]);
  const [appVersion, setAppVersion] = useState({front:"", back:""})

  useEffect(async () => {
    setAssignJobTypes();
    let back="";
    let front = "local";
    await getAppVersion().then(async (response) => {
      if (response.status === 200) {
        const data = await response.json();
        back =data.git_version;
      }
    });
    if (process.env.REACT_APP_ENVIROMENT !== 'local') {
      await fetch("git_file.txt")
          .then(res => res.text())
          .then(data => {
            front= data;
          });
    }
    setAppVersion({front: front, back: back})
  }, []);


  function getJobTypeStats(stats, assignJobTypes, setResources) {
    const assignJobTypesStr = assignJobTypes.join();
    if (assignJobTypesStr) {
      getJobTypes({
        stats: stats,
        id__in: assignJobTypes.join(),
        active: true,
        created__gte: ''
      }).then(async (response) => {
        const data = await response.json();
        if (data.results.length) setResources(data.results);
      });
    }
  }
  function filterJobTypeList(jobTypeList, labelerJobTypeIds, setFilteredJobTypes) {
    const filteredJobTypes = jobTypeList.filter((item) => labelerJobTypeIds.includes(item.id));
    return setFilteredJobTypes(filteredJobTypes);
  }
  useEffect(() => {
    if (Object.keys(labelerJobTypes).length) {
      const assignJobTypes = labelerJobTypes.allowed_job_types;
      const completedJobTypes = labelerJobTypes.completed_job_types;
      getJobTypes().then(async (response) => {
        const data = await response.json();
        const jobTypeList = data.results;
        filterJobTypeList(jobTypeList, assignJobTypes, setAllowedJobTypes);
        filterJobTypeList(jobTypeList, completedJobTypes, setPreviouseJobTypes);
      });
      getJobTypeStats('job', assignJobTypes, setJobs);
      getJobTypeStats('cropjob', assignJobTypes, setCropJobs);
    }
  }, [JSON.stringify(labelerJobTypes)]);
  return (
    <div>
      <Col md={{ span: 6, offset: 6 }} lg={{ span: 6, offset: 6 }} xl={{ span: 3, offset: 9 }}>
        <Card style={{ textAlign: 'center', fontSize: '15px' }} className="card-stats p-2">
          <Card.Body>
            <Row>
              <Col xs="5">
                <div className="icon-big text-center icon-warning">
                  <i className="nc-icon nc-vector text-danger" />
                </div>
              </Col>
              <Col xs="7">
                <div className="numbers ml-n3">
                  <p className="card-category">App version</p>
                  <Card.Title as="h5">
                    <span className="text-nowrap">F: {appVersion.front}</span>
                    <br/>
                    <span className="text-nowrap">B: {appVersion.back}</span>
                  </Card.Title>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      {isLabeler || isQC ? (
        <Suspense fallback={<div>loading...</div>}>
          <DashboardLabeler
            jobs={jobs}
            cropJobs={cropJobs}
            allowedJobTypes={allowedJobTypes}
            previouseJobTypes={previouseJobTypes}
          />
        </Suspense>
      ) : (
        <Suspense fallback={<div>loading...</div>}>
          <DashboardAll />
        </Suspense>
      )}
    </div>
  );
}
export default Dashboard;
