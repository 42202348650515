import React from 'react';
import { useCropReviewProcessContext } from 'contexts/CropReviewProcess';

function CropDetails() {
  const { cropSource, cropInfo, error } = useCropReviewProcessContext();
  return (
    <>
      <h6>Crop details:</h6>
      {!error && (
        <div className="border border-secondary">
          {cropInfo?.new_label !== '' && <p>New label name: {cropInfo?.new_label}</p>}
          <p>Old label name: {cropSource?.object.description.name}</p>
          <p>Crop id: {cropInfo?.label_id}</p>
          <p>Photo id: {cropInfo?.photo_id}</p>
          {cropInfo?.job_type.description !== '' && (
            <>
              <h6>Description:</h6>
              <p>{cropInfo?.job_type.description}</p>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default CropDetails;
