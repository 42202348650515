import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ArrayDropdown from './ArrayDropdown';

function JobTypeDropdown({ jobTypeList, onClickJobType, disabled, openDropdown, predefinedClass }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!jobTypeList.length) return;
    const options = jobTypeList.reduce((acc, currItem) => {
      const value = currItem[`${predefinedClass ? 'predefined_classes_id' : 'id'}`];
      acc.push({
        label: `${currItem.name}-${value}`,
        value: currItem,
        rejected: !!currItem.rejected_count
      });
      return acc;
    }, []);
    setOptions(options);
  }, [jobTypeList]);

  return (
    <ArrayDropdown
      onClick={(source) => onClickJobType(source)}
      defaultValue="Select Job Type"
      openDropdown={openDropdown}
      disabled={disabled}
      list={options}
    />
  );
}

JobTypeDropdown.propTypes = {
  jobTypeList: PropTypes.array,
  onClickJobType: PropTypes.func,
  selectedJobType: PropTypes.object,
  withClear: PropTypes.bool,
  disabled: PropTypes.bool
};

JobTypeDropdown.defaultProps = {
  jobTypeList: [],
  onClickJobType: () => {},
  selectedJobType: undefined,
  withClear: false,
  disabled: false
};

export default JobTypeDropdown;
