import React from 'react';
import { Alert } from 'react-bootstrap';
import ClusterWrapper from './ClusterWrapper';
import { useUserContext } from '../../contexts/Users';

function FastLane() {
  const { isQC, isAdmin, accessToStation } = useUserContext();

  const hasAccess = (isQC && accessToStation === '/fast_lane') || isAdmin;

  return hasAccess ? (
    <ClusterWrapper action="FAST_LANE" />
  ) : (
    <Alert variant="danger">
      You are not assigned to this station. Please contact your manager.
    </Alert>
  );
}

export default FastLane;
