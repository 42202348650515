import React, { lazy, Suspense } from 'react';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';

const BatchCrops = lazy(() => import('components/BatchCrops/BatchCrops'));
const ReviewerActionButtons = lazy(() => import('./ReviewerActionButtons'));

function BatchCropReviewing({ selectedLabel }) {
  const { setAction } = useBatchLabelingContext();

  React.useEffect(() => {
    setAction('reviewing');
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BatchCrops reviewing>
        <ReviewerActionButtons selectedLabel={selectedLabel} />
      </BatchCrops>
    </Suspense>
  );
}

export default BatchCropReviewing;
