import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Container, Row, Col, Alert, Table } from 'react-bootstrap';
import PerformanceFilters from 'views/Performance/PerformanceFilters';
import ListPaginator, { PAGE_SIZE } from 'components/Paginators/ListPaginator';
import { getPerformance } from 'api/performance';

function Performance({ userType }) {
  const [performance, setPerformance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [errorMessage, setErrorMessages] = useState('');
  const [state, setState] = useState({
    page_size: PAGE_SIZE,
    page: 1,
    selectedType: '',
    selectedJobType: '',
    selectedLabeler: '',
    lastStateChangeBefore: '',
    lastStateChangeAfter: '',
    checked: false,
    count: 0
  });

  const isLabeler = userType === 'labeler';

  const getParams = ({
    selectedJobType,
    selectedLabeler,
    lastStateChangeBefore,
    lastStateChangeAfter,
    checked
  }) => {
    const params = {
      page_size: PAGE_SIZE
    };
    if (selectedJobType) params.job_type_id = selectedJobType;
    if (selectedLabeler) params.labeler_id = selectedLabeler;
    if (checked) params.is_image_finder_job = checked;
    if (lastStateChangeBefore) params.last_state_change_before = lastStateChangeBefore;
    if (lastStateChangeAfter) params.last_state_change_after = lastStateChangeAfter;

    return params;
  };

  const filterCallback = (params) => {
    const parameters = getParams(params);
    setSelectedType(params.selectedType);
    setState((prev) => ({
      ...prev,
      ...params
    }));
    getPerformance({ page: 1, ...parameters }, userType, params.selectedType)
      .then(async (response) => {
        setLoading(false);
        const data = await response.json();
        setPerformance(data.results);
        setErrorMessages('');
        setState((prev) => ({ ...prev, ...data, page: params.page }));
      })
      .catch(() => {
        setPerformance([]);
        setErrorMessages('Error');
      });
    setLoading(true);
  };

  const getLabelersList = (labelersList) => {
    setState((prev) => ({
      ...prev,
      labelersList
    }));
  };

  const loadPerformance = (params) => {
    const parameters = getParams(state);
    getPerformance({ page: params.page, ...parameters }, userType, selectedType).then(
      async (response) => {
        setLoading(false);
        const data = await response.json();
        setPerformance(data.results);
        setState((prev) => ({ ...prev, ...data, page: params.page }));
      }
    );
  };

  return (
    <Container fluid>
      <Row>
        <PerformanceFilters
          getLabelersList={getLabelersList}
          filterCallback={filterCallback}
          isLoading={loading}
        />
        <Col md="12">
          {performance && performance.length ? (
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Performance (Total: {state.count})</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-4">
                {state.count > PAGE_SIZE && (
                  <ListPaginator
                    total={state.count}
                    current={state.page}
                    previous={state.previous}
                    next={state.next}
                    changePage={loadPerformance}
                  />
                )}
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">Labeler</th>
                      <th className="border-0">JobType</th>
                      <th className="border-0">ImageFinder job</th>
                      <th className="border-0">Accepted</th>
                      <th className="border-0">Fixed</th>
                      <th className="border-0">Returned</th>
                      <th className="border-0">Total Finished</th>
                      <th className="border-0">
                        {' '}
                        {`${isLabeler ? 'Failure' : 'Detection'}  rate [%]`}
                      </th>
                      {!isLabeler && <th className="border-0"> Fix rate [%]</th>}
                      <th className="border-0">{`${
                        isLabeler ? 'Total tagged' : 'Total checked'
                      }`}</th>
                      <th className="border-0">Work time [h:m:s]</th>
                      <th className="border-0">Time per tagged [m:S]</th>
                    </tr>
                  </thead>
                  <tbody>
                    {performance.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {`${
                            state.labelersList.find((el) =>
                              el.value === (item.labeler_id || item.reviewer_id) ? el.label : ''
                            ).label
                          }`}
                        </td>
                        <td>{`${item.job_type_name}-${item.job_type_id}`}</td>
                        <td>{`${item.is_image_finder_job}`}</td>
                        <td>{item.accepted}</td>
                        <td>{item.fixed}</td>
                        <td>{item.returned}</td>
                        <td>{item.finished}</td>
                        <td>{isLabeler ? item.failure_rate : item.detection_rate}</td>
                        {!isLabeler && <td>{item.fix_rate}</td>}
                        <td>{isLabeler ? item.tagged : item.checked}</td>
                        <td>{item.work_time}</td>
                        <td>{item.time_per_tagged}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {state.count > PAGE_SIZE && (
                  <ListPaginator
                    total={state.count}
                    current={state.page}
                    previous={state.previous}
                    next={state.next}
                    changePage={loadPerformance}
                  />
                )}
              </Card.Body>
            </Card>
          ) : (
            <>
              {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <Alert variant="dark">No results</Alert>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

Performance.propTypes = {
  userType: PropTypes.string
};

Performance.defaultProps = {
  userType: ''
};

export default Performance;
