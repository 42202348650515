/* eslint eqeqeq: "off" */
import React from 'react';
import { getConfigs, queryImage } from 'api/imageFinder';
import ImageFinderModal from 'modals/ImageFinderModal';
import ImageFinderFilterModal from 'modals/ImageFinderFilterModal';
import { getCrop } from 'api/featurestore';
import ActionView from 'views/ImageFinder/ActionView';
import MatchesView from 'views/ImageFinder/MatchesView';
import TaskResultsNotification from 'components/Notifications/TaskResultsNotification';
import { ImageFinderSelectors } from 'enums';
import { ImageFinderPageSize } from 'localConstants';
import { useImageFinderContext } from 'contexts/ImageFinderProcess';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';

function ImageFinder() {
  const [state, setState] = React.useState({
    configs: [],
    selectedConfig: undefined,
    imageSource: undefined,
    imageURL: undefined,
    matches: [],
    isLoading: false,
    error: undefined,
    showModal: false,
    nearestNeighbors: 50,
    currentLoaded: ImageFinderPageSize,
    showFilterModal: false,
    filter: {}
  });

  const { getToken, error } = useImageFinderContext();

  const { setAction } = useBatchLabelingContext();

  React.useEffect(() => {
    setAction('imageFinder');
  }, []);

  const setError = (error) =>
    setState((prev) => ({
      ...prev,
      isLoading: false,
      selectedConfig: undefined,
      imageSource: undefined,
      imageURL: undefined,
      error: error,
      currentLoaded: ImageFinderPageSize
    }));

  const onChangeImage = (event) => {
    setState((prev) => ({
      ...prev,
      imageSource: undefined,
      imageURL: undefined,
      matches: [],
      error: undefined
    }));

    if (event.target.files && event.target.files[0]) {
      setState((prev) => ({
        ...prev,
        imageSource: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      }));
    }
  };

  const loadCropFeatureStore = async (cropInfo) => {
    const response = await getCrop(cropInfo.metadata.fs_label_id, {
      photo_id: cropInfo.metadata.image_id,
      centroid_scale: 0.8,
      photo_as_url: true
    });

    const data = await response.json();

    setState((prev) => {
      const { matches } = state;
      cropInfo.source = data.crop;
      cropInfo.rect = data.rect;
      cropInfo.selected = false;
      matches.push(cropInfo);
      return { ...prev, matches };
    });
  };

  const loadCrop = async (cropInfo) => {
    if (cropInfo.metadata.fs_label_id) {
      loadCropFeatureStore(cropInfo);
    } else {
      setState((prev) => {
        const { matches } = state;
        cropInfo.selected = false;
        matches.push(cropInfo);
        return { ...prev, matches };
      });
    }
  };

  const processSearchCrops = async () => {
    try {
      setState((prev) => ({
        ...prev,
        isLoading: true,
        matches: [],
        currentLoaded: ImageFinderPageSize
      }));

      const props = {
        config_name: state.selectedConfig,
        n_nearest_neighbors: state.nearestNeighbors
      };

      if (state.filter) {
        const filter = {};
        Object.entries(state.filter).forEach(([key, { selector, value }]) => {
          if (selector && value) {
            filter[key] = { [ImageFinderSelectors[selector]]: value };
          }
        });

        if (filter != {}) {
          props.filter = filter;
        }
      }

      const token = await getToken();
      const matches = await queryImage(props, state.imageSource, token);
      setState((prev) => ({ ...prev, isLoading: false, matches: [] }));
      matches.forEach((crop) => loadCrop(crop));
    } catch (error) {
      setError(error.toString());
    }
  };

  const searchCrops = async () => {
    if (state.matches.length) {
      setState((prev) => ({
        ...prev,
        isLoading: false,
        matches: [],
        currentLoaded: ImageFinderPageSize
      }));
    } else {
      processSearchCrops();
    }
  };

  const toogleCrop = (cropInfo) => {
    const { matches } = state;
    const index = matches.indexOf(cropInfo);
    matches[index].selected = !matches[index].selected;
    setState((prevState) => ({ ...prevState, matches }));
  };

  const createCrops = () => {
    setState((prev) => ({ ...prev, showModal: true }));
  };

  const onAccepted = () => {
    setState((prevState) => {
      const updatedMatches = prevState.matches.map((el) => {
        if (el.selected) {
          return {
            ...el,
            prevSelected: el.selected,
            selected: false
          };
        }
        return el;
      });

      return {
        ...prevState,
        matches: updatedMatches
      };
    });
  };

  React.useEffect(() => {
    getConfigs().then(async (response) => {
      const configs = await response.json();
      setState((prev) => ({ ...prev, configs }));
    });
  }, []);

  return (
    <div>
      {state.error ||
        (error && (
          <div className="alert alert-danger">
            <strong>{state.error || error}</strong>
          </div>
        ))}
      <ActionView
        onChangeImage={onChangeImage}
        setState={setState}
        selectedConfig={state.selectedConfig}
        configs={state.configs}
        imageSource={state.imageSource}
        searchCrops={searchCrops}
        isLoading={state.isLoading}
        matches={state.matches}
        nearestNeighbors={state.nearestNeighbors}
        currentLoaded={state.currentLoaded}
        createCrops={createCrops}
        imageURL={state.imageURL}
      />
      {state.imageSource && <TaskResultsNotification />}
      <MatchesView
        matches={state.matches}
        toogleCrop={toogleCrop}
        currentLoaded={state.currentLoaded}
        setState={setState}
      />
      <ImageFinderModal
        showModal={state.showModal}
        onAccepted={onAccepted}
        setShowModal={(value) => setState((prev) => ({ ...prev, showModal: value }))}
        items={state.showModal ? state.matches.filter((item) => item.selected == true) : []}
        selectedConfig={state.selectedConfig}
      />
      <ImageFinderFilterModal
        showModal={state.showFilterModal}
        setShowModal={(value) => setState((prev) => ({ ...prev, showFilterModal: value }))}
        setState={setState}
        state={state}
      />
    </div>
  );
}

export default ImageFinder;
