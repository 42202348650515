import React, { lazy, Suspense } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const Chart = lazy(() => import('react-apexcharts'));

export function ColumnChart({ series, categories }) {
  ColumnChart.propTypes = {
    series: PropTypes.arrayOf.isRequired,
    categories: PropTypes.arrayOf.isRequired
  };

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      }
    },
    xaxis: {
      categories: categories
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      showForSingleSeries: true
    },
    fill: {
      opacity: 1
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Chart options={options} series={series} type="bar" height={350} />
    </Suspense>
  );
}

export function TimeChart({ series, exactHours, jobType }) {
  TimeChart.propTypes = {
    series: PropTypes.arrayOf.isRequired,
    exactHours: PropTypes.arrayOf.isRequired
  };
  const options = {
    chart: {
      height: 450,
      type: 'rangeBar'
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '90%',
        columnWidth: '100%',
        columnHeight: '100%',
        dataLabels: {
          hideOverflowingLabels: true,
          position: 'center'
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        const a = moment(new Date(val[0]));
        const b = moment(new Date(val[1]));
        const duration = moment.duration(b.diff(a));
        const hours = parseInt(duration.asHours(), 10);
        const minutes = parseInt(duration.asMinutes(), 10) % 60;
        return `${hours}:${minutes} h`;
      }
    },
    xaxis: {
      type: 'category',
      tickAmount: 12,
      showForNullSeries: false,
      labels: {
        formatter: function (val) {
          return moment(new Date(val)).format('HH:mm');
        }
      }
    },
    stroke: {
      width: 1
    },
    fill: {
      type: 'solid',
      opacity: 0.6
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'left',
      showForSingleSeries: !!jobType
    },
    annotations: {
      xaxis: exactHours
    },
    tooltip: {
      enabled: false
    }
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Chart options={options} series={series} type="rangeBar" height={350} />;
    </Suspense>
  );
}
