import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';
import { getJobTypes } from 'api/jobTypes';
import { createCropJobs } from 'api/crops';
import ArrayDropdown from 'components/Buttons/ArrayDropdown';
import { percentage } from 'utils';
import ActionModal from './ActionModal';

function CreateManyCropJobsModal({ showModal, setShowModal, labels, containers }) {
  const [state, setState] = React.useState({
    jobTypesList: [],
    selectedJobType: '',
    selectedJobTypeId: '',
    progressCounter: 0,
    toProcess: 0,
    isLoading: false
  });

  const closeModal = () => {
    setShowModal(false);
    setState((prev) => ({
      ...prev,
      selectedJobType: '',
      progressCounter: 0,
      toProcess: 0,
      isLoading: false
    }));
  };

  const sendCreateCropRequest = async (container, label) => {
    await createCropJobs({
      label,
      job_type: state.selectedJobTypeId,
      container
    }).then(() => {
      setState((prev) => ({ ...prev, progressCounter: prev.progressCounter + 1 }));
    });
  };

  const modalAction = async (accepted) => {
    if (accepted) {
      setState((prev) => ({ ...prev, isLoading: true }));
      containers.forEach((container) => {
        labels.forEach(async (label) => {
          await sendCreateCropRequest(container, label);
        });
      });
    } else {
      closeModal();
    }
  };

  React.useEffect(() => {
    if (showModal) {
      getJobTypes().then(async (response) => {
        const data = await response.json();
        const jobTypeList = data.results.reduce((acc, currItem) => {
          acc.push({ label: `${currItem.name}-${currItem.id}`, value: currItem.id });
          return acc;
        }, []);
        setState((prev) => ({ ...prev, jobTypeList }));
      });

      const toProcess = containers.length * labels.length;
      setState((prev) => ({ ...prev, progressCounter: 0, toProcess }));
    }
  }, [showModal]);

  const percentageValue = parseInt(percentage(state.progressCounter, state.toProcess));

  return (
    <ActionModal
      disabledAcceptButton={state.selectedJobType === '' || state.isLoading}
      modalAction={modalAction}
      showModal={showModal}
    >
      <div>
        {state.isLoading && (
          <div>
            <p>Creating a requests: {percentageValue}%</p>
            <ProgressBar now={percentageValue} />
          </div>
        )}
        {!state.isLoading && (
          <div>
            <p>Are you sure? It will create a {state.toProcess} a new requests.</p>
            <p>Please select Job Typ.</p>
            <ArrayDropdown
              list={state.jobTypeList}
              onClick={(source) =>
                setState((prev) => ({
                  ...prev,
                  selectedJobType: source,
                  selectedJobTypeId: source
                }))
              }
              defaultValue="Select Job Type"
            />
          </div>
        )}
      </div>
    </ActionModal>
  );
}

CreateManyCropJobsModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  containers: PropTypes.array,
  labels: PropTypes.array
};

CreateManyCropJobsModal.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  containers: [],
  labels: []
};

export default CreateManyCropJobsModal;
