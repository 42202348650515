import React, { useState, useEffect } from 'react';
import {
  removeCluster,
  removeSelectedCrops,
  moveToNewCluster,
  assignAsReviewer,
  postDiscardCluster
} from 'api/clusters';
import { Button, Spinner, Modal } from 'react-bootstrap';
import ConfirmationModal from 'modals/ConfirmationModal';
import Select from 'react-select';

function ButtonSpinner() {
  return <Spinner size="sm" animation="border" role="status" />;
}

function DialogModalComponent({ showAlert, setShowAlert }) {
  const handleClose = () => setShowAlert(false);
  return (
    <Modal centered show={showAlert} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Not allowed action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Sorry, you can't do it, while there are any draft cluster exist</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function FastLaneButtons({
  cluster,
  refreshData,
  setErrorMessage,
  selectedCrops,
  stopLoading,
  stopLoadingFunction,
  moveSelected,
  selectedUuid
}) {
  const { id, clusterCount } = cluster;
  const [modalShow, setModalShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [draftClusters, setDraftClusters] = useState([]);
  const [isAllSelectedMainCluster, setIsAllSelectedMainCluster] = useState(false);

  const isDisabledClusterActions = clusterCount > 1;

  useEffect(() => {
    setIsBusy(stopLoading);
  }, [stopLoading]);

  useEffect(() => {
    if (selectedCrops.length === 0) {
      return;
    }
    let newIsAllSelectedMainCluster = true;
    const cropsIdMainCluster = cluster.points.filter((el) => el.clusterID === 0);
    cropsIdMainCluster.forEach((el) => {
      if (!selectedCrops.includes(el.id)) {
        newIsAllSelectedMainCluster = false;
      }
    });
    setIsAllSelectedMainCluster(newIsAllSelectedMainCluster);
  }, [selectedCrops]);

  const checkResponse = async (response) => {
    if (response.status === 200) {
      refreshData();
    } else {
      await response
        .json()
        .then((data) => setErrorMessage(data.detail || 'Error'))
        .catch(() => setErrorMessage('Error'));
    }
  };

  const handleDangerousAction = (actionType) => {
    setActionType(actionType);
    setModalShow(true);
  };

  const handleMultipleMove = async (id, params) => {
    const listOfArguments = [];
    for (let i = 1; i < clusterCount; i += 1) {
      const cropIds = cluster.points.filter((el) => el.clusterID === i).map((el) => el.id);
      listOfArguments.push(cropIds);
    }
    const listOfPromises = listOfArguments.map((el) =>
      moveToNewCluster(id, params, { crop_ids: el })
    );

    await Promise.allSettled(listOfPromises).then((responses) => {
      const rejectedResponses = responses.filter((el) => el.status === 'rejected');
      if (rejectedResponses.length > 0) {
        setErrorMessage('Sorry something went wrong');
        return;
      }
      checkResponse(responses[0].value);
    });
  };

  const handleAction = async (aActionType) => {
    stopLoadingFunction(true);
    aActionType = aActionType || actionType;
    const params = { fix: true };
    switch (aActionType) {
      case 'removeCluster':
        await checkResponse(await removeCluster(id, params));
        break;
      case 'removeSelectedCrops':
        await checkResponse(await removeSelectedCrops(id, params, { crop_ids: selectedCrops }));
        break;
      case 'acceptAllNewClusters':
        await handleMultipleMove(id, params);
        break;
      case 'unknowProduct':
        if (!cluster.brandbank_uuid) {
          await checkResponse(await assignAsReviewer(id));
        } else {
          await checkResponse(await assignAsReviewer(id, params));
        }
        break;
      case 'assignSelected':
        if (selectedUuid === cluster.brandbank_uuid) {
          await checkResponse(await assignAsReviewer(id, { brandbank_uuid: selectedUuid }));
        } else {
          await checkResponse(
            await assignAsReviewer(id, { brandbank_uuid: selectedUuid, ...params })
          );
        }
        break;
      case 'ignore':
        await checkResponse(await postDiscardCluster(id));
        break;
      default:
        break;
    }

    setActionType(null);
  };

  useEffect(() => {
    let excludedClusterID = -1;
    if (selectedCrops.length > 0) {
      const selectedClustersID = cluster.points
        .filter((el) => selectedCrops.includes(el.id))
        .map((el) => el.clusterID);
      const makeUniq = (arr) => arr.filter((el, id) => arr.indexOf(el) === id);
      const cropsListUniq = makeUniq(selectedClustersID);
      excludedClusterID = cropsListUniq.length > 1 ? excludedClusterID : cropsListUniq[0];
    }

    const newDraftClusters = [];
    for (let i = 0; i <= clusterCount; i += 1) {
      if (i !== excludedClusterID) {
        const label = i === 0 ? 'Main' : `draft cluster #${i}`;
        const value = i;
        newDraftClusters.push({ label, value });
      }
    }
    setDraftClusters(newDraftClusters);
  }, [clusterCount, selectedCrops]);

  const onChangeSelect = (selectedOption) => {
    if (selectedOption) {
      moveSelected(selectedOption.value);
    }
  };

  const buttonClick = (aActionType) => {
    if (clusterCount > 1) {
      return setShowAlert(true);
    }
    handleDangerousAction(aActionType);
  };

  return (
    <>
      {id && (
        <>
          <div className="text-center mt-1">
            <Button
              style={{ fontSize: '13px' }}
              className="ml-2 mt-3"
              disabled={isBusy || isDisabledClusterActions}
              variant="danger"
              onClick={() => handleDangerousAction('ignore')}
            >
              {isBusy && actionType === 'ignore' && (
                <Spinner size="sm" animation="border" role="status" />
              )}
              Ignore the cluster
            </Button>
            <Button
              className="ml-2 mt-3"
              style={{ fontSize: '13px' }}
              variant="danger"
              disabled={isBusy || isDisabledClusterActions}
              onClick={() => {
                handleDangerousAction('unknowProduct');
              }}
            >
              Set as unknown product
            </Button>
            <Button
              style={{ fontSize: '13px' }}
              className="ml-2 mt-3"
              variant="defoult"
              disabled={!selectedUuid || isBusy || isDisabledClusterActions}
              onClick={() => handleAction('assignSelected')}
            >
              Assign selected class
            </Button>
            <Button
              className="ml-2 mt-3"
              variant="danger"
              style={{ fontSize: '13px' }}
              disabled={isBusy || isDisabledClusterActions}
              onClick={() => buttonClick('removeCluster')}
            >
              Remove cluster
              {isBusy && actionType === 'removeCluster' && <ButtonSpinner />}
            </Button>
            <Button
              disabled={!selectedCrops.length || isBusy || isAllSelectedMainCluster}
              className="ml-2 mt-3"
              variant="default"
              style={{ fontSize: '13px' }}
              onClick={() => handleDangerousAction('removeSelectedCrops')}
            >
              Remove selected crops
              {isBusy && actionType === 'removeSelectedCrops' && <ButtonSpinner />}
            </Button>
            <Button
              disabled={clusterCount === 1 || isBusy}
              className="ml-2 mt-3"
              variant="default"
              style={{ fontSize: '13px' }}
              onClick={() => handleAction('acceptAllNewClusters')}
            >
              Accept all new clusters
              {isBusy && actionType === 'acceptAllNewClusters' && <ButtonSpinner />}
            </Button>
            <div className="d-inline-block">
              <Select
                value=""
                onChange={onChangeSelect}
                options={draftClusters}
                placeholder="move selected to"
                className="ml-2 mt-3"
                isDisabled={!selectedCrops.length || isBusy || isAllSelectedMainCluster}
                isClearable
              />
            </div>
          </div>
          <div className="text-center mt-3">
            {`Number of crops: ${cluster.points.length}, selected: ${selectedCrops.length}`}
          </div>
        </>
      )}
      <ConfirmationModal
        showModal={modalShow}
        disabled={isBusy}
        modalAction={(value) => {
          value && handleAction();
          setModalShow(false);
        }}
      >
        <h3>Confirm your action</h3>
        <p>Do you really want to do this?</p>
      </ConfirmationModal>
      <DialogModalComponent showAlert={showAlert} setShowAlert={setShowAlert} />
    </>
  );
}

export default FastLaneButtons;
