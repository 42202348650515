import React from 'react';
import PropTypes from 'prop-types';

function CropImage({ source, binaryData, rectangle, width, height }) {
  let src = '';

  if (binaryData) {
    src = source
      ? `data:image/jpeg;base64,${source}`
      : `http://via.placeholder.com/${width}x${height}`;
  } else {
    src = source;
  }

  return (
    <svg width={width} height={height} viewBox="0 0 1 1">
      <image href={src} height="100%" width="100%" x="0" y="0" preserveAspectRatio="none" />

      <rect
        x={rectangle.x}
        y={rectangle.y}
        width={rectangle.width}
        height={rectangle.height}
        rx="0.01"
        ry="0.01"
        style={{
          stroke: 'lightseagreen',
          strokeWidth: 0.01,
          fillOpacity: 0.0
        }}
      />
    </svg>
  );
}

CropImage.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  source: PropTypes.string,
  binaryData: PropTypes.bool,
  returned: PropTypes.bool,
  className: PropTypes.string,
  rectangle: PropTypes.object
};

CropImage.defaultProps = {
  width: '200px',
  height: '200px',
  className: '',
  rectangle: {},
  source: '',
  binaryData: false,
  returned: false
};

export default CropImage;
