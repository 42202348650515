import { Card, CardContent, Chip } from '@mui/material';
import React from 'react';

function TaskItem({ task }) {
  return (
    <Card>
      <CardContent>
        {task.title} <Chip label={task.permission} size="small" />
      </CardContent>
    </Card>
  );
}

export default TaskItem;
