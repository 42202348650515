import React, { lazy, Suspense } from 'react';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';

const BatchCrops = lazy(() => import('components/BatchCrops/BatchCrops'));
const LabelerActionButtons = lazy(() => import('./LabelerActionButtons'));

function BatchCropsLabeling({ selectedLabel }) {
  const { setAction } = useBatchLabelingContext();

  React.useEffect(() => {
    setAction('labeling');
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BatchCrops returned>
        <LabelerActionButtons selectedLabel={selectedLabel} />
      </BatchCrops>
    </Suspense>
  );
}

export default BatchCropsLabeling;
