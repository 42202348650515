import { getUserToken } from 'utils';
import { BASE_URL } from './base';

export const clusterFinder = async (props, body) => {
  const pathname = 'clusters/search_by_image/';
  const token = getUserToken();
  let url = BASE_URL + pathname;
  if (props) {
    const queryParams = new URLSearchParams(props).toString();
    url = `${url}?${queryParams}`;
  }

  return fetch(url, {
    method: 'post',
    body: body,
    headers: {
      Authorization: `Token ${token}`
    }
  });
};
