import { UNASSIGNED } from 'views/Clusters/partials/dnd/utils/constants';
import { getDataWithToken, putDataAuthorized } from './base';

export const getManage = (pcId) => {
  const pathname = `clusters/manage/${pcId}`;
  return getDataWithToken(pathname);
};
export const setManage = (pcId, props) => {
  let pathname = `clusters/manage/${pcId}?labeler_id=${props.labeler_id}`;
  if (props.assigned_station !== UNASSIGNED) {
    pathname = `${pathname}&assigned_station=${props.assigned_station}`;
  }

  return putDataAuthorized(pathname);
};
