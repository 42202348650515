import React, { createContext, useReducer, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  SettingsReducer,
  initialState,
  IS_AUTO_LOAD_ON_KEY,
  IS_LABELER_MODE_ON_KEY
} from '../reducers/SettingsReducer';

const SettingsContext = createContext(initialState);

export function SettingsProvider(props) {
  const [state, dispatch] = useReducer(SettingsReducer, initialState);
  const { children } = props;

  const setAutoLoad = (isTurnedOn) => {
    localStorage.setItem(IS_AUTO_LOAD_ON_KEY, isTurnedOn);
    dispatch({
      type: 'AutoLoadValueChanged',
      isAutoLoadOn: isTurnedOn
    });
  };

  const setLabelerMode = (isTurnedOn) => {
    localStorage.setItem(IS_LABELER_MODE_ON_KEY, isTurnedOn);
    dispatch({
      type: 'LabelerModeValueChanged',
      isLabelerMode: isTurnedOn
    });
  };

  const value = {
    isAutoLoadOn: state.isAutoLoadOn,
    isLabelerMode: state.isLabelerMode,
    setAutoLoad: setAutoLoad,
    setLabelerMode: setLabelerMode
  };
  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
}

const useSettings = () => {
  const context = useContext(SettingsContext);

  if (context === undefined) {
    throw new Error('useSettings must be used within SettingsContext');
  }

  return context;
};

SettingsProvider.propTypes = {
  children: PropTypes.node
};

export default useSettings;
