import React, { useState } from 'react';
import ModalWithHistory from 'components/Modal/ModalWithHistory';
import { getClusterHistory } from 'api/clusters';
import PropTypes from 'prop-types';
import { Button, Col } from 'react-bootstrap';
import OverlayUserDetails from 'components/Overlay/OverlayUserDetails';

function ClusterViewHeader({ clusterId, title, labelerId, reviewerId, userType }) {
  const [show, setModalShow] = useState(false);
  const [clusterHistory, setClusterHistory] = useState(false);

  const handleClose = () => setModalShow(false);
  const handleOpen = (data) => {
    setModalShow(true);
    setClusterHistory(data);
    getClusterHistory(clusterId).then(async (response) => {
      const data = await response.json();
      setClusterHistory(data);
    });
  };

  return (
    <div className="row card-body">
      <Col className="d-flex align-items-center" md={10}>
        <h5 style={{ fontWeight: 600 }} className="card-title">
          {title}
        </h5>
      </Col>
      <Col xs={2} style={{ textAlign: 'end' }}>
        {labelerId && userType !== 'labeler' && OverlayUserDetails(labelerId, 'Labeler')}
        {reviewerId && userType === 'labeler' && OverlayUserDetails(reviewerId, 'Reviewer')}
        <Button className="ml-1" onClick={() => handleOpen()} size="sm" variant="defoult">
          <i style={{ fontWeight: 'bold' }} className="fa fa-history" />
        </Button>
      </Col>
      <ModalWithHistory handleClose={handleClose} show={show} data={clusterHistory} />
    </div>
  );
}

ClusterViewHeader.propTypes = {
  title: PropTypes.string,
  clusterId: PropTypes.string
};

ClusterViewHeader.defaultProps = {
  title: 'Cluster Info',
  clusterId: ''
};

export default ClusterViewHeader;
