import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import DialogModal from './Modal';

function ModalWithSVGShape({ show, handleClose, crop }) {
  const [width, setWith] = useState('0');
  const [height, setHeight] = useState('0');
  const imgElement = React.createRef();
  const targetRef = useRef();

  const openInNewTab = () => {
    window.open(crop.url, '_blank', 'noopener,noreferrer');
  };

  const getDimensions = () => {
    const imgWidth = imgElement.current.naturalWidth;
    const imgHeight = imgElement.current.naturalHeight;
    const parentWidth = targetRef.current.offsetWidth;
    if (imgWidth > parentWidth) {
      setWith(parentWidth);
      const calculate = parentWidth / imgWidth;
      setHeight(imgHeight * calculate);
    } else {
      setWith(imgWidth);
      setHeight(imgHeight);
    }
  };

  function calculateDemensions(shapeCoords1, shapeCoords2) {
    const results = shapeCoords1 * 100 - shapeCoords2 * 100;
    return `${results}%`;
  }

  return (
    <DialogModal
      size="lg"
      title="Prereview Crop:"
      body={
        crop ? (
          <div onClick={openInNewTab}>
            <div ref={targetRef}>
              <svg width={width} height={height}>
                <image width={width} height={height} href={crop.url} />
                <rect
                  x={`${crop.x_min * 100}%`}
                  y={`${crop.y_min * 100}%`}
                  width={calculateDemensions(crop.x_max, crop.x_min)}
                  height={calculateDemensions(crop.y_max, crop.y_min)}
                  stroke="#EE2D20"
                  fill="transparent"
                  strokeWidth="6"
                  style={{ cursor: 'pointer' }}
                />
              </svg>
              <img
                style={{ display: 'none' }}
                alt=""
                src={crop.url}
                ref={imgElement}
                onLoad={() => getDimensions()}
              />
            </div>
          </div>
        ) : null
      }
      handleClose={handleClose}
      show={show}
    />
  );
}

ModalWithSVGShape.propTypes = {
  body: PropTypes.any,
  back: PropTypes.string,
  approve: PropTypes.string,
  show: PropTypes.bool,
  handleApprove: PropTypes.func,
  handleClose: PropTypes.func
};

ModalWithSVGShape.defaultProps = {
  body: '',
  back: '',
  approve: '',
  show: false,
  handleApprove: () => {},
  handleClose: () => {}
};

export default ModalWithSVGShape;
