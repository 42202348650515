import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

function OverlayUserDetails(name, role) {
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="tooltip-bottom">{`${role}: ${name}`}</Tooltip>}
    >
      <Button className="mr-2" size="sm" variant="defoult">
        <i style={{ fontWeight: 'bold' }} className="nc-icon nc-single-02" />
      </Button>
    </OverlayTrigger>
  );
}

export default OverlayUserDetails;
