import React, { useState, useEffect } from 'react';
import CropLists from 'components/CropLists/CropLists';
import { Row, ListGroup, Card, Spinner } from 'react-bootstrap';
import { getClusterDetails, getClusterSimilar, reProcess } from 'api/clusters';
import PropTypes from 'prop-types';
import moment from 'moment';
import ClusterViewHeader from './ClusterViewHeader';
import { CropPreview } from './CropPreview';
import { useUserContext } from '../../contexts/Users';
import SelectState from '../../components/Select/SelectState';
import SpinnerButton from '../../components/Buttons/SpinnerButton';
import CropImage from './CropImage';
import { PAGE_SIZE } from '../../components/Paginators/ListPaginator';

function ShowSimilar({ clusterDetails }) {
  const [selectedState, setSelectedState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [prevParams, setPrevParams] = useState({});
  const [results, setResults] = useState({});

  function handleResponce(res, params) {
    setPrevParams(params);
    if (!res) return;
    setResults(res);
  }

  const handleClusterFinder = () => {
    setIsLoading(true);
    const selectedStateValue = selectedState.map((state) => state.value);
    const params = {
      predefined_classes: clusterDetails.predefined_classes,
      blocked: false,
      page: 1,
      page_size: PAGE_SIZE
    };
    if (selectedStateValue.length > 0) params.state__in = selectedStateValue;

    getClusterSimilar(clusterDetails.id, params).then(async (response) => {
      const data = await response.json();
      setIsLoading(false);
      handleResponce(data, params);
    });
  };

  return (
    <div className="p-3">
      <SelectState
        selectedMultiState={selectedState}
        setSelectedMultiState={setSelectedState}
        isMulti
        isDisabled={isLoading}
      />
      <div className="text-center">
        <SpinnerButton
          disabled={false}
          className="btn mt-3"
          onClick={handleClusterFinder}
          isLoading={isLoading}
          value="Show similar"
        />
      </div>
      {isLoading && <Spinner size="md" animation="border" role="status" />}
      {!isLoading && results?.results && (
        <Card className="mt-3">
          <Card.Header />
          <Card.Body>
            <CropImage
              items={results.results}
              previous={results.previous}
              next={results.next}
              count={results.count}
              prevParams={prevParams}
              formData={clusterDetails.id}
            />
          </Card.Body>
        </Card>
      )}
    </div>
  );
}

function ClusterDetails({ clusterId }) {
  const { isAdmin } = useUserContext();
  const [clusterDetails, setClusterDetails] = useState({});
  const [previewCrop, setPreviewCrop] = useState(null);

  useEffect(() => {
    getClusterDetails(clusterId).then(async (response) => {
      const data = await response.json();
      setClusterDetails(data);
    });
  }, [clusterId]);

  return (
    <div className="card-body">
      <Row>
        {clusterDetails ? (
          <>
            <div className="col-6">
              <CropLists
                crops={clusterDetails.points || []}
                onPreviewClicked={(crop) => {
                  setPreviewCrop(crop);
                }}
              />
            </div>
            <div className="col-6">
              <div className="card">
                <ClusterViewHeader clusterId={clusterId} title="Cluster Info" />
                <ListGroup className="ml-1 mr-1" variant="flush">
                  <ListGroup.Item>id: {clusterDetails.id || 'unset'}</ListGroup.Item>
                  <ListGroup.Item>
                    created at:{' '}
                    {clusterDetails.created_at
                      ? moment(clusterDetails.created_at).format('YYYY-MM-DD HH:mm')
                      : 'unset'}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    updated at:{' '}
                    {clusterDetails.updated_at
                      ? moment(clusterDetails.updated_at).format('YYYY-MM-DD HH:mm')
                      : 'unset'}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    preview url: {clusterDetails.preview_url || 'unset'}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    labeler id: {clusterDetails.labeler_id || 'unset'}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    reviewer id: {clusterDetails.reviewer_id || 'unset'}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    job type id: {clusterDetails.job_type_id || 'unset'}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    fix flag: {`${clusterDetails.fix_flag}` || 'unset'}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    reject flag: {`${clusterDetails.reject_flag}` || 'unset'}
                  </ListGroup.Item>
                  <ListGroup.Item>priority: {clusterDetails.priority || 'unset'}</ListGroup.Item>
                  <ListGroup.Item>state: {clusterDetails.state || 'unset'}</ListGroup.Item>
                  <ListGroup.Item>substate: {clusterDetails.substate || 'unset'}</ListGroup.Item>
                  <ListGroup.Item>
                    count of points: {clusterDetails.count_of_points || 'unset'}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    brandbank uuid: {clusterDetails.brandbank_uuid || 'unset'}
                  </ListGroup.Item>
                </ListGroup>
                {isAdmin && <ShowSimilar clusterDetails={clusterDetails} />}
              </div>
              <CropPreview crop={previewCrop} />
            </div>
          </>
        ) : null}
      </Row>
    </div>
  );
}

ClusterDetails.propTypes = {
  clusterId: PropTypes.string
};

ClusterDetails.defaultProps = {
  clusterId: ''
};

export default ClusterDetails;
