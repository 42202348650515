/* eslint react/button-has-type: "off" */

import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

function SpinnerButton({ disabled, onClick, isLoading, value, className, style }) {
  return (
    <button
      id="spinnerButton"
      disabled={disabled}
      style={style}
      name="submit"
      className={className}
      onClick={onClick}
    >
      {isLoading && <Spinner size="sm" animation="border" role="status" />}
      {!isLoading && <span>{value}</span>}
    </button>
  );
}

SpinnerButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  value: PropTypes.string,
  style: PropTypes.object
};

SpinnerButton.defaultProps = {
  className: 'btn btn-fill',
  disabled: true,
  onClick: () => {},
  isLoading: false,
  value: '',
  style: {}
};

export default SpinnerButton;
