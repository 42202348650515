import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Calendar from 'react-select-date';
import {CUSTOM_INPUT_STYLES} from "../StylesConstants";

function DateRangePicker({ setDate, isLoading }) {
  const [showcld_range, setShowcld_range] = useState(false);
  const [rangeDate, setRangeDate] = useState({});
  const datepickerRef = useRef(null);

  function addZero(num) {
    return num > 9 ? num : `0${num}`;
  }

  const startRangeDrop =
    rangeDate.startDate &&
    `${rangeDate.startDate.getFullYear()}-${addZero(rangeDate.startDate.getMonth() + 1)}-${addZero(
      rangeDate.startDate.getDate()
    )}`;

  const endRangeDrop = rangeDate.endDate
    ? `${rangeDate.endDate.getFullYear()}-${addZero(rangeDate.endDate.getMonth() + 1)}-${addZero(
        rangeDate.endDate.getDate()
      )}`
    : startRangeDrop;

  const selectDate = (date) => {
    setRangeDate(date);
    setDate(startRangeDrop, endRangeDrop);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datepickerRef.current && !datepickerRef.current.contains(event.target)) {
        setShowcld_range(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

    const customStyle=  {
            ...CUSTOM_INPUT_STYLES,
            minWidth: 240,
            cursor: 'default',
            textAlign: 'center',
            color: 'hsl(0, 0%, 50%)'
        };

    if (!isLoading)  {
        customStyle.background = '#fff';
        customStyle.color = 'hsl(0, 0%, 20%)';
    }

  return (
    <div ref={datepickerRef}>
      <div className="inputRelative mr-3">
        <Form.Control
          size='md'
          onClick={() => setShowcld_range(!showcld_range)}
          placeholder="Select date range"
          disabled={isLoading}
          style={customStyle}
          readOnly
          value={`${startRangeDrop} <--> ${endRangeDrop}`}
          className="cldRangeInput"
        />

        <div className={!showcld_range && 'd-none'} style={{position: 'absolute',  zIndex: 1}}>
          <Calendar
            showDateInputField={false}
            slotInfo={false}
            templateClr="blue"
            selectDateType="range"
            onSelect={selectDate}
          />
        </div>
      </div>
    </div>
  );
}

DateRangePicker.propTypes = {
  isLoading: PropTypes.bool,
  setDate: PropTypes.func
};

DateRangePicker.defaultProps = {
  isLoading: false,
  setDate: () => {}
};

export default DateRangePicker;
