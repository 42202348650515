import React, { lazy, Suspense } from 'react';
import { getData, updateItem, deleteItem, addItem } from 'api/imageFinder';
import { useImageFinderContext } from 'contexts/ImageFinderProcess';

const CrudTable = lazy(() => import('./CrudTable'));

function ImageFinderIndexes() {
  const [state, setState] = React.useState({
    indexes: [],
    isLoading: false,
    response: ''
  });

  const { getToken } = useImageFinderContext();

  const indexesName = [
    { name: 'Config name', key: 'config_name', row: true, label: true },
    { name: 'Model name', key: 'model_name', row: true, label: true },
    { name: 'Host', key: 'host', row: true, label: true },
    { name: 'Namespace', key: 'namespace', row: true, label: true },
    { name: 'Index name', key: 'index_name', row: true, label: true },
    { name: 'Environment', key: 'environment', label: true },
    { name: 'Internal Host', key: 'internal_host', label: true }
  ];

  const setError = (error) =>
    setState((prev) => ({
      ...prev,
      isLoading: false,
      error: error
    }));

  const getIndexes = async () => {
    let abortController = null;
    abortController = new AbortController();
    try {
      const token = await getToken();
      const indexes = await getData(token, 'pinecone_indexer');
      setState((prev) => ({ ...prev, indexes }));
    } catch (error) {
      setError(error.toString());
    }
    return () => abortController.abort();
  };

  React.useEffect(() => {
    getIndexes();
  }, []);

  const setData = async () => {
    const token = await getToken();
    getData(token, 'pinecone_indexer').then((res) => {
      setState((prev) => ({
        ...prev,
        indexes: res,
        isLoading: false,
        response: 'success'
      }));
      setTimeout(
        setState((prev) => ({
          ...prev,
          response: ''
        })),
        100
      );
    });
  };

  const addIndex = async (data) => {
    setState((prev) => ({ ...prev, isLoading: true }));
    const token = await getToken();
    addItem(data, 'pinecone_indexer', token)
      .then(() => {
        setData();
      })
      .catch(() => {
        setState((prev) => ({ ...prev, isLoading: false, response: 'error' }));
      });
  };

  const updateIndex = async (data, id) => {
    setState((prev) => ({ ...prev, isLoading: true }));
    const token = await getToken();
    updateItem(data, 'pinecone_indexer', id, token)
      .then(() => {
        setData();
      })
      .catch(() => {
        setState((prev) => ({ ...prev, isLoading: false, response: 'error' }));
      });
  };

  const deleteIndex = async (id) => {
    setState((prev) => ({ ...prev, isLoading: true }));
    const token = await getToken();
    deleteItem('pinecone_indexer', id, token)
      .then(() => {
        setData();
      })
      .catch(() => {
        setState((prev) => ({ ...prev, isLoading: false, response: 'error' }));
      });
  };

  return (
    <Suspense fallback={<p>Loading...</p>}>
      <CrudTable
        rowNames={state.indexes}
        titleNames={indexesName}
        addItem={addIndex}
        updateItem={updateIndex}
        deleteItem={deleteIndex}
        isLoading={state.isLoading}
        response={state.response}
      />
    </Suspense>
  );
}

export default ImageFinderIndexes;
