import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import ImageImporterModal from 'modals/ImageImporterModal';

const exampleFileString =
  'url,image_id,container,fold\nhttps://example-photo-url.com,photo-id,container-name,test';

const csvFileToArray = (string) => {
  const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
  const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

  const array = csvRows.map((i) => {
    const values = i.split(',');
    const obj = csvHeader.reduce((object, header, index) => {
      object[header] = values[index];
      return object;
    }, {});
    return obj;
  });

  return array;
};

function ImageImporter() {
  const [state, setState] = useState({
    error: undefined,
    csvObjects: [],
    showModal: false
  });

  const [file, setFile] = useState();

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
    setState({
      error: undefined,
      csvObjects: [],
      showModal: false
    });
  };

  const verifyCSV = (string) => {
    let csvObjects = csvFileToArray(string);

    if (!csvObjects.length) {
      throw new Error(`Invalid length ${csvObjects.length}`);
    }
    const properties = ['url', 'container', 'image_id', 'fold'];

    csvObjects.forEach((element) => {
      properties.forEach((property) => {
        if (!(property in element)) {
          throw new Error(`Invalid field for file. ${property}: ${JSON.stringify(element)}`);
        }
      });
    });

    csvObjects = csvObjects.map((element) => {
      if (element.url.startsWith('"')) {
        element.url = element.url.replace('"', '');
      }
      return element;
    });

    if (csvObjects.length > 40) {
      csvObjects = csvObjects.slice(0, 40);
    }

    setState((prev) => ({ ...prev, csvObjects, error: undefined }));
  };

  const onClickLoadFile = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        try {
          verifyCSV(event.target.result);
        } catch (error) {
          setFile(undefined);
          setState((prev) => ({ ...prev, error: error.toString(), csvObjects: [] }));
        }
      };

      fileReader.readAsText(file);
    }
  };

  const downloadExample = (event) => {
    event.preventDefault();
    const element = document.createElement('a');
    const file = new Blob([exampleFileString], {
      type: 'text/plain'
    });
    element.href = URL.createObjectURL(file);
    element.download = 'example.csv';
    document.body.appendChild(element);
    element.click();
  };

  const onClickImportImages = (e) => {
    e.preventDefault();
    setState((prev) => ({ ...prev, showModal: true }));
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {state.error && (
        <div className="alert alert-danger">
          <strong>`{state.error}`</strong>
        </div>
      )}
      <h3>CSV file:</h3>
      <form>
        <input type="file" id="csvFileInput" accept=".csv" onChange={handleOnChange} />
        <br />
        <br />
        <button
          className="btn btn-fill mr-5"
          disabled={!file}
          type="button"
          onClick={(e) => {
            onClickLoadFile(e);
          }}
        >
          Load file
        </button>
        <button
          disabled={state.csvObjects.length === 0}
          className="btn btn-fill"
          type="button"
          onClick={onClickImportImages}
        >
          Import images
        </button>
        <button type="button" className="btn btn-fill ml-5" onClick={downloadExample}>
          Example CSV
        </button>
      </form>
      <br />
      {state.csvObjects.length > 0 && (
        <>
          <Table className="table-hover table-striped">
            <thead>
              <tr>
                <th className="border-0">Id</th>
                <th className="border-0">Url</th>
                <th className="border-0">Image Id</th>
                <th className="border-0">Container</th>
                <th className="border-0">Fold</th>
              </tr>
            </thead>
            <tbody>
              {state.csvObjects.map((item, id) => (
                <tr>
                  <td>{id}</td>
                  <td>
                    <p
                      className="btn btn-primary stretched-link"
                      onClick={() => window.open(item.url, '_blank', 'noopener,noreferrer')}
                    >
                      {item?.url.replace(/(.{20})..+/, '$1…')}
                    </p>
                  </td>
                  <td>{item.image_id}</td>
                  <td>{item.container}</td>
                  <td>{item.fold}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <ImageImporterModal
            showModal={state.showModal}
            setShowModal={(value) => setState((prev) => ({ ...prev, showModal: value }))}
            file={file}
          />
        </>
      )}
    </div>
  );
}

export default ImageImporter;
