import React, { useEffect } from 'react';
import { useCropReviewProcessContext } from 'contexts/CropReviewProcess';
import { useUserContext } from 'contexts/Users';
import { updateCropStatus } from 'api/crops';
import { JobState, UserType } from 'enums';
import JobTypeDropdown from 'components/Buttons/JobTypeDropdown';
import { updateLabelName } from 'api/featurestore';
import CropSKUReference from './CropSKUReference';
import CropReviewProcessBase from './CropReviewProcessBase';

function CropLabeling() {
  const {
    cropSource,
    cropInfo,
    predefinedSet,
    fetchNextCrop,
    jobType,
    jobTypeList,
    onClickJobType,
    setError
  } = useCropReviewProcessContext();
  const { assignJobTypes, setAssignJobTypes } = useUserContext();
  const [state, setState] = React.useState({
    newLabel: ''
  });

  useEffect(() => {
    setAssignJobTypes();
  }, []);

  const assignJobTypesList = jobTypeList.filter((item) => assignJobTypes.includes(item.id));

  const serviceCropToFinishedState = async () => {
    const { newLabel } = state;
    const { id } = cropInfo;
    const { label_id } = cropInfo;
    const predefined = predefinedSet.filter((item) => item.name === newLabel).shift();

    if (!predefined) {
      setError(
        `BrandBank predefined class not defined properly for this job. Can you please verify it?`
      );
      return;
    }

    setState((prev) => ({ ...prev, isLoading: true }));

    await updateCropStatus(UserType.LABELER, id, {
      state: JobState.FINISHED,
      new_label: newLabel
    });
    await updateLabelName(label_id, {
      new_label: newLabel,
      new_uuid: predefined.uuid
    });

    setState((prev) => ({ ...prev, isLoading: false }));
    fetchNextCrop(UserType.LABELER, jobType.id);
  };

  const updateAndFetchNextCrop = async () => {
    if (jobType.requires_qc) {
      const { id } = cropInfo;
      const cropStatusParameters = {
        state: JobState.AWAITING_QC,
        new_label: state.newLabel
      };

      await updateCropStatus(UserType.LABELER, id, cropStatusParameters);

      setState((prev) => ({ ...prev, isLoading: false }));
      fetchNextCrop(UserType.LABELER, jobType.id);
    } else {
      serviceCropToFinishedState();
    }
  };

  const isSubmitDisable =
    cropSource?.object.description.name === '' ||
    state.newLabel === '' ||
    predefinedSet.filter((item) => item.name === state.newLabel).length === 0;

  const serviceEnter = (event) =>
    event.key === 'Enter' && !isSubmitDisable ? updateAndFetchNextCrop() : undefined;

  return (
    <CropReviewProcessBase userType={UserType.LABELER} title="Crop Labeling">
      <div className="container">
        <h4>Labeler action:</h4>
        <div className="row">
          <JobTypeDropdown
            selectedJobType={jobType}
            jobTypeList={assignJobTypesList}
            onClickJobType={(jobType) => onClickJobType(UserType.LABELER, jobType)}
          />
          {jobType && (
            <div className="pt-2">
              <label htmlFor="scales">QC&nbsp;&nbsp;</label>
              <input
                type="checkbox"
                id="scales"
                name="scales"
                checked={jobType.requires_qc}
                disabled
              />
            </div>
          )}
        </div>
        <CropSKUReference
          action="cropView"
          predefinedSet={predefinedSet}
          newLabel={state.newLabel}
          selectedLabel={state.newLabel}
          onClick={(newLabel) => setState((prev) => ({ ...prev, newLabel: newLabel.name }))}
        />
        <div>
          <button
            className="btn btn-sm btn-fill btn-warning"
            disabled={state.newLabel === ''}
            onClick={() => setState((prev) => ({ ...prev, newLabel: '' }))}
            data-dismiss="alert"
            type="button"
          >
            Clear
          </button>
          <input
            style={{
              width: '600px'
            }}
            className="mt-3"
            autoFocus
            onKeyDown={serviceEnter}
            list="searchDropdownInputId"
            value={state.newLabel}
            placeholder="New label"
            onChange={(e) => setState((prev) => ({ ...prev, newLabel: e.target.value }))}
          />
          <button
            className="btn btn-sm btn-fill"
            disabled={isSubmitDisable}
            onClick={updateAndFetchNextCrop}
            data-dismiss="alert"
            type="button"
          >
            Submit
          </button>
        </div>
      </div>
    </CropReviewProcessBase>
  );
}

export default CropLabeling;
