import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getLabelers } from 'api/user';
import { Card, Col, Row, Form } from 'react-bootstrap';
import Select from 'react-select';
import SpinnerButton from 'components/Buttons/SpinnerButton';
import Calendar from 'react-select-date';

export function addZero(num) {
  return num > 9 ? num : `0${num}`;
}

function LabelerAnalyticsFilters({ filterCallback, isLoading }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [labelers, setLabelers] = useState([]);
  const [showcld_range, setShowcld_range] = useState(false);
  const [rangeDate, setRangeDate] = useState({});

  useEffect(() => {
    getLabelers().then(async (response) => {
      const data = await response.json();
      const lebelersList = data.results;
      const lebelers = [];
      lebelersList.forEach((Lebeler) => {
        lebelers.push({ value: Lebeler, label: `${Lebeler.user.username}` });
      });
      setLabelers(lebelers);
    });
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 180,
      maxWidth: 850,
      minHeight: 50
    })
  };

  const selectDate = (date) => {
    setRangeDate(date);
  };

  const startRangeDrop =
    rangeDate.startDate &&
    `${rangeDate.startDate.getFullYear()}-${addZero(rangeDate.startDate.getMonth() + 1)}-${addZero(
      rangeDate.startDate.getDate()
    )}`;

  const endRangeDrop = rangeDate.endDate
    ? `${rangeDate.endDate.getFullYear()}-${addZero(rangeDate.endDate.getMonth() + 1)}-${addZero(
        rangeDate.endDate.getDate()
      )}`
    : startRangeDrop;

  const onClick = () => {
    filterCallback(selectedOption.value, startRangeDrop, `${endRangeDrop} 23:59:59.999`);
    setShowcld_range(false);
  };

  const isDisabled = isLoading || !startRangeDrop || !endRangeDrop || !selectedOption;

  return (
    <Col md="12">
      <Card className="strpied-tabled-with-hover pb-2">
        <Card.Header>
          <Card.Title as="h4">Filters:</Card.Title>
        </Card.Header>
        <Card.Body className="table-full-width table-responsive px-3">
          <Row>
            <Select
              defaultValue={selectedOption}
              styles={customStyles}
              onChange={setSelectedOption}
              options={labelers}
              placeholder="Select labeler"
              isSearchable
              className="mr-3 ml-3"
              isDisabled={isLoading}
              isClearable
            />
            <div>
              <div className="inputRelative mr-3">
                <Form.Control
                  size="lg"
                  onClick={() => setShowcld_range(!showcld_range)}
                  placeholder="Select date range"
                  disabled={isLoading}
                  style={{
                    height: '50px',
                    cursor: 'default',
                    background: '#fff',
                    color: 'hsl(0, 0%, 20%)'
                  }}
                  readOnly
                  value={`${startRangeDrop}  < -- >  ${endRangeDrop}`}
                  className="cldRangeInput"
                />

                <div className={`${!showcld_range && 'd-none'} cldAbsolute`}>
                  <Calendar
                    showDateInputField={false}
                    slotInfo={false}
                    templateClr="blue"
                    selectDateType="range"
                    onSelect={(date) => selectDate(date)}
                  />
                </div>
              </div>
            </div>

            <SpinnerButton
              style={{ height: '50px' }}
              isLoading={isLoading}
              disabled={isDisabled}
              onClick={onClick}
              value="search"
            />
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}
LabelerAnalyticsFilters.propTypes = {
  filterCallback: PropTypes.func,
  isLoading: PropTypes.bool
};

LabelerAnalyticsFilters.defaultProps = {
  filterCallback: () => {},
  isLoading: false
};

export default LabelerAnalyticsFilters;
