export const fetchRetry = async (url, options, n) => {
  try {
    return await fetch(url, options)
  } catch (err) {
    if (n === 1) throw err;
    if (err.status === 401) {
      return await fetchRetry(url, options, n - 1);
    }
  }
};
