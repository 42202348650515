import React from 'react';
import PropTypes from 'prop-types';
import { getJobTypes } from 'api/jobTypes';
import { createCropJobs } from 'api/crops';
import ArrayDropdown from 'components/Buttons/ArrayDropdown';
import ActionModal from './ActionModal';

function CreateCropJobModal({ showModal, setShowModal, label, container }) {
  const [state, setState] = React.useState({
    jobTypeList: [],
    selectedJobType: '',
    selectedJobTypeId: '',
    progressCounter: 0,
    message: ''
  });

  const closeModal = () => {
    setShowModal(false);
    setState((prev) => ({ ...prev, selectedJobType: '', message: '' }));
  };

  const modalAction = async (accepted) => {
    if (accepted) {
      const response = await createCropJobs({
        label,
        job_type: state.selectedJobTypeId,
        container
      });
      const data = await response.json();
      setState((prev) => ({ ...prev, message: JSON.stringify(data) }));
    } else {
      closeModal();
    }
  };

  React.useEffect(() => {
    if (showModal) {
      getJobTypes().then(async (response) => {
        const data = await response.json();
        const jobTypeList = data.results.reduce((acc, currItem) => {
          acc.push({ label: `${currItem.name}-${currItem.id}`, value: currItem.id });
          return acc;
        }, []);
        setState((prev) => ({ ...prev, jobTypeList }));
      });
    }
  }, [showModal]);

  return (
    <ActionModal
      disabledAcceptButton={state.selectedJobType === '' || state.message !== ''}
      modalAction={modalAction}
      showModal={showModal}
    >
      <div>
        {state.message && state.message}
        {!state.message && (
          <div>
            <p>Are you sure?</p>
            <p>
              You will create a new jobs for {label} with {container} name.
            </p>
            <p>Please select Job Typ.</p>
            <ArrayDropdown
              list={state.jobTypeList}
              onClick={(source) =>
                setState((prev) => ({
                  ...prev,
                  selectedJobType: source,
                  selectedJobTypeId: source
                }))
              }
              defaultValue="Select Job Type"
            />
          </div>
        )}
      </div>
    </ActionModal>
  );
}

CreateCropJobModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  container: PropTypes.string,
  label: PropTypes.string
};

CreateCropJobModal.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  container: '',
  label: ''
};

export default CreateCropJobModal;
