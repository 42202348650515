/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { getJobTypes } from 'api/jobTypes';
import { getLabelers } from 'api/user';
import { JobStateChoices } from 'enums';
import { Card, Col, FormControl, InputGroup } from 'react-bootstrap';
import ArrayDropdown from 'components/Buttons/ArrayDropdown';

export const labelerNameValue = (labeler) =>
  `${labeler.user.first_name} ${labeler.user.last_name} ${labeler.user.username}`;

function ListFilters({ filterCallback, labelerRole }) {
  const [state, setState] = React.useState({
    jobTypeList: [],
    jobStateList: [],
    jobType: '',
    jobState: '',
    labelersList: [],
    reviewersList: [],
    labeler: '',
    retriver: ''
  });
  const [id, setId] = React.useState('');

  React.useEffect(() => {
    getJobTypes().then(async (response) => {
      const data = await response.json();
      const jobTypeList = data.results.reduce((acc, currItem) => {
        acc.push({ label: `${currItem.name}-${currItem.id}`, value: currItem.id });
        return acc;
      }, []);
      setState((prev) => ({ ...prev, jobTypeList }));
    });
    getLabelers().then(async (response) => {
      const data = await response.json();
      const usersList = data.results.reduce((acc, currItem) => {
        acc.push({ label: currItem.user.username, value: currItem.id });
        return acc;
      }, []);

      /* eslint-disable */
      const jobStateList = [];
      for (let item in JobStateChoices) {
        if (JobStateChoices.hasOwnProperty(item)) {
          jobStateList.push({ label: JobStateChoices[item], value: item });
        }
      }

      const reviewers = data.results.filter((el) => el.permission !== 'NORMAL');
      const reviewersList = reviewers.reduce((acc, currItem) => {
        acc.push({ label: currItem.user.username, value: currItem.id });
        return acc;
      }, []);
      setState((prev) => ({ ...prev, labelersList: usersList, reviewersList, jobStateList }));
    });
  }, []);

  const onClick = (value, key) => {
    setState((prev) => ({ ...prev, [key]: value }));
    filterCallback(key, value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      filterCallback('id', id);
    }
  };

  return (
    <Col md="12">
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title as="h4">Filters:</Card.Title>
        </Card.Header>
        <Card.Body className="table-full-width table-responsive px-3">
          <div className="row">
            <ArrayDropdown
              onClick={(source) => onClick(source, 'jobType')}
              defaultValue="Select Job Type"
              list={state.jobTypeList}
            />
            <ArrayDropdown
              onClick={(source) => onClick(source, 'jobState')}
              defaultValue="Select Job State"
              list={state.jobStateList}
            />
            <ArrayDropdown
              onClick={(source) => onClick(source, 'labeler')}
              defaultValue="Select Labeler"
              list={state.labelersList}
            />
            {labelerRole === 'Reviewer' && (
              <ArrayDropdown
                onClick={(source) => onClick(source, 'reviewer')}
                defaultValue="Select reviewer"
                list={state.reviewersList}
              />
            )}
            <div>
              <InputGroup className="col-xs-3 pl-3 mb-3">
                <FormControl
                  style={{
                    height: '44px',
                    border: '2px solid #4e938e'
                  }}
                  aria-describedby="basic-addon2"
                  list="searchDropdownInputId"
                  value={id}
                  placeholder="Search for ID and press enter"
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={(e) => setId(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-fill btn-default"
                  style={{ paddingBottom: '1px' }}
                  id="button-addon2"
                  onClick={() => {
                    filterCallback('id', id);
                  }}
                >
                  <i style={{ fontWeight: 'bold' }} className="nc-icon nc-zoom-split" />
                </button>
              </InputGroup>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}

ListFilters.propTypes = {
  filterCallback: PropTypes.func,
  labelerRole: PropTypes.string
};

ListFilters.defaultProps = {
  filterCallback: () => {},
  labelerRole: ''
};

export default ListFilters;
