import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getTaskResults } from 'api/taskResults';
import { useUserContext } from 'contexts/Users';
import BasicNotification from './BasicNotification';

function TaskResultsNotification() {
  const [tasksQuantity, setTasksQuantity] = useState([]);
  const { userInfo } = useUserContext();

  useEffect(() => {
    getTaskResults({ status: 'PENDING', page_size: 1000, page: 1 })
      .then((response) => response.json())
      .then((source) => setTasksQuantity(source.count));
  }, []);

  return (
    <div>
      {tasksQuantity > 10 ? (
        <BasicNotification
          type="info"
          show
          autoDismiss="500"
          text={
            <div>
              <span>
                There are currently <span>{tasksQuantity} </span> other{' '}
              </span>
              <span>
                tasks in the queue, which may cause delays in creating new items. Please try again
                later.
                <span>
                  {userInfo.permission === 'STAFF' ? (
                    <span>
                      {' '}
                      For more information, go to
                      <Link
                        style={{ color: '#fff', textDecoration: 'underLine' }}
                        target="_blank"
                        to="task-results"
                      >
                        {' '}
                        Task Results
                      </Link>
                      .
                    </span>
                  ) : null}
                </span>
              </span>
            </div>
          }
        />
      ) : null}
    </div>
  );
}

export default TaskResultsNotification;
