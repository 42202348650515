import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, Alert } from 'react-bootstrap';
import { getJobTypes } from 'api/jobTypes';
import { createCropJob, createCropJobs } from 'api/crops';
import ArrayDropdown from 'components/Buttons/ArrayDropdown';
import { percentage } from 'utils';
import { JobState } from 'enums';
import CropProducts from 'views/CropLabelingProcess/CropProducts';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';
import ActionModal from './ActionModal';

function ImageFinderModal({ showModal, setShowModal, items, selectedConfig, onAccepted }) {
  const [state, setState] = React.useState({
    jobTypesItems: [],
    selectedJobType: '',
    noJobTypes: false,
    selectedJobTypeId: '',
    isLoading: false,
    progressCounter: 0,
    container: '',
    createJob: false,
    doubleLabeling: false,
    selectedLabel: ''
  });

  let timeoutHandle;

  const {
    predefinedSet,
    handleJobTypeChange,
    clearPredefinedClass,
    isLoadingSKU
  } = useBatchLabelingContext();

  const closeModal = () => {
    setShowModal(false);
    setState((prev) => ({
      ...prev,
      selectedJobType: '',
      isLoading: false,
      progressCounter: 0
    }));
  };

  const processCreateCropJobsWithoutImport = async (fromFeatureStore) => {
    const { selectedJobTypeId, container } = state;

    await fromFeatureStore.map(async (cropInfo) => {
      await createCropJob({
        label_id: cropInfo.metadata.fs_label_id,
        job_type: selectedJobTypeId,
        photo_id: cropInfo.metadata.image_id,
        new_label: cropInfo.metadata.name,
        container_id: container
      }).then(() => {
        setState((prev) => ({ ...prev, progressCounter: prev.progressCounter + 1 }));
        clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(closeModal, 1000);
      });
    });
  };

  const processCreateCropJobsWithImport = async (requiredImportToFeaturestore) => {
    if (requiredImportToFeaturestore.length > 0) {
      const { selectedJobTypeId, container, createJob } = state;
      await createCropJobs({
        image_finder: true,
        matches: requiredImportToFeaturestore,
        job_type: selectedJobTypeId,
        container,
        create_job: createJob,
        image_finder_config_name: selectedConfig,
        new_label: state.selectedLabel,
        state: JobState.AWAITING_QC
      }).then(() => {
        setState((prev) => ({ ...prev, progressCounter: items.length }));
        clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(closeModal, 500);
      });
    }
  };

  const modalAction = async (accepted) => {
    if (accepted) {
      setState((prev) => ({ ...prev, isLoading: true, progressCounter: 0 }));
      processCreateCropJobsWithoutImport(items.filter((cropInfo) => cropInfo.metadata.fs_label_id));
      processCreateCropJobsWithImport(items.filter((cropInfo) => !cropInfo.metadata.fs_label_id));
      onAccepted();
    } else if (!state.isLoading) {
      setShowModal(false);
    }
  };

  React.useEffect(() => {
    if (showModal) {
      getJobTypes({ page_size: 999, allowed_if_config__name: selectedConfig }).then(
        async (response) => {
          const data = await response.json();
          const jobTypeList = data.results.reduce((acc, currItem) => {
            acc.push({ label: `${currItem.name}-${currItem.id}`, value: currItem });
            return acc;
          }, []);
          setState((prev) => ({ ...prev, jobTypeList }));

          setState((prev) => ({
            ...prev,
            jobTypesItems: data.results,
            jobTypeList,
            noJobTypes: data.results.length === 0,
            selectedJobType: '',
            selectedJobTypeId: '',
            isLoading: false,
            progressCounter: 0,
            container: '',
            createJob: false,
            doubleLabeling: false,
            selectedLabel: ''
          }));
          clearPredefinedClass();
        }
      );
    }
  }, [showModal]);

  const hasClassId = (id) => !!(id && !/^""*$/.test(id));

  const percentageValue = parseInt(percentage(state.progressCounter, items.length));
  const { selectedPredefinedClassesId, selectedLabel, noJobTypes } = state;

  return (
    <ActionModal
      disabledAcceptButton={
        !state.selectedJobType || !state.selectedLabel || state.isLoading || state.container === ''
      }
      disabledCloseButton={state.isLoading}
      modalAction={modalAction}
      showModal={showModal}
      size="large">
      {state.isLoading && (
        <div>
          <p>Creating a jobs: {percentageValue}%</p>
          <ProgressBar now={percentageValue} />
        </div>
      )}
      {!state.isLoading && (
        <div>
          {state.selectedJobTypeId !== '' && state.container === '' && (
            <div className="alert alert-danger">
              <strong>Sorry, invalid job-type configuration</strong>
            </div>
          )}
          <p>Are you sure?</p>
          <p>You will create a {items.length} new items.</p>
          <p>Please select Job Typ.</p>
          <ArrayDropdown
            onClick={(source) => {
              setState((prev) => ({
                ...prev,
                selectedJobType: source.name,
                selectedJobTypeId: source.id,
                container: source.default_container_name || '',
                selectedPredefinedClassesId: source.predefined_classes_id
              }));
              clearPredefinedClass();
              handleJobTypeChange(source);
            }}
            defaultValue="Select Job Type"
            list={state.jobTypeList}
          />
          {isLoadingSKU && (
            <>
              <br />
              <Alert variant="warning">The base is loading...</Alert>
            </>
          )}
          {noJobTypes && (
            <>
              <br />
              <Alert variant="danger">
                There is no allowed JobType for {selectedConfig}. Please contact your manager.
              </Alert>
            </>
          )}
          {hasClassId(selectedPredefinedClassesId) && (
            <div className="pt-3">
              <div className="container border-gray">
                <CropProducts
                  predefinedSet={predefinedSet}
                  onChange={(selectedLabel) => setState((prev) => ({ ...prev, selectedLabel }))}
                  selectedLabel={selectedLabel}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </ActionModal>
  );
}

ImageFinderModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  onAccepted: PropTypes.func,
  items: PropTypes.array,
  selectedConfig: PropTypes.string
};

ImageFinderModal.defaultProps = {
  showModal: false,
  selectedConfig: '',
  setShowModal: () => {},
  onAccepted: () => {},
  items: []
};

export default ImageFinderModal;
