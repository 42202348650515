import { putJsonDataAuthorized, postData, getDataWithToken } from './base';

export const makeLogin = ({ username, password }) => postData('token/', { username, password });

export const getUser = (id) => getDataWithToken(`users/${id}/`);

export const getLabelers = () => getDataWithToken('labelers/', { page_size: '1000' });

export const updateJobType = (id, props) => putJsonDataAuthorized(`labelers/${id}`, props);

export const getLabelersJobTypes = (id) => {
  const pathname = `labelers/${id}/`;
  return getDataWithToken(pathname);
};
