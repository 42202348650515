import React, { useState } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { getJobAnalytics, getCropJobAnalytics } from 'api/labelerAnalytics';
import LabelerAnalyticsFilters from 'views/LabelerAnalytics/LabelerAnalyticsFilters';
import moment from 'moment';
import { calculateStatePerDay } from './LabelerAnalyticsFunc';
import { ColumnChart, TimeChart } from './LabelerAnalyticsChart';

function LabelerAnalytics() {
  const [loading, setLoading] = useState(false);
  const [datedatasets, setDateDatasets] = useState([]);
  const [jobTypesWorkingTime, setJobTypesWorkingTime] = useState([]);
  const [exactHours, setExactHours] = useState([]);
  const [completedJobTypesDates, setCompletedJobTypesDates] = useState([]);
  const [completedQCJobTypesDates, setCompletedQCJobTypesDates] = useState([]);
  const [completedQCCropJobsDates, setCompletedQCCropJobsDates] = useState([]);
  const [totalJobTypesPerDay, setTotalJobTypesPerDay] = useState([]);
  const [completedCropJobsDates, setCompletedCropJobsDates] = useState([]);
  const [totalCropJobPerDay, setTotalCropJobPerDay] = useState([]);
  const [totalQcJobsPerDay, setTotalQcJobsPerDay] = useState([]);
  const [totalQcCropJobsPerDay, setTotalQcCropJobsPerDay] = useState([]);
  const [isQC, setIsQC] = useState([]);

  function calculateJobTypeTime(data, startDate, endDate) {
    if (data.length) {
      const obj = {};
      data.forEach((el) => {
        const jobTypeId = el.name;
        el.data.forEach((item) => {
          const day = moment(item.created).format('YYYY-MM-DD');
          if (!obj[jobTypeId] || !obj[jobTypeId][day]) {
            if (!obj[jobTypeId]) {
              obj[jobTypeId] = {
                [day]: {
                  start: new Date(item.created).getTime(),
                  end: new Date(item.created).getTime()
                }
              };
            } else {
              obj[jobTypeId][day] = {
                start: new Date(item.created).getTime(),
                end: new Date(item.created).getTime()
              };
            }
          } else {
            if (obj[jobTypeId][day].end < new Date(item.created).getTime()) {
              obj[jobTypeId][day].end = new Date(item.created).getTime();
            }
            if (obj[jobTypeId][day].start > new Date(item.created).getTime()) {
              obj[jobTypeId][day].start = new Date(item.created).getTime();
            }
          }
        });
      });
      /* eslint-disable */
      const chartData = [];
      for (const name in obj) {
        if (obj.hasOwnProperty(name)) {
          const days = [];
          for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
            const day = m.format('YYYY-MM-DD');
            if (obj[name][day]) {
              const startTime = moment(new Date(obj[name][day].start)).format('HH:mm');
              const endTime = moment(new Date(obj[name][day].end)).format('HH:mm');
              const randomDay = moment().add(-1, 'days').format('YYY/MM/DD');
              days.push({
                x: day,
                y: [
                  new Date(`${startTime} ${randomDay}`).getTime(),
                  new Date(`${endTime} ${randomDay}`).getTime()
                ]
              });
            }
          }
          chartData.push({ name, data: days });
        }
      }
      chartData.sort((a, b) => (a.data[0].x > b.data[0].x ? 1 : -1));
      setJobTypesWorkingTime(chartData);
      /* eslint-enable */
    }
    setLoading(false);
  }

  function calculateWorkingHours(data, startDate, endDate) {
    const obj = {};

    data.forEach((el) => {
      const day = moment(el.created).format('YYYY-MM-DD');
      if (!obj[day]) {
        obj[day] = { start: new Date(el.created).getTime(), end: new Date(el.created).getTime() };
      } else {
        if (obj[day].end < new Date(el.created).getTime()) {
          obj[day].end = new Date(el.created).getTime();
        }
        if (obj[day].start > new Date(el.created).getTime()) {
          obj[day].start = new Date(el.created).getTime();
        }
      }
    });

    const days = [];
    const hours = [];

    for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
      const day = m.format('YYYY-MM-DD');
      if (obj[day]) {
        const startTime = moment(new Date(obj[day].start)).format('HH:mm');
        const endTime = moment(new Date(obj[day].end)).format('HH:mm');
        const randomDay = moment().add(-1, 'days').format('YYY/MM/DD');
        days.push({
          x: day,
          y: [
            new Date(`${startTime} ${randomDay}`).getTime(),
            new Date(`${endTime} ${randomDay}`).getTime()
          ]
        });

        hours.push({
          x: new Date(`${startTime} ${randomDay}`).getTime(),
          borderColor: 'lightgrey',
          label: {
            borderColor: '#808080',
            style: {
              color: '#fff',
              background: '#808080'
            },
            orientation: 'horizontal',
            text: startTime
          }
        });
        hours.push({
          x: new Date(`${endTime} ${randomDay}`).getTime(),
          borderColor: 'lightgrey',
          label: {
            borderColor: '#808080',
            style: {
              color: '#fff',
              background: '#808080'
            },
            orientation: 'horizontal',
            text: endTime
          }
        });
      }
    }
    setExactHours(hours);
    setDateDatasets(days);
  }

  function getCompletedPerDay(params) {
    const { count, seriesName } = calculateStatePerDay(params);
    const { setDate, setTotalPerDay } = params;
    setDate(Object.keys(count));
    /* eslint-disable */
    Object.values(count).forEach((el) => {
      for (const name in el) {
        if (el.hasOwnProperty(name)) {
          seriesName.find((element, index) => {
            if (element.name.toString() === name.toString()) {
              seriesName[index].data.push(el[name]);
            }
          });
        }
      }
    });
    setTotalPerDay(seriesName);
  }

  function getJobAndCropJob(labeler, startDate, endDate) {
    const isQC = labeler.permission === 'QC' || labeler.permission === 'STAFF';
    setIsQC(isQC);
    const created = { created__gte: startDate, created__lte: endDate };

    Promise.all([
      getCropJobAnalytics({
        crop_job__labeler: labeler.id,
        states_for: 'labeler',
        ...created
      }),
      getJobAnalytics({
        job__labeler: labeler.id,
        states_for: 'labeler',
        ...created
      }),
      isQC &&
        getCropJobAnalytics({
          crop_job__reviewer: labeler.id,
          states_for: 'reviewer',
          ...created
        }),
      isQC &&
        getJobAnalytics({
          job__reviewer: labeler.id,
          states_for: 'reviewer',
          ...created
        })
    ])
      .then(async ([resCropJob, resJob, resRetriwerCropJob, resRetriwerJob]) => {
        const cropJobs = await resCropJob.json();
        const jobs = await resJob.json();
        const retriwerCropJob = resRetriwerCropJob ? await resRetriwerCropJob.json() : [];
        const retriwerJob = resRetriwerJob ? await resRetriwerJob.json() : [];
        getCompletedPerDay({
          finishedArr: jobs,
          setDate: setCompletedJobTypesDates,
          setTotalPerDay: setTotalJobTypesPerDay,
          state: ['AWAITING_QC', 'FINISHED_WITHOUT_QC']
        });
        getCompletedPerDay({
          finishedArr: cropJobs,
          setDate: setCompletedCropJobsDates,
          setTotalPerDay: setTotalCropJobPerDay,
          state: ['AWAITING_QC', 'FINISHED_WITHOUT_QC']
        });
        isQC &&
          getCompletedPerDay({
            finishedArr: retriwerJob,
            setDate: setCompletedQCJobTypesDates,
            setTotalPerDay: setTotalQcJobsPerDay,
            state: ['FINISHED', 'RETURNED']
          });
        isQC &&
          getCompletedPerDay({
            finishedArr: retriwerCropJob,
            setDate: setCompletedQCCropJobsDates,
            setTotalPerDay: setTotalQcCropJobsPerDay,
            state: ['FINISHED', 'RETURNED']
          });

        return [...cropJobs, ...jobs, ...retriwerCropJob, ...retriwerJob];
      })
      .then((allJobs) => {
        calculateWorkingHours(allJobs, startDate, endDate);

        const jobTypesObj = {};

        allJobs.forEach((el) => {
          if (!jobTypesObj[el.job_type]) {
            jobTypesObj[el.job_type] = [el];
          } else {
            jobTypesObj[el.job_type].push(el);
          }
        });
        const jobTypeArr = Object.keys(jobTypesObj).map((key) => ({
          name: Number(key),
          data: [...jobTypesObj[key]]
        }));
        calculateJobTypeTime(jobTypeArr, startDate, endDate);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const filterCallback = (labeler, startDate, endDate) => {
    setLoading(true);
    getJobAndCropJob(labeler, startDate, endDate);
  };

  return (
    <Container fluid>
      <Row>
        <LabelerAnalyticsFilters filterCallback={filterCallback} isLoading={loading} />
        {datedatasets.length ? (
          <>
            <Col md="6" xs="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-full-width table-responsive px-4">
                  <Card.Title as="h4" className="mb-3">
                    Overall Working Hours
                  </Card.Title>
                  <TimeChart
                    series={[{ data: datedatasets }]}
                    exactHours={exactHours}
                    type="rangeBar"
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" xs="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-full-width table-responsive px-4">
                  <Card.Title as="h4" className="mb-3">
                    {' '}
                    Working Hours per Job Type
                  </Card.Title>
                  {jobTypesWorkingTime.length && (
                    <TimeChart
                      series={jobTypesWorkingTime}
                      jobType
                      exactHours={exactHours}
                      type="rangeBar"
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" xs="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-full-width table-responsive px-4">
                  <Card.Title as="h4" className="mb-3">
                    {' '}
                    Overall Job Tasks completed
                  </Card.Title>
                  <ColumnChart
                    job
                    title="Total Job tasks per day"
                    series={totalJobTypesPerDay}
                    categories={completedJobTypesDates}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" xs="12">
              <Card className="strpied-tabled-with-hover">
                <Card.Body className="table-full-width table-responsive px-4">
                  <Card.Title as="h4" className="mb-3">
                    {' '}
                    Overall Crop Job Tasks completed
                  </Card.Title>
                  <ColumnChart
                    crop
                    title="Total Crop Job tasks per day"
                    series={totalCropJobPerDay}
                    categories={completedCropJobsDates}
                  />
                </Card.Body>
              </Card>
            </Col>
            {isQC ? (
              <>
                <Col md="6" xs="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body className="table-full-width table-responsive px-4">
                      <Card.Title as="h4" className="mb-3">
                        {' '}
                        Overall QC Job Tasks completed
                      </Card.Title>
                      <ColumnChart
                        job
                        title="Total Job tasks per day"
                        series={totalQcJobsPerDay}
                        categories={completedQCJobTypesDates}
                      />
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="6" xs="12">
                  <Card className="strpied-tabled-with-hover">
                    <Card.Body className="table-full-width table-responsive px-4">
                      <Card.Title as="h4" className="mb-3">
                        {' '}
                        Overall QC Crop Job Tasks completed
                      </Card.Title>
                      <ColumnChart
                        crop
                        title="Total Crop Job tasks per day"
                        series={totalQcCropJobsPerDay}
                        categories={completedQCCropJobsDates}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </>
            ) : null}
          </>
        ) : null}
      </Row>
    </Container>
  );
}

export default LabelerAnalytics;
