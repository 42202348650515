/* eslint-disable */
import React from 'react';
import { Alert, Table as BootstrapTable, Card, Spinner } from 'react-bootstrap';
import ListPaginator, { PAGE_SIZE } from 'components/Paginators/ListPaginator';

function Table({
  columnNames,
  loadData,
  rowNames,
  title,
  count,
  page,
  previous,
  next,
  isLoading = false
}) {
  return (
    <>
      {isLoading && (
        <Card>
          <div className="p-3">
            <Spinner size="lg" animation="border" role="status" />
          </div>
        </Card>
      )}
      {!isLoading && (
        <Card className="strpied-tabled-with-hover card-scroll">
          <Card.Header>
            <Card.Title as="h4">
              {title} (Total: {count})
            </Card.Title>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-4">
            {rowNames && rowNames.length ? (
              <>
                <BootstrapTable className="table-hover table-striped">
                  <thead>
                    <tr>
                      {columnNames.map((columnName) => (
                        <th className="border-0" key={columnName}>
                          {columnName}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rowNames &&
                      rowNames.map((rowName, i) => (
                        <tr key={`row_${i}`}>
                          {columnNames.map((columnName, j) => (
                            <td className="border-0" key={`el_${i}_${j}`}>
                              {rowName[columnName]}
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </BootstrapTable>
                {count > PAGE_SIZE && (
                  <ListPaginator
                    total={count}
                    current={page}
                    previous={previous}
                    next={next}
                    changePage={loadData}
                  />
                )}
              </>
            ) : (
              <Alert variant="dark">No results</Alert>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  );
}

export default Table;
