import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getNextCrop } from 'api/crops';
import { HttpStatus } from 'enums';
import { getCrop, getPhotoObjects } from 'api/featurestore';
import { getJobTypes } from 'api/jobTypes';
import { getCropPredefinedClass } from 'api/brandbank';

const CropReviewProcessContext = React.createContext();

export const useCropReviewProcessContext = () =>
  React.useContext(CropReviewProcessContext);

class CropReviewProcessProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: undefined,
      cropInfo: undefined,
      cropSource: undefined,
      jobType: undefined,
      jobTypeName: '',
      photoObjects: [],
      lastPhotoId: '',
      predefinedSet: [],
      jobTypeList: []
    };
  }

  fetchJobTypeList = () => {
    this.setState((prev) => ({
      ...prev,
      jobType: undefined,
      jobTypeList: [],
      predefinedSet: [],
      lastPhotoId: '',
      cropInfo: undefined,
      cropSource: undefined,
      error: false,
      errorMessage: undefined,
      jobTypeName: ''
    }));

    getJobTypes().then(async (response) => {
      const data = await response.json();
      this.setState((prev) => ({ ...prev, jobTypeList: data.results }));
    });
  };

  setError = (errorMessage = undefined) =>
    this.setState((prev) => ({ ...prev, error: true, errorMessage }));

  loadCrop = async ({ label_id, photo_id }) => {
    getCrop(label_id, {
      photo_id: photo_id,
      centroid_scale: 2.0,
      photo_as_url: true
    })
      .then(async (response) => {
        if (response.status === HttpStatus.SUCCESS) {
          const data = await response.json();
          this.setState((prev) => ({ ...prev, cropSource: data }));
        } else {
          this.setError();
        }
      })
      .catch(() => this.setError());
  };

  loadPhotoObjects = async ({ photo_id }) => {
    const { lastPhotoId } = this.state;

    if (lastPhotoId !== photo_id) {
      getPhotoObjects(photo_id)
        .then(async (response) => {
          if (response.status === 200) {
            const data = await response.json();
            this.setState((prev) => ({
              ...prev,
              photoObjects: data,
              lastPhotoId: photo_id
            }));
          } else {
            this.setError();
          }
        })
        .catch(() => this.setError());
    }
  };

  loadPredefinedClass = async ({ predefined_classes_id, name }) => {
    const { jobTypeName } = this.state;
    if (predefined_classes_id) {
      if (jobTypeName !== name) {
        await getCropPredefinedClass(predefined_classes_id)
          .then((response) => response.json())
          .then((data) => {
            this.setState((prev) => ({
              ...prev,
              predefinedSet: data.results,
              jobTypeName: name
            }));
          });
      }
    } else {
      this.setError(`BrandBank predefined class not defined for ${name} type.`);
    }
  };

  fetchNextCrop = (userType, jobTypeId) => {
    this.setState((prev) => ({
      ...prev,
      cropSource: undefined,
      error: false,
      errorMessage: undefined,
      cropInfo: undefined
    }));

    getNextCrop(userType, { job_type: jobTypeId })
      .then(async (response) => {
        if (response.status === HttpStatus.SUCCESS) {
          let data = await response.json();
          data = data instanceof Array ? data[0] : data;
          this.setState((prev) => ({ ...prev, cropInfo: data }));
          this.loadPhotoObjects(data);
          this.loadCrop(data);
        } else {
          this.setState((prev) => ({
            ...prev,
            cropSource: undefined,
            cropInfo: undefined,
            error: true
          }));
          const data = await response.json();
          this.setError(data.shift());
        }
      })
      .catch(() => this.setError());
  };

  onClickJobType = (userType, jobType) => {
    this.setState((prev) => ({ ...prev, jobType, predefinedSet: [] }));
    this.loadPredefinedClass(jobType);
    this.fetchNextCrop(userType, jobType ? jobType.id : undefined);
  };

  render() {
    const { children } = this.props;

    return (
      <CropReviewProcessContext.Provider
        value={{
          ...this.state,
          fetchNextCrop: this.fetchNextCrop,
          setError: this.setError,
          onClickJobType: this.onClickJobType,
          fetchJobTypeList: this.fetchJobTypeList
        }}
      >
        {children}
      </CropReviewProcessContext.Provider>
    );
  }
}

CropReviewProcessProvider.propTypes = {
  children: PropTypes.node
};

CropReviewProcessProvider.defaultProps = {
  children: null
};

export default CropReviewProcessProvider;
