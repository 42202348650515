import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PageNotFound } from 'views/PageNotFound';
import dashboardRoutes from 'routers/dashboardRoutes';
import { useUserContext } from 'contexts/Users';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

function Router() {
  const routers = [];
  const { accessToStation, isClusteringLabeler } = useUserContext();

  const processChild = (item, child) => {
    const isClusteringLabelerItem = isClusteringLabeler(item);
    if (isClusteringLabelerItem && accessToStation !== child.path) {
      child.isDisabled = true;
      item.isDisabled = true;
    } else {
      child.isDisabled = false;
      item.isDisabled = false;
    }
    return child;
  };

  dashboardRoutes.forEach((item) => {
    routers.push(item);
    if (item.children && item.children.length > 0) {
      const isClusteringLabelerItem = isClusteringLabeler(item);
      item.children = item.children.map((child) => processChild(item, child));
      if (isClusteringLabelerItem) {
        item.isDisabled = true;
      }
      routers.push(...item.children);
    }
  });

  return (
    <Switch>
      {routers.map((item) =>
        item.private ? (
          <PrivateRoute
            access={item.access}
            key={item.path}
            path={item.path}
            component={item.component}
          />
        ) : (
          <PublicRoute key={item.path} path={item.path} component={item.component} />
        )
      )}
      <PrivateRoute exact path="/" />
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default Router;
