import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Accordion } from 'react-bootstrap';
import { getAuditReportDetails, getStatsPerDay } from 'api/auditReport';
import Table from 'components/Table/Table';
import { Link, useParams, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import ProductionReportDetailFilters from './ProductionReportDetailFilters';
import SpinnerButton from '../../components/Buttons/SpinnerButton';
import { PAGE_SIZE } from '../../components/Paginators/ListPaginator';

function InfoCard({ id, parentCrop }) {
  const [isLoading, setIsLoading] = useState(false);
  const [countByDate, setCountByDate] = useState([]);
  const [error, setError] = useState(false);

  const styles = {
    headerContainer: {
      position: 'relative',
      cursor: 'pointer'
    },
    wrapper: {
      position: 'absolute',
      left: 15,
      right: 15,
      background: 'inherit',
      zIndex: 100,
      marginTop: 2,
      boxShadow: 'rgba(0, 0, 0, 1) 0 20px 20px -20px'
    },
    subContainer: {
      maxHeight: '50vh',
      overflow: 'auto'
    },
    topPlus: {
      position: 'absolute',
      right: 16,
      top: 16
    },
    topMinus: {
      position: 'absolute',
      right: 16,
      top: -37,
      background: '#fff',
      pointerEvents: 'none'
    }
  };

  const onShowMoreClick = () => {
    setIsLoading(true);
    const params = { ordering: 'date' };
    getStatsPerDay(id, params).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        await response
          .json()
          .then((data) => {
            setCountByDate(data.results);
            setError(false);
          })
          .catch(() => setError(true));
      } else {
        setError(true);
      }
      setIsLoading(false);
    });
  };

  return (
    <Card>
      <Card.Body className="p-3">
        <Row>
          <Col xs="12" md="6">
            <span className="text-muted">Client:</span> {parentCrop?.client}
            <br />
            <span className="text-muted">Containers:</span> {parentCrop?.containers.join(',  ')}
            <br />
            <span className="text-muted">Model:</span> {parentCrop?.model}
            <br />
            <span className="text-muted">Th. Model:</span> {parentCrop?.threshold_model}
            <span> / </span>
            <span className="text-muted">Th. Analysis:</span> {parentCrop?.threshold_analysis}
            <br />
            <span className="text-muted">Created:</span> {parentCrop?.created_at}
            <br />
            <span className="text-muted">Accuracy:</span> {parentCrop?.accuracy}
            <br />
            <span className="text-muted">Errors count:</span> {parentCrop?.crops_checked_count} /{' '}
            {parentCrop?.crops_count}
            <span> (&nbsp;{parentCrop?.errors_count_percent}&nbsp;)</span>
          </Col>
          <Col xs="12" md="6">
            <span className="text-muted">date from:</span>{' '}
            {parentCrop?.date_from && moment(parentCrop?.date_from).format('YYYY-MM-DD')}
            <span> / </span>
            <span className="text-muted">date to:</span>{' '}
            {parentCrop?.date_to && moment(parentCrop?.date_to).format('YYYY-MM-DD')}
            <span> ( {parentCrop?.days_duration} day(s) )</span>
            <br />
            <span className="text-muted">Images:</span> {parentCrop?.images_count}
            <span> / </span>
            <span className="text-muted">Crops:</span> {parentCrop?.crops_count}
            <div className="pt-3">
              {countByDate.length === 0 ? (
                <>
                  <SpinnerButton
                    isLoading={isLoading}
                    disabled={false}
                    onClick={onShowMoreClick}
                    value="Show more"
                    className="btn mr-3"
                    style={{ minHeight: 50 }}
                  />
                  {error && (
                    <span className="error-text text-nowrap">Sorry, something went wrong</span>
                  )}
                </>
              ) : (
                <Accordion defaultActiveKey="0">
                  <Accordion.Toggle eventKey="0" as="div" style={styles.headerContainer}>
                    <Card className="m-0">
                      <Card.Body>
                        More Info
                        <FontAwesomeIcon icon={faPlus} style={styles.topPlus} />
                      </Card.Body>
                    </Card>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0" style={styles.wrapper}>
                    <Card className="m-0 p-0">
                      <Card.Body className="m-0 p-0">
                        <FontAwesomeIcon icon={faMinus} style={styles.topMinus} />
                        <div style={styles.subContainer}>
                          <ul className="py-2 px-3 mt-0 mb-0 mr-0 ml-3">
                            {countByDate.map((el) => (
                              <li key={el.date}>
                                {el.date}: {el.images_count} images, {el.crops_count} crops
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Card.Body>
                    </Card>
                  </Accordion.Collapse>
                </Accordion>
              )}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function ProductionReportDetails() {
  const [reportDetails, setReportDetails] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [page, setPage] = useState(1);
  const [next, setNext] = useState(null);
  const [count, setCount] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [parentCrop, setParentCrop] = useState(null);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [parameters, setParameters] = useState({});
  const [error, setError] = useState(false);
  const [currentState, setCurrentState]= useState({});

  const { id } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    if (state?.crop) {
      setCurrentState(state);
      const newParentCrop = { ...state.crop };
      newParentCrop.accuracy = state.crop.accuracy
        ? `${(100 * state.crop.accuracy).toFixed(2)}%`
        : '';
      newParentCrop.created_at = moment(state.crop.created_at).format('YYYY-MM-DD HH:mm:ss');
      newParentCrop.errors_count_percent = state.crop.crops_count
        ? `${(100 * state.crop.crops_checked_count / state.crop.crops_count ).toFixed(2) }%`
        : '';
      newParentCrop.date_from = moment(state.crop.date_from).format('YYYY-MM-DD');
      newParentCrop.date_to = moment(state.crop.date_to).format('YYYY-MM-DD');

      const dateFrom = moment(newParentCrop.date_from);
      const dateTo = moment(newParentCrop.date_to);

      newParentCrop.days_duration = moment.duration(dateTo.diff(dateFrom)).asDays().toFixed(0);
      setParentCrop(newParentCrop);
    }
  }, []);

  const roundScore = (score) => {
    const number = 10**8;
    return (Math.round(score * number) / number);
  }

  const setAuditReportDetails = (params) => {
    getAuditReportDetails(id, { ...params, page_size: PAGE_SIZE }).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        const thisState = state || currentState;
        await response
          .json()
          .then((data) => {
            const rowNames = [];
            data.results.forEach((item) => {
              rowNames.push({
                Label: item.label,
                'Sum Score': roundScore(item.score),
                'Avg Score': roundScore(item.mean_score),
                'Max Score': roundScore(item.max_score),
                'Min Score': roundScore(item.min_score),
                Check: (
                  <Link
                    to={{
                      pathname: `/check/${item.id}`,
                      state: {
                        label: item.label ,
                        pathnameBack:`/audit-report-details/${thisState.crop.id}`,
                        stateBack: {...thisState}
                        }
                    }}
                  >
                    <button className="btn btn-light d-flex align-items-center" type="button">
                      {item.crops_checked_count}/{item.crops_count}
                      <i className="nc-icon nc-stre-right" />
                    </button>
                  </Link>
                )
              });
            });
            setReportDetails(rowNames);
            setPrevId(id);
            setNext(data.next);
            setCount(data.count);
            setPrevious(data.previous);
            setError(false);
          })
          .catch(() => setError(true));
      } else {
        setError(true);
      }
      setIsLoadingSearch(false);
    });
  };

  useEffect(() => {
    if (prevId !== id) {
      setAuditReportDetails({ page: 1, ...parameters });
    }
  }, [id]);

  const loadData = (params) => {
    setPage(params.page);
    setAuditReportDetails({ page: params.page, ...parameters });
  };

  const columnNames = ['Label', 'Sum Score', 'Avg Score', 'Max Score', 'Min Score', 'Check'];

  const filterCallback = (selectedSorting, searchLabel) => {
    setIsLoadingSearch(true);
    const params = { ...parameters, search: searchLabel };
    if (selectedSorting) params.ordering = selectedSorting;
    setPage(1);
    setParameters(params);
    setAuditReportDetails({ page: 1, ...params });
  };

  return (
    <>
      <InfoCard parentCrop={parentCrop} id={id} />
      <ProductionReportDetailFilters
        filterCallback={filterCallback}
        isLoadingSearch={isLoadingSearch}
      />
      {error ? (
        <div className="alert alert-danger">Sorry, something went wrong</div>
      ) : (
        <Table
          page={page}
          next={next}
          count={count}
          previous={previous}
          rowNames={reportDetails}
          columnNames={columnNames}
          loadData={loadData}
        />
      )}
    </>
  );
}

export default ProductionReportDetails;
