import React from 'react';
import Box from '@mui/material/Box';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Typography from '@mui/material/Typography';
import TaskItem from 'views/Clusters/partials/dnd/TaskItem';
import SortableTaskItem from 'views/Clusters/partials/dnd/SortableTaskItem';

function BoardSection({ id, title, tasks }) {
  const { setNodeRef } = useDroppable({
    id
  });

  return (
    <Box sx={{ backgroundColor: '#eee', padding: 1, minHeight: 40 }}>
      <SortableContext id={id} items={tasks} strategy={verticalListSortingStrategy}>
        <div ref={setNodeRef}>
          {tasks.map((task) => (
            <Box key={task.id} sx={{ mb: 2 }}>
              <SortableTaskItem id={task.id}>
                <TaskItem task={task} />
              </SortableTaskItem>
            </Box>
          ))}
        </div>
      </SortableContext>
    </Box>
  );
}

export default BoardSection;
