import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, Row, Tooltip, Col, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import DialogModal from './Modal';

function ModalWithHistory({ show, handleClose, data }) {
  return (
    <DialogModal
      title="History"
      size="lg"
      body={
        <ListGroup>
          {(data || []).map(
            ({
              changed_by,
              history_date,
              labeler_username,
              new_label,
              reviewer_username,
              state,
              substate,
              brandbank_uuid,
              predefined_classes,
              job_type_id,
              fix_flag,
              reject_flag,
              history_change_reason
            }) => (
              <ListGroup.Item>
                <Row>
                  <Col>
                    <div className="text-muted fw-bold">
                      {moment(history_date).format('YYYY-MM-DD HH:mm')}
                    </div>
                  </Col>
                  <Col className="col-auto text-end">
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-changed-by">Changed by</Tooltip>}>
                      <b className="d-flex align-items-center">
                        <i className="nc-icon nc-circle-09 mr-1" /> {changed_by}
                      </b>
                    </OverlayTrigger>
                  </Col>
                </Row>
                <div className="ms-2 me-auto">
                  <div>
                    State: <b>{state}</b>
                  </div>
                </div>
                <div className="ms-2 me-auto">
                  <div>
                    Labeler username: <b>{labeler_username} </b>
                  </div>
                </div>
                <div className="ms-2 me-auto">
                  <div>
                    Reviewer username:<b> {reviewer_username}</b>
                  </div>
                </div>
                <div className="ms-2 me-auto">
                  <div>
                    New label: <span style={{ fontWeight: '200' }}>{new_label}</span>
                  </div>
                </div>
                {substate ? (
                  <div className="ms-2 me-auto">
                    <div>
                      Substate: <b>{substate}</b>
                    </div>
                  </div>
                ) : null}
                {brandbank_uuid ? (
                  <div className="ms-2 me-auto">
                    <div>
                      Brandbank uuid:{' '}
                      <span style={{ fontWeight: '200' }}>
                        <b>{brandbank_uuid}</b>
                      </span>
                    </div>
                  </div>
                ) : null}
                {predefined_classes ? (
                  <div className="ms-2 me-auto">
                    <div>
                      Predefined classes: <b>{predefined_classes}</b>
                    </div>
                  </div>
                ) : null}
                {job_type_id ? (
                  <div className="ms-2 me-auto">
                    <div>
                      jobType id: <b>{job_type_id}</b>
                    </div>
                  </div>
                ) : null}
                {`${fix_flag}` ? (
                  <div className="ms-2 me-auto">
                    <div>
                      Fix flag: <b>{`${fix_flag}`}</b>
                    </div>
                  </div>
                ) : null}
                {`${reject_flag}` ? (
                  <div className="ms-2 me-auto">
                    <div>
                      Reject flag: <b>{`${reject_flag}`}</b>
                    </div>
                  </div>
                ) : null}
                {history_change_reason ? (
                  <div className="ms-2 me-auto">
                    <div>
                      Comment: <b>{history_change_reason}</b>
                    </div>
                  </div>
                ) : null}
              </ListGroup.Item>
            )
          )}
        </ListGroup>
      }
      handleClose={handleClose}
      show={show}
    />
  );
}

ModalWithHistory.propTypes = {
  title: PropTypes.string,
  body: PropTypes.any,
  back: PropTypes.string,
  approve: PropTypes.string,
  show: PropTypes.bool,
  handleApprove: PropTypes.func,
  handleClose: PropTypes.func
};

ModalWithHistory.defaultProps = {
  title: '',
  body: '',
  back: '',
  approve: '',
  show: false,
  handleApprove: () => {},
  handleClose: () => {}
};

export default ModalWithHistory;
