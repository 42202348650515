import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useLayoutContext } from 'contexts/LayoutContext';
import dashboardRoutes from 'routers/dashboardRoutes';
import { useUserContext } from 'contexts/Users';

function Header() {
  const { userPermission } = useUserContext();
  const location = useLocation();
  const { toogleSidebar, showSidebar } = useLayoutContext();
  const [isClosed, setIsClosed] = useState(true);

  const styles = location.pathname === "/cleaning" || location.pathname === "/identification"
    ? { background: '#fff', paddingRight: 220 }
    : { background: '#fff' };

    useEffect(() => {
      setIsClosed(true)
    }, [location.pathname]);

  return (
    <ul className={`nav nav-tabs ${isClosed ? 'nav-tabs-closed' : 'nav-tabs-open'}`} style={styles}>
      <div className="nav-tabs_btn" onClick={() => setIsClosed(!isClosed)}>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
        <span>&nbsp;</span>
      </div>
      <a onClick={toogleSidebar} className="m-1 btn nav-link">
        <i className={`nc-icon ${showSidebar ? 'nc-stre-left' : 'nc-stre-right'}`} />
      </a>
      <div className="nav-tabs-container d-flex ml-2 ml-lg-0 mt-1 mb-1">
        {dashboardRoutes.map((item) => {
          const routers = [];
          let isChild = false;
          if (item.children && item.children.length > 0) {
            isChild = item.children.filter((child) => child.path === location.pathname).length > 0;
          }

          if ((location.pathname === item.path && item.children) || isChild) {
            routers.push(item);
            item.children.forEach((child) => {
              if (child.access.includes(userPermission) && !child.notVisible) routers.push(child);
            });
          } else if (location.pathname === item.path) {
            routers.push(item);
          }

          return routers.map((item) => {
            const disabled = item.path === location.pathname || item.isDisabled ? 'disabled' : '';
            const underLine = item.path === location.pathname ? 'custom-underline' : '';
            return (
              <Link
                key={item.name}
                to={item.path}
                className={`mr-0 mr-lg-1 btn nav-link ${disabled} ${underLine}`}
                style={{ fontWeight: 'bold', fontSize: '17px' }}
              >
                {item.tabName || item.name}
              </Link>
            );
          });
        })}
      </div>
    </ul>
  );
}

export default Header;
