import { getDataWithToken } from './base';

export const getJobAnalytics = (params) => {
  const pathname = 'statistics/jobs/';
  const props = params;
  return getDataWithToken(pathname, props);
};

export const getCropJobAnalytics = (params) => {
  const pathname = 'statistics/crop_jobs/';
  const props = params;
  return getDataWithToken(pathname, props);
};
