import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { getClusterDetails } from '../../../api/clusters';
import CropLists from '../../../components/CropLists/CropLists';

function GalleryCropsModal({ galleryCropsState, setGalleryCropsState }) {
  const [galleryCrops, setGalleryCrops] = useState(undefined);

  const getGalleryCrops = (id) => {
    getClusterDetails(id).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        const data = await response.json();
        setGalleryCrops(data);
      }
    });
  };

  useEffect(() => {
    if (!galleryCropsState.clusterId) return;
    setGalleryCrops(undefined);
    getGalleryCrops(galleryCropsState.clusterId);
  }, [galleryCropsState.clusterId]);

  const handleClose = () => {
    setGalleryCropsState({
      clusterId: galleryCropsState.clusterId,
      showModal: false
    });
  };

  return (
    <Modal
      size="xl"
      show={galleryCropsState.showModal}
      centered
      onHide={handleClose}
      data-testid="modal_parent"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h4" className="mt-0">
          Crops
        </Modal.Title>
      </Modal.Header>
      <Modal.Body data-testid="gallery-crops_parent">
        {galleryCrops ? (
          <CropLists
            crops={galleryCrops.points || []}
            setSelectedCrop={() => {}}
            selectedCrops={[]}
          />
        ) : (
          <Spinner size="sm" animation="border" role="status" />
        )}
      </Modal.Body>
    </Modal>
  );
}

GalleryCropsModal.propTypes = {
  galleryCropsState: PropTypes.object,
  setGalleryCropsState: PropTypes.func
};

GalleryCropsModal.defaultProps = {
  galleryCropsState: {}
};

export default GalleryCropsModal;
