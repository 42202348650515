import React, { useState } from 'react';
import { ListGroup } from 'react-bootstrap';
import TaskResultsNotification from 'components/Notifications/TaskResultsNotification';
import CreateManyCropJobsModal from 'modals/CreateManyCropJobsModal';

const MAX_CONTAINERS_COUNT = 20;
const MAX_LABELS_COUNT = 50;

const exampleFileString = JSON.stringify(
  {
    containers: ['container_name_1', 'container_name_2'],
    labels: ['label_name_1', 'label_name_2']
  },
  null,
  4
);

function CreateManyCropJobRequests() {
  const [state, setState] = useState({
    error: undefined,
    containers: [],
    labels: [],
    showModal: false
  });
  const [file, setFile] = useState();

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const verifyJSON = (string) => {
    const parsedObject = JSON.parse(string);

    if (!parsedObject.containers || parsedObject.containers.length === 0) {
      throw new Error('Invalid containers');
    }

    if (parsedObject.containers.length > MAX_CONTAINERS_COUNT) {
      throw new Error(`To many containers. Maximum value is: ${MAX_CONTAINERS_COUNT}`);
    }

    if (!parsedObject.labels || parsedObject.labels.length === 0) {
      throw new Error('Invalid labels');
    }

    if (parsedObject.labels.length > MAX_LABELS_COUNT) {
      throw new Error(`To many labels. Maximum value is: ${MAX_LABELS_COUNT}`);
    }

    const { containers, labels } = parsedObject;
    setState((prev) => ({ ...prev, containers, labels, error: undefined }));
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        try {
          verifyJSON(event.target.result);
        } catch (error) {
          setFile(undefined);
          setState((prev) => ({ ...prev, error: error.toString() }));
        }
      };

      fileReader.readAsText(file);
    }
  };

  const downloadExampleJson = (event) => {
    event.preventDefault();
    const element = document.createElement('a');
    const file = new Blob([exampleFileString], {
      type: 'text/plain'
    });
    element.href = URL.createObjectURL(file);
    element.download = 'example.json';
    document.body.appendChild(element);
    element.click();
  };

  const onClickCreate = (e) => {
    e.preventDefault();
    setState((prev) => ({ ...prev, showModal: true }));
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {state.error && (
        <div className="alert alert-danger">
          <strong>`{state.error}`</strong>
        </div>
      )}
      <TaskResultsNotification />
      <h3>JSON file with Containers and Labels.</h3>
      <form>
        <input type="file" id="csvFileInput" accept=".json" onChange={handleOnChange} />

        <button
          className="btn btn-fill mr-5"
          disabled={!file}
          type="button"
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        >
          IMPORT JSON
        </button>

        <button type="button" className="btn btn-fill mr-5" onClick={downloadExampleJson}>
          Example JSON
        </button>

        <button
          disabled={state.labels.length === 0 || state.containers.length === 0}
          className="btn btn-fill"
          type="button"
          onClick={onClickCreate}
        >
          Create Crop Jobs
        </button>
      </form>
      <br />
      {state.labels.length > 0 && state.containers.length > 0 && (
        <div className="row">
          <div className="col-xs-6">
            <h3>Containers</h3>
            <ListGroup as="ol" numbered>
              {state.containers.map((value) => (
                <ListGroup.Item key={value} as="li">
                  {value}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
          <div className="col-xs-6">
            <h3>Labels</h3>
            <ListGroup as="ol" numbered>
              {state.labels &&
                state.labels.map((value) => (
                  <ListGroup.Item key={value} as="li">
                    {value}
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </div>
        </div>
      )}
      <CreateManyCropJobsModal
        showModal={state.showModal}
        setShowModal={(value) => setState((prev) => ({ ...prev, showModal: value }))}
        containers={state.containers}
        labels={state.labels}
      />
    </div>
  );
}

export default CreateManyCropJobRequests;
