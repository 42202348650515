import React, { useEffect, useState } from 'react';
import { getAuditReportModels } from 'api/auditReport';
import PropTypes from 'prop-types';
import Select from './Select';

function SelectModel({ selectedModel, setSelectedModel, className, isDisabled }) {
  const [Models, setModels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getAuditReportModels().then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        await response
          .json()
          .then((data) => {
            const ModelsList = data;
            const Models = [];
            ModelsList.forEach((el) => {
              Models.push({ value: el, label: el });
            });
            setModels(Models);
          })
          .catch(() => setIsError(true));
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    });
  }, []);

  return isError ? (
    <div className="d-flex align-items-center">
      <div className={`${className} error-text `}>
        Model filter - error, <br />
        please refresh or try later
      </div>
    </div>
  ) : (
    <Select
      value={selectedModel}
      onChange={setSelectedModel}
      className={className}
      options={Models}
      placeholder="Models"
      isLoading={isLoading}
      isDisabled={isDisabled}
      isSearchable
      isClearable
    />
  );
}

SelectModel.propTypes = {
  selectedModel: PropTypes.object,
  setSelectedModel: PropTypes.func,
  className: PropTypes.string,
  isDisabled: PropTypes.bool
};

SelectModel.defaultProps = {
  selectedModel: null,
  className: '',
  isDisabled: false
};

export default SelectModel;
