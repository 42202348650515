import React, { useState, useEffect } from 'react';
import {Accordion, Alert, Col, Row, Spinner} from 'react-bootstrap';
import { getClustersRejections } from 'api/clusters';
import moment from 'moment';
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function ButtonSpinner() {
  return <Spinner size="md" animation="border" role="status" />;
}

function RejectErrorComponent({id}) {
  const [clustersRejectionsHistory, setClustersRejectionsHistory] = useState({})
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [lastComment, setLastComment] = useState("");
  const styles = {
    mainContainer: {
      position: 'relative',
    },
    headerContainer: {
      position: 'relative',
      cursor: 'pointer',
    },
    wrapper: {
      position: 'absolute',
      left: 0,
      right: 0,
      background: 'inherit',
      zIndex: 100,
      marginTop: 15,
      boxShadow: 'rgba(0, 0, 0, 1) 0 20px 20px -20px'
    },
    subContainer: {
      maxHeight: 'calc(50vh + 140px)',
      overflow: 'auto',
    },
    separator: {
      borderColor: '#343a40',
    },
    topPlus: {
      position: 'absolute',
      right: -32,
      top: 2,
    },
    topMinus: {
      position: 'absolute',
      right: 16,
      top: -34,
      background: 'inherit',
      pointerEvents: 'none'
    }
  }

  const getData = () => {
  getClustersRejections(id).then(async (response) => {
    const status = await response.status;
    if (status === 200) {
      const data = await response.json();
      setClustersRejectionsHistory(data.results);
      const lastEl = data.results[0];
      let lastComment;
      if (!lastEl) {
        setIsError(true);
      } else if( lastEl.comment && lastEl.comment.length > 0) {
        lastComment=lastEl.comment;
      } else {
        lastComment = "last reject without comment";
      }
      setLastComment(lastComment);
    } else {
      setIsError(true);
    }
    setIsLoading(false);
  });
};

  useEffect(() => {
    getData();
  }, [])

  return (
    <>
      {isLoading && <Alert variant="danger"><ButtonSpinner /></Alert>}
      {!isLoading &&  (
        <Alert variant="danger" style={styles.mainContainer} className="p-3 pr-5">
          {isError ? (
            <>This cluster is rejected, but we don't have any additional info</>
          ) : (
            <Accordion>
            <Accordion.Toggle eventKey="0" as="div" style={styles.headerContainer}>
              <div className="text-truncate">
                Rejected ({clustersRejectionsHistory.length}): {lastComment}
              </div>
                <FontAwesomeIcon icon={faPlus}  style={styles.topPlus} />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0" style={styles.wrapper}>
              <>
                <FontAwesomeIcon icon={faMinus} style={styles.topMinus} />
                  <div style={styles.subContainer}>
                    {clustersRejectionsHistory.map((el, index) => (
                      <div  className="pl-3 pr-3">
                        <hr style={styles.separator} className={`${!index && 'mt-0'}`}  />
                        <div  className="mt-3 mb-3">
                          <Row>
                            <Col xs={12} md={6}>
                              <span className="text-dark mr-1">Created at:</span>
                              {el.created ? moment(el.created).format('YYYY-MM-DD HH:mm') : 'unknown'}
                            </Col>
                            <Col xs={12} md={6} className="text-md-right">
                              <span className="text-dark mr-1">Reviewer ID:</span>
                              {el.reviewer_id ? el.reviewer_id : 'unknown'}
                            </Col>
                            <Col xs={12} className='mt-1 text-break'>
                              <span className="text-dark mr-1">Comment:</span>
                              {el.comment ? el.comment: 'this reject without comment'}
                            </Col>
                          </Row>
                        </div>
                  </div>
                    ))}
                 </div>
              </>
            </Accordion.Collapse>
            </Accordion>
          )}
        </Alert>
      )}
    </>
  )
}

export default RejectErrorComponent;