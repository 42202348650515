import React, { useState, useEffect } from 'react';
import {
  removeCluster,
  removeSelectedCrops,
  rejectClusterWithComment,
  rejectCluster,
  sendCluster,
  acceptCluster,
  moveToNewCluster
} from 'api/clusters';
import { Button, FormControl, Form, Spinner, Modal } from 'react-bootstrap';
import DialogModal from 'components/Modal/Modal';
import ConfirmationModal from 'modals/ConfirmationModal';
import Select from 'react-select';

function ButtonSpinner() {
  return <Spinner size="sm" animation="border" role="status" />;
}

function DialogModalComponent({ showAlert, setShowAlert }) {
  const handleClose = () => setShowAlert(false);
  return (
    <Modal centered show={showAlert} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Not allowed action</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Sorry, you can't do it, while there are any draft cluster exist</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

function CleaningButtons({
  cluster,
  refreshData,
  setErrorMessage,
  selectedCrops,
  userType,
  stopLoading,
  stopLoadingFunction,
  moveSelected
}) {
  const { id, clusterCount } = cluster;
  const [showModal, setShownModal] = useState(false);
  const [comment, setComment] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [draftClusters, setDraftClusters] = useState([]);
  const [isAllSelectedMainCluster, setIsAllSelectedMainCluster] = useState(false);
  const commentTooLong = comment.length > 1024;

  const handleCloseModalIfRequired = (actionType) => {
    if (actionType === 'rejectClusterWithComment') {
      setShownModal(false);
      setComment('');
    }
  };

  useEffect(() => {
    setIsBusy(stopLoading);
  }, [stopLoading]);

  useEffect(() => {
    if (selectedCrops.length === 0) {
      return;
    }
    let newIsAllSelectedMainCluster = true;
    const cropsIdMainCluster = cluster.points.filter((el) => el.clusterID === 0);
    cropsIdMainCluster.forEach((el) => {
      if (!selectedCrops.includes(el.id)) {
        newIsAllSelectedMainCluster = false;
      }
    });
    setIsAllSelectedMainCluster(newIsAllSelectedMainCluster);
  }, [selectedCrops]);

  const checkResponse = async (response, actionType) => {
    if (response.status === 200) {
      refreshData();
    } else {
      await response.json()
        .then((data) => setErrorMessage(data.detail || 'Error'))
        .catch(() => setErrorMessage('Error'))
    }
    handleCloseModalIfRequired(actionType);
  };

  const handleDangerousAction = (actionType) => {
    setActionType(actionType);
    setModalShow(true);
  };

  const handleMultipleMove = async (id, params) => {
    const listOfArguments = [];
    for (let i = 1; i < clusterCount; i += 1) {
      const cropIds = cluster.points.filter((el) => el.clusterID === i).map((el) => el.id);
      listOfArguments.push(cropIds);
    }
    const listOfPromises = listOfArguments.map(
      el => moveToNewCluster(id, params, { crop_ids: el })
    );

    await  Promise.allSettled(listOfPromises)
      .then(responses => {
        const rejectedResponses = responses.filter(el => el.status ==='rejected');
         if (rejectedResponses.length > 0) {
           setErrorMessage('Sorry something went wrong');
           return;
         }
        checkResponse(responses[0].value);
      })
  }

  const handleAction = async (aActionType) => {
    const mainClusterAction = ['accept', 'reject'];
    if (clusterCount > 1 && mainClusterAction.includes(aActionType)) {
      setShowAlert(true);
      return;
    }

    stopLoadingFunction(true);
    aActionType = aActionType || actionType;
    const params = userType === 'labeler' ? null : { fix: true };

    switch (aActionType) {
      case 'send':
        await checkResponse(await sendCluster(id));
        break;
      case 'reject':
        await checkResponse(await rejectCluster(id));
        break;
      case 'rejectClusterWithComment':
        await checkResponse(await rejectClusterWithComment(id, { comment }), aActionType);
        break;
      case 'removeCluster':
        await checkResponse(await removeCluster(id, params));
        break;
      case 'removeSelectedCrops':
        await checkResponse(await removeSelectedCrops(id, params, { crop_ids: selectedCrops }));
        break;
      case 'accept':
        await checkResponse(await acceptCluster(id));
        break;
      case 'acceptAllNewClusters':
        await handleMultipleMove(id, params);
        break;
      default:
        break;
    }
    setActionType(null);
  };

  useEffect(() => {
    let excludedClusterID = -1;
    if (selectedCrops.length > 0) {
      const selectedClustersID = cluster.points.filter(el => selectedCrops.includes(el.id)).map((el) => el.clusterID);
      const makeUniq = (arr) => arr.filter((el, id) => arr.indexOf(el) === id);
      const cropsListUniq = makeUniq(selectedClustersID);
      excludedClusterID = cropsListUniq.length > 1 ? excludedClusterID : cropsListUniq[0];
    }

    const newDraftClusters = [];
    for (let i = 0; i <= clusterCount; i += 1) {
      if (i !== excludedClusterID) {
        const label = i === 0 ? 'Main' : `draft cluster #${i}`;
        const value = i;
        newDraftClusters.push({ label, value });
      }
    }
    setDraftClusters(newDraftClusters);
  }, [clusterCount, selectedCrops]);

  const onChangeSelect = (selectedOption) => {
    if (selectedOption) {
      moveSelected(selectedOption.value);
    }
  };

  const buttonClick = (aActionType) => {
    if (clusterCount > 1) {
      return setShowAlert(true);
    }
    switch (aActionType) {
      case 'rejectClusterWithComment':
        setShownModal(true);
        break;
      case 'removeCluster':
        handleDangerousAction(aActionType);
        break;
      default:
        break;
    }
  }

  return (
    <>
      <DialogModal
        title="Reject with comment"
        show={showModal}
        disabled={isBusy || commentTooLong}
        approve="approve"
        back="cancel"
        size="md"
        body={
          <Form validated="">
            <FormControl
              as="textarea"
              rows={3}
              validated
              required
              className="form-range mb-5"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            {commentTooLong && (
              <div className="error-text mt-n5 mb-3">
                comment can't be longer than 1024 symbols
              </div>
            )}
          </Form>
        }
        handleClose={() => setShownModal(false)}
        handleApprove={() => {
          handleAction('rejectClusterWithComment', id, { comment });
        }}
      />
      {id && (
        <>
          <div className="text-center mt-4">
            {userType === 'labeler' ? (
              <Button
                disabled={isBusy}
                style={{ fontSize: '13px' }}
                className="ml-3 mb-2"
                variant="default"
                onClick={() => handleAction('send')}>
                Send
                {isBusy && actionType === 'send' && <ButtonSpinner />}
              </Button>
            ) : (
              <Button
                disabled={isBusy}
                style={{ fontSize: '13px' }}
                className="ml-3 mb-2"
                variant="default"
                onClick={() => handleAction('accept')}>
                Accept
                {isBusy && actionType === 'accept' && <ButtonSpinner />}
              </Button>
            )}
            {userType !== 'labeler' && (
              <>
                <Button
                  disabled={isBusy}
                  style={{ fontSize: '13px' }}
                  className="ml-3 mb-2"
                  variant="danger"
                  onClick={() => handleAction('reject')}>
                  Reject
                  {isBusy && actionType === 'reject' && <ButtonSpinner />}
                </Button>
                <Button
                  disabled={isBusy}
                  style={{ fontSize: '13px' }}
                  className="ml-3 mb-2"
                  variant="danger"
                  onClick={() => buttonClick('rejectClusterWithComment')}>
                  Reject with comment
                </Button>
              </>
            )}
            <Button
              className="ml-3 mb-2"
              variant="danger"
              style={{ fontSize: '13px' }}
              disabled={isBusy}
              onClick={() => buttonClick('removeCluster')}>
              Remove cluster
              {isBusy && actionType === 'removeCluster' && <ButtonSpinner />}
            </Button>
            <Button
              disabled={!selectedCrops.length || isBusy || isAllSelectedMainCluster}
              className="ml-2 mb-2"
              variant="default"
              style={{ fontSize: '13px' }}
              onClick={() => handleDangerousAction('removeSelectedCrops')}>
              Remove selected crops
              {isBusy && actionType === 'removeSelectedCrops' && <ButtonSpinner />}
            </Button>
            <Button
              disabled={clusterCount === 1 || isBusy }
              className="ml-2 mb-2"
              variant="default"
              style={{ fontSize: '13px' }}
              onClick={() => handleAction('acceptAllNewClusters')}>
              Accept all new clusters
              {isBusy && actionType === 'acceptAllNewClusters' && <ButtonSpinner />}
            </Button>
            <div className="d-inline-block">
              <Select
                value=""
                onChange={onChangeSelect}
                options={draftClusters}
                placeholder="move selected to"
                className="ml-3 mb-2"
                isDisabled={!selectedCrops.length || isBusy || isAllSelectedMainCluster}
                isClearable
              />
            </div>
          </div>
          <div className="text-center mt-3">
            {`Number of crops: ${cluster.points.length}, selected: ${selectedCrops.length}`}
          </div>
        </>
      )}
      <ConfirmationModal
        showModal={modalShow}
        disabled={isBusy}
        modalAction={(value) => {
          value && handleAction();
          setModalShow(false);
        }}>
        <h3>Confirm your action</h3>
        <p>Do you really want to do this?</p>
      </ConfirmationModal>
      <DialogModalComponent showAlert={showAlert} setShowAlert={setShowAlert} />
    </>
  );
}

export default CleaningButtons;
