import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getClusterHistory } from '../../../api/clusters';
import ModalWithHistory from '../../../components/Modal/ModalWithHistory';

function ClusterHistoryModal({ clusterHistoryState, setClusterHistoryState }) {
  const [clusterHistory, setClusterHistory] = useState(undefined);

  const requestClusterHistory = (id) => {
    getClusterHistory(id).then(async (response) => {
      const status = await response.status;
      if (status === 200) {
        const data = await response.json();
        setClusterHistory(data);
      }
    });
  };

  useEffect(() => {
    if (!clusterHistoryState.clusterId) return;
    setClusterHistory(undefined);
    requestClusterHistory(clusterHistoryState.clusterId);
  }, [clusterHistoryState.clusterId]);

  const handleClose = () => {
    setClusterHistoryState({
      clusterId: clusterHistoryState.clusterId,
      showModal: false
    });
  };

  return (
    <ModalWithHistory
      handleClose={handleClose}
      show={clusterHistoryState.showModal}
      data={clusterHistory}
    />
  );
}

ClusterHistoryModal.propTypes = {
  clusterHistoryState: PropTypes.object,
  setClusterHistoryState: PropTypes.func
};

ClusterHistoryModal.defaultProps = {
  clusterHistoryState: {}
};

export default ClusterHistoryModal;
