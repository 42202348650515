import { getDataWithToken } from './base';

export const getJobTypes = (params) => {
  const pathname = 'job_types/';
  const props = params || { page_size: 999 };
  return getDataWithToken(pathname, props);
};


export const getAppVersion = () => {
  const pathname = 'version/'
  return getDataWithToken(pathname);
}