import React, { useState, useEffect } from 'react';
import CropLists from 'components/CropLists/CropLists';
import { Button, Row, Col, Alert, Spinner } from 'react-bootstrap';
import { getPreFiltering, postSelectCluster, postDiscardCluster } from 'api/clusters';
import Switch from 'components/Buttons/Switch';
import JobTypeDropdown from 'components/Buttons/JobTypeDropdown';
import CropProducts from 'views/CropLabelingProcess/CropProducts';
import { useUserContext } from 'contexts/Users';
import useSettings from 'contexts/SettingsContext';
import { getJobTypes } from 'api/jobTypes';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';
import ConfirmationModal from 'modals/ConfirmationModal';
import ClusterViewHeader from './ClusterViewHeader';
import { CropPreview } from './CropPreview';

function PreFiltering() {
  const [jobTypes, setJobTypes] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [preFiltering, setPreFiltering] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [previewCrop, setPreviewCrop] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [isBusy, setIsBusy] = useState(false);

  const { isLabeler, assignJobTypes, setAssignJobTypes } = useUserContext();
  const { isAutoLoadOn, setAutoLoad } = useSettings();
  const action = 'PRE-FILTERING';

  const { predefinedSet, clearSKU, handleJobTypeChange } = useBatchLabelingContext();

  const assignLabeler = (jobType) => {
    if (jobType) {
      setSelectedJobType(jobType);
      getPreFiltering({
        predefined_classes: jobType.predefined_classes_id,
        substate: 'PRE-FILTERING'
      }).then(async (response) => {
        const data = await response.json();
        if (response.status === 200) {
          setPreFiltering(data);
          setErrorMessage('');
        } else {
          setPreFiltering({});
          setErrorMessage(data.detail);
        }
      });
    }
  };

  useEffect(() => {
    getJobTypes({ for_clustering: true, page_size: 999 }).then(async (response) => {
      const data = await response.json();
      const jobTypeList = data.results;
      if (isLabeler) {
        setAssignJobTypes();
      }
      setJobTypes(jobTypeList);
    });
  }, [isLabeler]);

  useEffect(() => {
    if (!assignJobTypes.length) return;
    const assignedJobTypes = jobTypes.filter((jobType) => jobType.id === assignJobTypes[0]);
    setJobTypes(assignedJobTypes[0]);
    assignLabeler(assignedJobTypes[0]);
  }, [assignJobTypes]);

  const getNextPreFiltering = () =>
    getPreFiltering({
      predefined_classes: selectedJobType.predefined_classes_id,
      substate: 'PRE-FILTERING'
    }).then(async (response) => {
      setIsBusy(false);
      const data = await response.json();
      if (response.status === 200) {
        setPreFiltering(data);
        setErrorMessage('');
      } else {
        setErrorMessage(data.detail);
      }
    });

  const handleDangerousAction = (actionType) => {
    setActionType(actionType);
    setModalShow(true);
  };

  const handleAction = (type) => {
    type = type || actionType;
    setIsBusy(true);
    const postAction = type === 'clean' ? postSelectCluster : postDiscardCluster;
    postAction(preFiltering?.id).then(() => {
      if (isAutoLoadOn) {
        getNextPreFiltering();
      } else {
        setPreFiltering(null);
        setIsBusy(false);
      }
    });
  };

  const onClickJobType = (jobType) => {
    assignLabeler(jobType);
    clearSKU();
    handleJobTypeChange(jobType);
  };

  return (
    <div>
      <Row>
        <div className="col-6">
          {preFiltering ? (
            <CropLists
              onPreviewClicked={(crop) => {
                setPreviewCrop(crop);
              }}
              action={action}
              crops={preFiltering?.points || []}
              setSelectedCrop={() => {}}
            />
          ) : (
            <div>
              <h4>You are in auto-load off, if you want load next data please turn-on auto-load</h4>
            </div>
          )}
        </div>
        <div className="col-6">
          {errorMessage ? <Alert variant="danger">{errorMessage}</Alert> : null}
          <div className="card" style={{ position: 'sticky', top: '15px' }}>
            <ClusterViewHeader
              clusterId={preFiltering?.id}
              title={`Cluster ID: ${preFiltering?.id || ''}`}
            />
            <Row className="mt-2">
              <Col xs={6}>
                {isLabeler ? (
                  <div className="text-center">Job Type: {assignJobTypes[0]}</div>
                ) : (
                  <JobTypeDropdown
                    predefinedClass
                    selectedJobType={selectedJobType}
                    onClickJobType={(jobType) => onClickJobType(jobType)}
                    jobTypeList={jobTypes}
                  />
                )}
                {preFiltering?.id ? (
                  <div className="mt-4 text-center">
                    <Button
                      style={{ fontSize: '13px' }}
                      className="ml-2 mr-2"
                      disabled={isBusy}
                      variant="defoult"
                      onClick={() => handleAction('clean')}
                    >
                      {isBusy && actionType === 'clean' && (
                        <Spinner size="sm" animation="border" role="status" />
                      )}
                      Should be cleaned
                    </Button>
                    <Button
                      style={{ fontSize: '13px' }}
                      className="ml-2"
                      disabled={isBusy}
                      variant="danger"
                      onClick={() => handleDangerousAction('ignore')}
                    >
                      {isBusy && actionType === 'ignore' && (
                        <Spinner size="sm" animation="border" role="status" />
                      )}
                      Ignore the cluster
                    </Button>
                  </div>
                ) : null}
              </Col>
              <Col xs="1">
                <Switch
                  name="auto-load"
                  checked={isAutoLoadOn}
                  setChecked={(e) => {
                    setAutoLoad(e);
                    if (!preFiltering) {
                      getNextPreFiltering();
                    }
                  }}
                />
              </Col>
              <Col className="d-flex justify-content-center" xs={5}>
                <CropPreview crop={previewCrop} />
              </Col>
            </Row>
            {preFiltering?.id ? (
              <div className="mb-2 px-2">
                <div style={{ marginTop: '-20px' }} className="container border-gray">
                  <CropProducts
                    predefinedSet={predefinedSet}
                    selectedJobType={selectedJobType}
                    onChange={(selectedLabel) => setSelectedLabel(selectedLabel)}
                    selectedLabel={selectedLabel}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Row>
      <ConfirmationModal
        showModal={modalShow}
        disabled={isBusy}
        modalAction={(value) => {
          value && handleAction();
          setModalShow(false);
        }}
      >
        <h3>Confirm your action</h3>
        <p>Do you really want to do this?</p>
      </ConfirmationModal>
    </div>
  );
}

export default PreFiltering;
