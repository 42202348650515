import React from 'react';
import PropTypes from 'prop-types';
import { Card, Table, Container, Row, Col, Button, Alert } from 'react-bootstrap';
import ListFilters, { labelerNameValue } from 'views/ListView/ListFilter';
import { useUserContext } from 'contexts/Users';
import ModalWithHistory from 'components/Modal/ModalWithHistory';
import { JobStateChoices } from 'enums';
import ListPaginator, { PAGE_SIZE } from 'components/Paginators/ListPaginator';
import ModalWithPreview from 'components/Modal/ModalWithPreview';
import { updateCropReference } from 'api/crops';

function List({ getData, labelerRole, crops }) {
  const [state, setState] = React.useState({
    count: 0,
    page: 1,
    results: [],
    jobType: undefined,
    jobState: undefined,
    labeler: undefined,
    reviewer: undefined,
    item: undefined,
    id: undefined,
    successMessage: undefined,
    errorMessage: undefined
  });

  const { isAdmin } = useUserContext();

  const [show, setModalShow] = React.useState(false);
  const [history, setHistory] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);

  const setRequestParameters = ({
    page = state.page,
    jobState = state.jobState,
    jobType = state.jobType,
    labeler = state.labeler,
    reviewer = state.reviewer,
    id = state.id
  }) => {
    const parameters = {
      page,
      page_size: PAGE_SIZE
    };

    if (jobState) {
      parameters.state = jobState;
    }

    if (jobType) {
      parameters.job_type = jobType;
    }

    if (labeler) {
      parameters.labeler = labeler;
    }

    if (reviewer) {
      parameters.reviewer = reviewer;
    }

    if (id) {
      parameters.id = id;
    }

    return parameters;
  };

  const loadCrops = (parameters) => {
    const params = setRequestParameters(parameters);
    getData(params)
      .then((response) => response.json())
      .then((data) => setState((prev) => ({ ...prev, ...data, page: params.page })));
  };

  const handleClose = () => setModalShow(false);
  const handleOpen = (data) => {
    setModalShow(true);
    setHistory(data);
  };

  const filterCallback = (key, value) => {
    setState((prev) => ({ ...prev, [key]: value, page: 1 }));
    loadCrops({ [key]: value, page: 1 });
  };

  const showModal = (item) => {
    setState((prev) => ({ ...prev, item }));
    crops && setShowPreview(true);
  };

  const onChange = (e, id, item) => {
    setState((prevState) => ({
      successMessage: undefined,
      errorMessage: undefined,
      results: prevState.results.map((el) =>
        el.id === id ? { ...el, reference: !el.reference } : el
      )
    }));
    updateCropReference(id, { reference: !item.reference }).then((res) => {
      if (res.status === 200) {
        setState((prev) => ({ ...prev, successMessage: `Reference ${id} updated successfully` }));
      } else {
        res
          .json()
          .then((body) =>
            setState((prev) => ({
              ...prev,
              errorMessage: body ? body.reference.toString() : 'Reference updated successfully'
            }))
          );
        setState((prevState) => ({
          results: prevState.results.map((el) =>
            el.id === id ? { ...el, reference: !el.reference } : el
          )
        }));
      }
    });
  };

  React.useEffect(() => loadCrops({}), []);

  return (
    <Container fluid>
      {state.errorMessage && <Alert variant="danger">{state.errorMessage}</Alert>}
      {state.successMessage && <Alert variant="success">{state.successMessage}</Alert>}
      <ModalWithHistory data={history} handleClose={handleClose} show={show} />
      <ModalWithPreview
        item={state.item}
        handleClose={() => setShowPreview(false)}
        show={showPreview}
      />
      <Row>
        <ListFilters filterCallback={filterCallback} labelerRole={labelerRole} />
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Job List (Total: {state.count})</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-4">
              {state.count > PAGE_SIZE && (
                <ListPaginator
                  total={state.count}
                  current={state.page}
                  previous={state.previous}
                  next={state.next}
                  changePage={loadCrops}
                />
              )}
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th className="border-0">ID</th>
                    <th className="border-0">Label ID</th>
                    <th className="border-0">State</th>
                    <th className="border-0">Job Type</th>
                    <th className="border-0">Labeler</th>
                    <th className="border-0">Created</th>
                    {crops && <th className="border-0">History</th>}
                    {crops && <th className="border-0">Reference</th>}
                  </tr>
                </thead>
                <tbody>
                  {state.results.map((item) => (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td
                        style={crops ? { textDecoration: 'underline', cursor: 'pointer' } : {}}
                        onClick={() => showModal(item)}
                      >
                        {item.label_id}
                      </td>
                      <td>{JobStateChoices[item.state]}</td>
                      <td>{item.job_type.name}</td>
                      <td>{item.labeler ? labelerNameValue(item.labeler) : ''}</td>
                      <td>{item.created}</td>
                      {crops && item.history.length ? (
                        <td>
                          <Button
                            onClick={() => handleOpen(item.history)}
                            size="sm"
                            variant="defoult"
                          >
                            <i style={{ fontWeight: 'bold' }} className="fa fa-history" />
                          </Button>
                        </td>
                      ) : (
                        <span />
                      )}
                      {crops ? (
                        <td>
                          <div
                            style={{ height: '50px' }}
                            className="d-flex justify-content-center align-items-center 
            custom-control custom-switch custom-switch-md mr-3"
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="customSwitch1"
                              checked={item.reference}
                              disabled={!isAdmin}
                            />
                            <label
                              onClick={(e) => onChange(e, item.id, item)}
                              className="custom-control-label"
                              htmlFor="customSwitch1"
                            >
                              <div style={{ marginTop: '5px' }}> &nbsp;</div>
                            </label>
                          </div>
                        </td>
                      ) : (
                        <span />
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

List.propTypes = {
  getData: PropTypes.func,
  labelerRole: PropTypes.string
};

List.defaultProps = {
  getData: () => {},
  labelerRole: ''
};

export default List;
