import React from 'react';
import PropTypes from 'prop-types';
import NotificationAlert from 'react-notification-alert';

export const NotificationPlace = Object.freeze({
  TOP_LEFT: 'tl',
  TOP_CENTER: 'tc',
  TOP_RIGHT: 'tr',
  BOTTOM_LEFT: 'bl',
  BOTTOM_CENTER: 'bc',
  BOTTOM_RIGHT: 'br'
});

export const NotificationType = Object.freeze({
  PRIMARY: 'primary',
  SUCCESS: 'success',
  DANGER: 'danger',
  WARNING: 'warning',
  INFO: 'info'
});

function BasicNotification({ show, type, text, place, autoDismiss }) {
  const notificationAlertRef = React.useRef(null);

  const notify = (place) => {
    let options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{text}</div>
        </div>
      ),
      type: type,
      icon: 'nc-icon nc-bell-55',
      autoDismiss: autoDismiss || 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  React.useEffect(() => {
    if (show) {
      notify(place);
    }
  }, [show]);

  return (
    <div className="rna-container">
      <NotificationAlert ref={notificationAlertRef} />
    </div>
  );
}

BasicNotification.propTypes = {
  text: PropTypes.string,
  show: PropTypes.bool,
  type: PropTypes.string,
  place: PropTypes.string
};

BasicNotification.defaultProps = {
  show: false,
  type: NotificationType.WARNING,
  text: '',
  place: NotificationPlace.TOP_CENTER
};

export default BasicNotification;
