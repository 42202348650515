import React from 'react';
import PropTypes from 'prop-types';

function Switch({ checked, setChecked, label, name }) {
  return (
    <div
      className="d-flex justify-content-center align-items-center 
        custom-control custom-switch custom-switch-md mr-3"
    >
      <input
        type="checkbox"
        className="custom-control-input"
        id={name}
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
      />
      <label style={{ cursor: 'pointer' }} className="custom-control-label" htmlFor={name}>
        <div style={{ marginTop: '5px' }}>{label || 'Auto load'}</div>
      </label>
    </div>
  );
}

Switch.propTypes = {
  className: PropTypes.string
};

Switch.defaultProps = {};

export default Switch;
