import React from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import ArrayDropdown from 'components/Buttons/ArrayDropdown';
import SpinnerButton from 'components/Buttons/SpinnerButton';

function ActionView({
  onChangeImage,
  setState,
  selectedConfig,
  configs,
  imageSource,
  searchCrops,
  isLoading,
  matches,
  createCrops,
  currentLoaded,
  imageURL
}) {
  return (
    <>
      <div style={{ position: 'sticky', top: '-1px', zIndex: '1000', marginBottom: '15px' }}>
        <Card>
          <Card.Body className="table-full-width table-responsive px-3">
            <div className="row col-xl-12 m-2">
              <input className="pt-2 pr-3" type="file" onChange={onChangeImage} />
              <ArrayDropdown
                items={configs}
                onClick={(selectedConfig) =>
                  setState((prev) => ({ ...prev, selectedConfig, matches: [], error: undefined }))
                }
                selected={selectedConfig}
                defaultValue="Please Select Configuration"
              />
              <SpinnerButton
                disabled={!imageSource || !selectedConfig}
                onClick={searchCrops}
                isLoading={isLoading}
                value={matches.length !== 0 ? 'Clear' : 'Search'}
              />
              &nbsp;&nbsp;
              <button
                disabled={matches.length === 0}
                onClick={() =>
                  setState((prev) => {
                    const { matches, selected } = prev;
                    const updatedMatches = matches.map((el, idx) => {
                      if (idx >= currentLoaded) {
                        return el;
                      }
                      if (!el.prevSelected) {
                        return { ...el, selected: !selected };
                      }
                      return el;
                    });

                    return { ...prev, matches: updatedMatches, selected: !selected };
                  })
                }
                type="button"
                className="btn btn-fill mr-2 ml-2"
              >
                Select/Unselect All Visible
              </button>
              &nbsp;&nbsp;
              <button
                onClick={() => setState((prev) => ({ ...prev, showFilterModal: true }))}
                type="button"
                className="btn btn-fill mr-2"
              >
                Filters
              </button>
              &nbsp;&nbsp;
              <button
                disabled={matches.filter((item) => item.selected === true).length === 0}
                onClick={createCrops}
                type="button"
                className="btn btn-fill btn-success mr-2"
              >
                Create
              </button>
              {matches && matches.length ? (
                <div className="stats ml-2 d-flex align-items-center">
                  <span style={{ color: '#a9a9a9' }}>Selected:</span>&nbsp;
                  <span style={{ color: '#333' }}>
                    {matches.filter((item) => item.selected === true).length}
                  </span>
                </div>
              ) : null}
            </div>
          </Card.Body>
        </Card>
      </div>
      <div style={{ position: 'sticky', top: '100px' }}>
        <img alt="" src={imageURL} height="250px" />
      </div>
    </>
  );
}

ActionView.propTypes = {
  onChangeImage: PropTypes.func,
  setState: PropTypes.func,
  selectedConfig: PropTypes.string,
  configs: PropTypes.array,
  imageSource: PropTypes.any,
  searchCrops: PropTypes.func,
  isLoading: PropTypes.bool,
  matches: PropTypes.array,
  nearestNeighbors: PropTypes.number,
  createCrops: PropTypes.func
};

ActionView.defaultProps = {
  onChangeImage: () => {},
  setState: () => {},
  selectedConfig: '',
  configs: [],
  imageSource: '',
  searchCrops: () => {},
  isLoading: false,
  matches: [],
  createCrops: () => {},
  nearestNeighbors: 20
};

export default ActionView;
