import { getData } from './base';

export const getPredefinedClasses = () => {
  const pathname = 'proxy/brandbank/predefined_classes/';
  return getData(pathname, null, { retryOnUnath: true });
};

export const getPredefinedClass = (props) => {
  const pathname = `labels/`;
  return getData(pathname, props, { retryOnUnath: true });
};

export const getCropPredefinedClass = (id) => {
  const pathname = `proxy/brandbank/predefined_classes/${id}/`;
  return getData(pathname, null, { retryOnUnath: true });
};
