import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useUserContext } from 'contexts/Users';

function PublicRoute({ component, ...rest }) {
  const { isAuthenticated, isExternal, isViewer } = useUserContext();

  let redirectPathname = '/dashboard';
  if (isExternal) redirectPathname = '/user';
  if(isViewer) redirectPathname = '/audit-reports';

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: redirectPathname
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
}

PublicRoute.propTypes = {
  component: PropTypes.any
};

export default PublicRoute;
