import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup, Row, Col } from 'react-bootstrap';
import DialogModal from './Modal';

function ModalWithReProcess({ show, handleClose, data }) {
  return (
    <DialogModal
      title="Re-process"
      size="lg"
      body={
        <ListGroup>
          {(data || []).map(({ cluster_id, state, status }) => (
            <ListGroup.Item>
              <Row>
                <Col xs={12}>
                  Cluster id: <b>{cluster_id}</b>
                </Col>
                <Col xs={12}>
                  State: <b>{state}</b>
                </Col>
                <Col xs={12}>
                  status: <b>{`${status}`}</b>
                </Col>
              </Row>
            </ListGroup.Item>
          ))}
        </ListGroup>
      }
      handleClose={handleClose}
      show={show}
    />
  );
}

ModalWithReProcess.propTypes = {
  title: PropTypes.string,
  body: PropTypes.any,
  back: PropTypes.string,
  approve: PropTypes.string,
  show: PropTypes.bool,
  handleApprove: PropTypes.func,
  handleClose: PropTypes.func
};

ModalWithReProcess.defaultProps = {
  title: '',
  body: '',
  back: '',
  approve: '',
  show: false,
  handleApprove: () => {},
  handleClose: () => {}
};

export default ModalWithReProcess;
