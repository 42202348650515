import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

function ArrayDropdown({ items, list, onClick, disabled, defaultValue, openDropdown }) {
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (items.length) {
      const options = items.reduce((acc, currItem) => {
        acc.push({ label: currItem, value: currItem });
        return acc;
      }, []);
      setOptions(options);
    }
  }, [items]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 180,
      maxWidth: 850,
      minHeight: 50,
      zIndex: 0
    }),
    option: (provided, { data, selectProps }) => ({
      ...provided,
      color: data.rejected && data.rejected.includes(selectProps.rejected) ? '#55AB94' : ''
    })
  };

  const onChange = (selectedOption) => {
    setSelectedJobType(selectedOption);
    const value = selectedOption ? selectedOption.value : '';
    onClick(value);
  };

  return (
    <Select
      value={selectedJobType}
      styles={customStyles}
      onChange={onChange}
      options={list || options}
      placeholder={defaultValue}
      isSearchable
      isClearable
      isDisabled={!!disabled}
      required
      className="mr-3 ml-3"
      onMenuOpen={openDropdown}
    />
  );
}

ArrayDropdown.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string
};

ArrayDropdown.defaultProps = {
  items: [],
  onClick: () => {},
  disabled: false,
  defaultValue: 'Please Select'
};

export default ArrayDropdown;
