import React, { Component } from 'react';
import { Cookies } from 'react-cookie';
import PropTypes from 'prop-types';
import { makeLogin, getUser, getLabelersJobTypes } from 'api/user';
import { HttpStatus, clusterStations, userRole } from 'enums';

const UserContext = React.createContext();

export const useUserContext = () => React.useContext(UserContext);

class UserContextProvider extends Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    this.state = {
      isAuthenticated: false,
      isLoading: true,
      error: false,
      userInfo: {},
      showSidebar: true,
      assignJobTypes: [],
      labelerJobTypes: [],
      accessToStation: '',
      isLabeler: '',
      isAdmin: '',
      isQC: ''
    };
  }

  componentDidMount() {
    this.isLogged();
  }

  isLogged = () => {
    if (this.cookies.get('user')) {
      const data = this.cookies.get('user');
      this.setUser(data);
    } else {
      this.setState((prev) => ({ ...prev, isLoading: false }));
    }
  };

  setAssignJobTypes = () => {
    /* eslint-disable */
    const userInfo = this.state.userInfo || this.cookies.get('user');
    getLabelersJobTypes(userInfo.labeler).then(async (res) => {
      if (res.status === HttpStatus.SUCCESS) {
        const results = await res.json();
        const assignJobTypes = results.allowed_job_types;
        const labelerJobTypes = results;
        this.setState({ assignJobTypes });
        this.setState({ labelerJobTypes });
      } else {
        this.clearUser();
      }
    });
  };

  handleUserResponse = async (response) => {
    if (response.status === HttpStatus.SUCCESS) {
      const data = await response.json();
      const userInfo = { ...data };
      if (userInfo.is_active) {
        const userPermission = userInfo.permission;
        const isLabeler = userPermission === userRole.labeler;
        const isAdmin = userPermission === userRole.admin;
        const isQC = userPermission === userRole.qc;
        const isViewer = userPermission === userRole.viewer;
        const isExternal = userPermission === userRole.external;
        this.setState((prev) => ({
          ...prev,
          isLoading: false,
          isAuthenticated: true,
          error: false,
          accessToStation: clusterStations[userInfo.assigned_station],
          userInfo,
          isLabeler,
          isAdmin,
          userPermission,
          isQC,
          isViewer,
          isExternal
        }));
      } else {
        this.clearUser();
      }
    } else {
      this.clearUser();
    }
  };

  getUserInformation = (id = this.state.id) => {
    getUser(id)
      .then(this.handleUserResponse)
      .catch(() => this.clearUser());
  };

  setUser = (data) => {
    const { id } = data;
    this.cookies.set('user', data, { secure: true, sameSite: 'none' });
    this.setState((prev) => ({ ...prev, isLoading: true, id }));

    this.getUserInformation(id);
  };

  clearUser = () => {
    this.cookies.remove('user');
    this.setState((prev) => ({
      ...prev,
      isLoading: false,
      isAuthenticated: false,
      error: true,
      userInfo: {}
    }));
  };

  makeLogout = () => {
    this.cookies.remove('user');
    this.setState((prev) => ({
      ...prev,
      isAuthenticated: false,
      userInfo: {}
    }));
  };

  onLogin = async (props) => {
    this.setState((prev) => ({ ...prev, isLoading: true, error: false }));
    const response = await makeLogin(props);
    if (response.status === HttpStatus.SUCCESS) {
      await response.json().then((data) => {
        this.setUser(data);
      });
    } else {
      this.clearUser();
    }
  };

  onLogout = () => {
    this.makeLogout();
  };

  isClusteringLabeler = (item) => {
    return (this.state.isLabeler || this.state.isQC) && item.name === 'Clustering';
  };

  render() {
    const { children } = this.props;
    const {
      isAuthenticated,
      isLoading,
      error,
      userInfo,
      assignJobTypes,
      labelerJobTypes,
      isLabeler,
      isAdmin,
      isQC,
      isExternal,
      isViewer,
      userPermission,
      accessToStation
    } = this.state;

    return (
      <UserContext.Provider
        value={{
          onLogin: this.onLogin,
          setAssignJobTypes: this.setAssignJobTypes,
          onLogout: this.onLogout,
          isClusteringLabeler: this.isClusteringLabeler,
          getUserInformation: this.getUserInformation,
          isAuthenticated,
          isLoading,
          error,
          userInfo,
          assignJobTypes,
          labelerJobTypes,
          isLabeler,
          isAdmin,
          isQC,
          isExternal,
          isViewer,
          accessToStation,
          userPermission
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

UserContextProvider.propTypes = {
  children: PropTypes.node
};

UserContextProvider.defaultProps = {
  children: null
};

export default UserContextProvider;
