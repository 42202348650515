import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CropImage from 'components/Images/CropImageSVG';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';
import { getFeaturestorePhotoUrl } from 'api/featurestore';
import DialogModal from './Modal';

function ModalWithPreview({ show, handleClose, item }) {
  const { fetchPrereviewCrop, prereviewCrop } = useBatchLabelingContext();

  useEffect(() => {
    if (item) {
      fetchPrereviewCrop(item);
    }
  }, [item]);

  const openInNewTab = () => {
    window.open(getFeaturestorePhotoUrl(item.photo_id), '_blank', 'noopener,noreferrer');
  };

  return (
    <DialogModal
      title="Prereview Crop:"
      body={
        <div onClick={openInNewTab}>
          <CropImage
            width="400px"
            height="400px"
            disabled
            source={prereviewCrop?.source.crop}
            rectangle={prereviewCrop?.source.rect}
          />
        </div>
      }
      handleClose={handleClose}
      show={show}
    />
  );
}

ModalWithPreview.propTypes = {
  title: PropTypes.string,
  body: PropTypes.any,
  back: PropTypes.string,
  approve: PropTypes.string,
  show: PropTypes.bool,
  handleApprove: PropTypes.func,
  handleClose: PropTypes.func
};

ModalWithPreview.defaultProps = {
  title: '',
  body: '',
  back: '',
  approve: '',
  show: false,
  handleApprove: () => {},
  handleClose: () => {}
};

export default ModalWithPreview;
