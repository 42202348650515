import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, ListGroup, Card } from 'react-bootstrap';
import ListPaginator, { PAGE_SIZE } from 'components/Paginators/ListPaginator';
import { useBatchLabelingContext } from 'contexts/BatchLabelingProcess';
import SingleSKUReferenceItem from './SingleSKUReferenceItem';

function CropSKUReference({ onClick, selectedLabel, action }) {
  const { loadPredefinedClass, clearSKU, count, previous, page, next, predefinedSet } =
    useBatchLabelingContext();

  useEffect(() => {
    clearSKU();
  }, [clearSKU]);

  const listStyle = {
    height: '320px',
    overflowY: 'scroll'
  };

  const ListItemStyle = { padding: '0 0 0 15px' };

  const changePage = (currentPage) => {
    loadPredefinedClass(selectedLabel, currentPage.page);
  };

  return (
    <>
      <h6 className="pb-2">SKU reference:</h6>

      {count === 0 && <Alert variant="danger">No results</Alert>}

      {predefinedSet && predefinedSet.length > 0 && (
        <Card>
          <ListGroup variant="flush" style={listStyle}>
            {predefinedSet.map((item) => (
              <ListGroup.Item key={item.name} style={ListItemStyle}>
                <SingleSKUReferenceItem
                  name={item.name}
                  selectedLabel={selectedLabel}
                  onClick={() => onClick(item)}
                  photos={item.photos}
                />
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Card.Footer>
            {count > PAGE_SIZE && selectedLabel && (
              <ListPaginator
                total={count}
                next={next}
                previous={previous}
                current={page}
                changePage={changePage}
              />
            )}
          </Card.Footer>
        </Card>
      )}
    </>
  );
}

CropSKUReference.propTypes = {
  newLabel: PropTypes.string,
  onClick: PropTypes.func,
  selectedLabel: PropTypes.string,
  action: PropTypes.string
};

CropSKUReference.defaultProps = {
  newLabel: '',
  onClick: () => {},
  selectedLabel: '',
  action: ''
};

export default CropSKUReference;
