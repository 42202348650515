import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { authenticate } from 'api/imageFinder';
import moment from 'moment';

const ImageFinderContext = React.createContext();

export const useImageFinderContext = () => React.useContext(ImageFinderContext);

class ImageFinderProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: '',
      error: ''
    };
  }

  getToken = async () => {
    const { exp, token } = this.state;
    const currentTime = moment();
    const tokenExpiration = moment.unix(exp);
    const refreshThreshold = moment(tokenExpiration).subtract(2, 'minutes');
    if (!exp || currentTime.isAfter(refreshThreshold)) {
      try {
        const token = await authenticate();
        const { exp } = JSON.parse(atob(token.split('.')[1]));
        this.setState({ token, exp });
        return token;
      } catch (error) {
        this.setState({ error: error.toString() });
      }
    }
    return token;
  };

  render() {
    const { children } = this.props;
    return (
      <ImageFinderContext.Provider
        value={{
          ...this.state,
          getToken: this.getToken
        }}
      >
        {children}
      </ImageFinderContext.Provider>
    );
  }
}

ImageFinderProcess.propTypes = {
  children: PropTypes.node
};

ImageFinderProcess.defaultProps = {
  children: null
};

export default ImageFinderProcess;
