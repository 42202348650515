import React, { useState, useEffect } from 'react';
import { getJobTypes } from 'api/jobTypes';
import { getLabelers, updateJobType } from 'api/user';
import { Row, Col, Card, ListGroup } from 'react-bootstrap';
import Select from 'react-select';
import SpinnerButton from 'components/Buttons/SpinnerButton';
import BasicNotification from 'components/Notifications/BasicNotification';

function LabelersJobTypes() {
  const [labelers, setLabelers] = useState([]);
  const [selectedLabelers, setSelectedLabelers] = useState([]);
  const [jobTypesOptions, setJobTypeOptions] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState(null);
  const [allowedJobTypes, setAllowedJobTypes] = useState({});
  const [selectedFilter, setSelectedFilter] = useState('');
  const [isLoading, setIsLading] = useState(false);
  const [showSuccessNotification, setSuccessNotification] = useState(false);
  const [showErrorNotification, setErrorNotification] = useState(false);

  function setJobTypes(jobTypeRes, setOptions) {
    const jobTypeList = jobTypeRes.results;
    const jobs = [];
    jobTypeList.map(({ id, name }) => {
      jobs.push({ value: id, label: `${name}-${id}` });
      return jobs;
    });
    setOptions(jobs);
  }

  function setLabelersJobType(labelersRes) {
    const labelersList = labelersRes.results;
    const labelersArr = [];
    const assignedJobTypes = {};
    labelersList.forEach(({ id, user, allowed_job_types, completed_job_types }) => {
      allowed_job_types.forEach((job) => {
        if (job && !assignedJobTypes[job]) {
          assignedJobTypes[job] = [];
        }
        if (job && assignedJobTypes[job]) {
          assignedJobTypes[job].push({ name: user.username, id: id });
        }
      });
      labelersArr.push({
        value: id,
        label: `${user.username}`,
        completedJobType: completed_job_types
      });
    });
    setAllowedJobTypes(assignedJobTypes);
    setLabelers(labelersArr);
  }

  useEffect(() => {
    Promise.all([
      getJobTypes().then((value) => value.json()),
      getLabelers().then((value) => value.json())
    ]).then(([jobTypeRes, labelersRes]) => {
      setJobTypes(jobTypeRes, setJobTypeOptions);
      setLabelersJobType(labelersRes);
    });
  }, []);

  const assignJobType = () => {
    setIsLading(true);
    const promises = [];
    selectedLabelers.map((labeler) =>
      promises.push(
        updateJobType(`${labeler.value}/`, { allowed_job_types: [selectedJobType.value] })
      )
    );
    Promise.all(promises)
      .catch(() => setErrorNotification(true))
      .then((responses) => {
        const success = responses.every((response) => response.status === 200);
        if (success) {
          setSuccessNotification(true);
        } else {
          setErrorNotification(true);
        }
        setSelectedJobType('');
        setSelectedLabelers('');
        getLabelers().then(async (response) => {
          const data = await response.json();
          setLabelersJobType(data);
        });
      })
      .finally(() => {
        setIsLading(false);
      });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: 180,
      maxWidth: 850,
      minHeight: 50
    }),
    option: (provided, { data, selectProps }) => ({
      ...provided,
      color:
        data.completedJobType && data.completedJobType.includes(selectProps.selectedJobType)
          ? '#55AB94'
          : ''
    }),
    multiValue: (provided, { data, selectProps }) => ({
      ...provided,
      background:
        data.completedJobType && !data.completedJobType.includes(selectProps.selectedJobType)
          ? '#FFA17A'
          : ''
    })
  };

  function setJobTypeName(key) {
    const jobTypeName = jobTypesOptions.filter(
      (item) => item && key && item.value && item.value.toString() === key.toString()
    );
    return jobTypeName[0] ? jobTypeName[0].label : '';
  }

  const isNotValid = !selectedJobType || !selectedLabelers.length;
  const isDisabled = isLoading || isNotValid;

  function allowedJobTybesList(assignedJobTypes) {
    return (
      <Row>
        {Object.keys(assignedJobTypes).map((key) => (
          <Col key={key} lg="3" sm="6">
            <Card>
              <Card.Body>
                <h4>{setJobTypeName(key)}</h4>
                <ListGroup variant="flush">
                  {assignedJobTypes[key].map((el) => (
                    <ListGroup.Item key={el.id}>{el.name}</ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
              <Card.Footer>
                <b>total labelers: {assignedJobTypes[key].length}</b>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    );
  }

  function selectedJobTybesList() {
    const { value } = selectedFilter;
    return (
      <Row>
        <Col lg="3" sm="6">
          <Card>
            <Card.Body>
              <h4>{jobTypesOptions.filter((item) => item.value === value)[0].label}</h4>
              <ListGroup variant="flush">
                {allowedJobTypes[value] &&
                  allowedJobTypes[value].map((el) => (
                    <ListGroup.Item key={el.id}>{el.name}</ListGroup.Item>
                  ))}
              </ListGroup>
            </Card.Body>
            <Card.Footer>
              <b>total labelers: {allowedJobTypes[value] ? allowedJobTypes[value].length : 0}</b>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    );
  }
  return (
    <Col md="12">
      <BasicNotification
        show={showSuccessNotification}
        type="success"
        text="Successfully assigned"
      />
      <br />
      <BasicNotification show={showErrorNotification} type="danger" text="Error" />
      <Card className="strpied-tabled-with-hover pb-2">
        <Card.Header>
          <Card.Title as="h4">Add labelers to job types</Card.Title>
        </Card.Header>
        <Card.Body className="table-full-width table-responsive px-3">
          <Row>
            <Select
              value={selectedJobType}
              styles={customStyles}
              onChange={setSelectedJobType}
              options={jobTypesOptions}
              placeholder="Select Job Type"
              isSearchable
              isClearable
              required
              className="mr-3 ml-3"
            />
            <div className="d-flex justify-content-center align-items-center">
              <i className="fas fa-arrow-right" />
            </div>
            <Select
              value={selectedLabelers}
              selectedJobType={selectedJobType ? selectedJobType.value : ''}
              styles={customStyles}
              onChange={setSelectedLabelers}
              options={labelers}
              isMulti
              placeholder="Select Labelers"
              isSearchable
              className="mr-3 ml-3"
              required
              isClearable
            />
            <SpinnerButton
              style={{ height: '50px' }}
              isLoading={isLoading}
              disabled={isDisabled}
              onClick={assignJobType}
              value="Add"
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="strpied-tabled-with-hover pb-2">
        <Card.Header>
          <Card.Title as="h4">Search jobTypes</Card.Title>
        </Card.Header>
        <Card.Body className="table-full-width table-responsive px-3">
          <Row>
            <Select
              defaultValue={selectedFilter}
              styles={customStyles}
              onChange={setSelectedFilter}
              options={jobTypesOptions}
              placeholder="Select Job Type"
              isSearchable
              className="mr-3 ml-3"
              isClearable
            />
          </Row>
        </Card.Body>
      </Card>
      {selectedFilter && selectedFilter.value
        ? selectedJobTybesList()
        : allowedJobTybesList(allowedJobTypes)}
    </Col>
  );
}

export default LabelersJobTypes;
