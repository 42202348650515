import React from 'react';
import { Button } from 'react-bootstrap';

export function CropListLoadMoreButton({ onClick, remainingCrops }) {
  return (
    <Button variant="default" onClick={onClick}>
      Load More ({remainingCrops} crops left)
    </Button>
  );
}
