// TODO: fixme
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import Table from 'components/Table/Table';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProductionReportFilters from 'views/ProductionReports/ProductionReportFilters';
import { getAuditReport } from 'api/auditReport';
import moment from 'moment';
import { PAGE_SIZE } from '../../components/Paginators/ListPaginator';

function ProductionReports() {
  const [rowNames, setRowNames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [page, setPage] = useState(1);
  const [parameters, setParameters] = useState({ ordering: '-created_at' });
  const [error, setError] = useState(false);

  const columnNames = [
    'Client',
    'Containers',
    'Model',
    'Th. Model',
    'Th. Analysis',
    'date from',
    'date to',
    'Created',
    'Period',
    'Images',
    'Crops',
    'Accuracy',
    'Errors count',
    'SKU count',
    'Download Report'
  ];

  const setAuditReport = (params) => {
    getAuditReport({ page_size: PAGE_SIZE, ...params }).then(
      async (response) => {
        const status = await response.status;
        if (status === 200) {
          await response
            .json()
            .then((source) => {
              const rowNames = [];
              source.results.forEach((item) => {
                rowNames.push({
                  Client: item.client,
                  Containers: item.containers
                    ? item.containers.map((el, i) => {
                        if (i === 0) {
                          return el;
                        }
                        return (
                          <>
                            ,<br />
                            {el}
                          </>
                        );
                      })
                    : [],
                  Model: item.model,
                  'Th. Model': item.threshold_model,
                  'Th. Analysis': item.threshold_analysis,
                  'date from': moment(item.date_from).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                  'date to': moment(item.date_to).format('YYYY-MM-DD HH:mm:ss'),
                  Created: moment(item.created_at).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                  Period: item.period,
                  Images: item.images_count,
                  Crops: item.crops_count,
                  Accuracy: `${(item.accuracy * 100).toFixed(2)}%`,
                  'Errors count': (
                    <>
                      {item.crops_checked_count} / {item.crops_count}
                      <br />
                      <span className="text-muted">
                        {(
                          100 *
                          (item.crops_checked_count / item.crops_count)
                        ).toFixed(2)}
                        %
                      </span>
                    </>
                  ),
                  'SKU count': (
                    <Link
                      to={{
                        pathname: `/audit-report-details/${item.id}`,
                        state: { crop: item }
                      }}
                    >
                      <Button variant="default">
                        {item.sku_count} <i className="nc-icon nc-stre-right" />
                      </Button>{' '}
                    </Link>
                  ),
                  'Download Report': (
                    <Button
                      className="text-center"
                      onClick={() => window.open(item.report_url, '_blank')}
                      variant="default"
                    >
                      <i className="nc-icon nc-cloud-download-93" />
                    </Button>
                  )
                });
              });
              setRowNames(rowNames);
              setCount(source.count);
              setNext(source.next);
              setPrevious(source.previous);
              setError(false);
            })
            .catch(() => setError(true));
        } else {
          setError(true);
        }
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    setAuditReport({ page: 1, ...parameters });
  }, []);

  const loadData = (params) => {
    setPage(params.page);
    setAuditReport({ page: params.page, ...parameters });
  };

  const filterCallback = (selectedClient, selectedModel, selectedSorting) => {
    setIsLoading(true);
    const parameters = { ordering: selectedSorting };
    if (selectedClient?.value) parameters.client = selectedClient?.value;
    if (selectedModel?.value) parameters.model = selectedModel?.value;
    setPage(1);
    setParameters(parameters);
    setAuditReport({ page: 1, ...parameters });
  };

  return (
    <>
      <ProductionReportFilters
        filterCallback={filterCallback}
        isLoading={isLoading}
      />
      {error ? (
        <div className="alert alert-danger">Sorry, something went wrong</div>
      ) : (
        <Table
          loadData={loadData}
          count={count}
          page={page}
          next={next}
          previous={previous}
          rowNames={rowNames}
          columnNames={columnNames}
          isLoading={isLoading}
        />
      )}
    </>
  );
}

export default ProductionReports;
