import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/scss/main.scss';
import './assets/css/demo.css';
import 'react-image-crop/dist/ReactCrop.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Router from 'routers/Router';
import ContextWrapper from 'contexts/ContextWrapper';
import AdminLayout from './layouts/Admin';

if (process.env.REACT_APP_ENVIROMENT !== 'local') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIROMENT
  });
}

ReactDOM.render(
  <BrowserRouter>
    <ContextWrapper>
      <AdminLayout>
        <Router />
      </AdminLayout>
    </ContextWrapper>
  </BrowserRouter>,
  document.getElementById('root')
);
