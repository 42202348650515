/* eslint react/no-array-index-key: "off" */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CropFromImage from 'components/Images/CropFromImage';
import CropImage from 'components/Images/CropImageSVG';
import { ImageFinderPageSize } from 'localConstants';
import { Button } from 'react-bootstrap';
import ModalWithSVGShape from 'components/Modal/ModalWithSVGShape';
import ModalWithPreview from 'components/Modal/ModalWithPreview';

const textSlicer = (text = '', max = 40) => (text.length > max ? `${text.slice(0, max)}...` : text);

const renderCropImage = (cropInfo, index) => {
  let renderedCrop;
  if (cropInfo.metadata.fs_label_id) {
    renderedCrop = (
      <>
        <CropImage
          key={index}
          disabled
          width="150"
          height="250"
          source={cropInfo?.source}
          rectangle={cropInfo?.rect}
        />
        <p style={{ position: 'relative', width: '200px' }}>{textSlicer(cropInfo.metadata.name)}</p>
      </>
    );
  } else {
    renderedCrop = (
      <>
        <CropFromImage
          xMin={cropInfo.metadata.x_min}
          yMin={cropInfo.metadata.y_min}
          yMax={cropInfo.metadata.y_max}
          xMax={cropInfo.metadata.x_max}
          url={cropInfo.metadata.url}
          index={index}
        />
        <p style={{ position: 'relative', width: '200px' }}>{textSlicer(cropInfo.metadata.name)}</p>
      </>
    );
  }
  return renderedCrop;
};

function MatchesView({ matches, toogleCrop, currentLoaded, setState }) {
  const [showPreview, setShowPreview] = useState(false);
  const [showSVGShape, setShowSVGShape] = useState(false);
  const [dimensions, setDimensions] = useState({});
  const [item, setItem] = useState(undefined);

  const showModal = (crop) => {
    if (crop.metadata.fs_label_id) {
      setItem({
        label_id: crop.metadata.fs_label_id,
        photo_id: crop.metadata.image_id
      });
      setShowPreview(true);
    } else {
      setDimensions(crop.metadata);
      setShowSVGShape(true);
    }
  };

  return (
    <div style={{ marginTop: '-275px' }} className="row mb-3">
      <ModalWithSVGShape
        crop={dimensions}
        handleClose={() => setShowSVGShape(false)}
        show={showSVGShape}
      />
      <ModalWithPreview item={item} handleClose={() => setShowPreview(false)} show={showPreview} />
      <div className="row col-xl-6" />
      <div className="row col-xl-6 d-flex flex-wrap">
        {matches &&
          matches.slice(0, currentLoaded).map((cropInfo, index) => (
            <div key={index} className="p-2 black">
              <div
                onClick={() => toogleCrop(cropInfo)}
                style={
                  cropInfo.prevSelected
                    ? { opacity: '0.3', pointerEvents: 'none' }
                    : { cursor: 'pointer' }
                }
                className={`col maintxt p-2 ${cropInfo.selected ? 'bg-primary' : 'bg-secondary'}`}
              >
                {renderCropImage(cropInfo, index)}
              </div>
              <Button
                className="mr-2"
                onClick={() => showModal(cropInfo)}
                size="sm"
                variant="defoult"
              >
                <i className="fa fa-eye" />
              </Button>
            </div>
          ))}
      </div>
      <div className="pull-right">
        {matches.length > 20 && matches.length > currentLoaded && (
          <button
            className="btn btn-fill"
            type="button"
            onClick={() =>
              setState((prev) => ({
                ...prev,
                currentLoaded: prev.currentLoaded + ImageFinderPageSize
              }))
            }
          >
            LOAD MORE
          </button>
        )}
      </div>
    </div>
  );
}

MatchesView.propTypes = {
  matches: PropTypes.array,
  toogleCrop: PropTypes.func,
  currentLoaded: PropTypes.number,
  setState: PropTypes.func
};

MatchesView.defaultProps = {
  matches: [],
  toogleCrop: () => {},
  currentLoaded: ImageFinderPageSize,
  setState: () => {}
};

export default MatchesView;
