import React from 'react';
import { useUserContext } from 'contexts/Users';
import SpinnerButton from 'components/Buttons/SpinnerButton';

function Login() {
  const { onLogin, isLoading, error } = useUserContext();
  const [state, setState] = React.useState({
    username: '',
    password: ''
  });

  const onChange = (event) =>
    setState((prev) => ({ ...prev, [event.target.name]: event.target.value }));

  const onSubmit = (event) => {
    event.preventDefault();
    onLogin({ ...state });
  };

  return (
    <div className="container">
      <div>
        {error && (
          <div className="alert alert-danger">
            <strong>
              Sorry, I don't recognize your username and/or password. Could you give it another try?
            </strong>
          </div>
        )}
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            onChange={onChange}
            required
            className="form-control input-lg"
            type="text"
            name="username"
            id="username"
            placeholder="Username"
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            onChange={onChange}
            required
            className="form-control input-lg"
            type="password"
            name="password"
            placeholder="password"
          />
        </div>
        <div className="form-group">
          <SpinnerButton
            disabled={isLoading || state.password === '' || state.username === ''}
            onClick={onSubmit}
            isLoading={isLoading}
            value="Login"
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
