import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {getBBox} from 'api/clusters';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBottleWater} from '@fortawesome/free-solid-svg-icons';
import ModalWithSVGShape from "../../components/Modal/ModalWithSVGShape";

export function CropPreview({crop}) {
    const [showSVGShape, setShowSVGShape] = useState(false);
    const [dimensions, setDimensions] = useState({});
    const [showModalError, setShowModalError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const url = crop ? crop.crop_path : null;

    const showModal = () => {
        getBBox(crop.id).then(async (response) => {
            const status = await response.status;
            if (status === 200) {
                const data = await response.json();
                const result = {
                    ...data,
                    url: data.source_img_url,
                }
                setDimensions(result);
                setShowSVGShape(true);
            } else if (status === 204) {
                setErrorText('For this crop there is no original job image available.');
                setShowModalError(true);
            } else {
                setErrorText('Sorry, something went wrong');
                setShowModalError(true);
            }
        });
    };

    return (
        <div style={{width: '270px', height: '270px'}} className="card p-1">
            <ModalWithSVGShape
                crop={dimensions}
                handleClose={() => {
                    setShowSVGShape(false);
                }}
                show={showSVGShape}
            />
            <Modal
                show={showModalError}
                onHide={() => setShowModalError(false)}
            >
                <Modal.Header closeButton> </Modal.Header>
                <Modal.Body>{errorText}</Modal.Body>
            </Modal>
            {url ? (
                <img
                    onClick={() => showModal()}
                    style={{width: '100%', height: '100%', objectFit: 'contain', cursor: 'pointer'}}
                    src={url}
                    alt="crop-preview"
                />
            ) : (
                <FontAwesomeIcon
                    style={{padding: '50px', height: '50%', color: 'lightgray'}}
                    icon={faBottleWater}
                />
            )}
        </div>
    );
}
