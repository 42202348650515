import React from 'react';
import PropTypes from 'prop-types';
import { useCropReviewProcessContext } from 'contexts/CropReviewProcess';
import { getFeaturestorePhotoUrl } from 'api/featurestore';
import CropImage from 'components/Images/CropImageSVG';
import { getRectagle } from 'utils';
import CropDetails from './CropDetails';

function CropReviewProcessBase({ children }) {
  const { error, errorMessage, cropSource, cropInfo, photoObjects, fetchJobTypeList } =
    useCropReviewProcessContext();

  React.useEffect(() => {
    fetchJobTypeList();
  }, []);

  return (
    <div>
      {error && (
        <div className="alert alert-danger">
          <strong>{errorMessage || <>Sorry, Something went wrong.</>}</strong>
        </div>
      )}
      <div>
        <div className="row">
          <div className="col-xl-6">
            <div className="row">
              <div className="col-xl-6">
                <h6>Crop photo:</h6>
                <CropImage
                  width="150px"
                  height="150px"
                  disabled
                  source={cropSource?.crop}
                  rectangle={cropSource?.rect}
                />
              </div>
              <div className="col-xl-6">
                <CropDetails />
              </div>
              {children}
            </div>
          </div>
          <div className="col-xl-6">
            <h6>Main Photo:</h6>
            {cropInfo && photoObjects.length > 0 && (
              <CropImage
                width="600px"
                height="600px"
                disabled
                source={getFeaturestorePhotoUrl(cropInfo.photo_id)}
                rectangle={getRectagle(cropInfo.label_id, photoObjects)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

CropReviewProcessBase.propTypes = {
  children: PropTypes.node
};

CropReviewProcessBase.defaultProps = {
  children: {}
};

export default CropReviewProcessBase;
