import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ModalWithImage({ imageUrl, altText, showModal, handleClose }) {
  return (
    <Modal centered show={showModal} onHide={handleClose}>
      <Modal.Header closeButton />
      <Modal.Body>
        <img src={imageUrl} alt={altText} style={{ width: '100%' }} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalWithImage;
