import React from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { postImportImages } from 'api/imageImporter';
import { getJobTypes } from 'api/jobTypes';
import ArrayDropdown from 'components/Buttons/ArrayDropdown';
import ActionModal from './ActionModal';

function ImageImporterModal({ showModal, setShowModal, file }) {
  const [state, setState] = React.useState({
    message: undefined,
    errorMessage: undefined,
    jobTypesItems: [],
    selectedJobType: '',
    selectedJobTypeId: '',
    doubleLabeling: false
  });

  const closeModal = () => {
    setState({
      message: undefined,
      errorMessage: undefined,
      jobTypesItems: [],
      selectedJobType: '',
      selectedJobTypeId: '',
      doubleLabeling: false
    });
    setShowModal(false);
  };

  const modalAction = async (accepted) => {
    if (accepted) {
      const props = {};
      if (state.selectedJobTypeId) {
        props.job_type_id = state.selectedJobTypeId;
      }

      if (state.doubleLabeling) {
        props.double_labeling = state.doubleLabeling;
      }

      postImportImages(file, props).then(async (response) => {
        const status = await response.status;
        if (status === 200) {
          const message = await response.json();
          setState((prev) => ({ ...prev, message }));
        } else {
          const errorMessage = await response.json();
          setState((prev) => ({ ...prev, errorMessage }));
        }
      });
    } else {
      closeModal();
    }
  };

  React.useEffect(() => {
    if (showModal) {
      getJobTypes().then(async (response) => {
        const data = await response.json();
        const jobTypeList = data.results.reduce((acc, currItem) => {
          acc.push({ label: `${currItem.name}-${currItem.id}`, value: currItem.id });
          return acc;
        }, []);
        setState((prev) => ({ ...prev, jobTypeList }));

        setState((prev) => ({
          ...prev,
          jobTypeList,
          selectedJobType: '',
          selectedJobTypeId: '',
          doubleLabeling: false
        }));
      });
    }
  }, [showModal]);

  return (
    <ActionModal
      disabledAcceptButton={state.message !== undefined}
      modalAction={modalAction}
      showModal={showModal}
    >
      {state.message && (
        <div>
          <p>{`Created task: ${state.message.task}`}</p>
        </div>
      )}
      {state.errorMessage && <Alert variant="danger">{state.errorMessage.error}</Alert>}
      {!state.message && !state.errorMessage && (
        <div>
          <div>
            <ArrayDropdown
              list={state.jobTypeList}
              onClick={(source) =>
                setState((prev) => ({
                  ...prev,
                  selectedJobType: source,
                  selectedJobTypeId: source
                }))
              }
              defaultValue="Select Job Type"
            />
            {state.selectedJobType && (
              <>
                <label htmlFor="doubleLabeling">&nbsp;&nbsp;Double labeling:&nbsp;&nbsp;</label>
                <input
                  type="radio"
                  id="doubleLabeling"
                  name="doubleLabeling"
                  checked={state.doubleLabeling}
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      doubleLabeling: !prev.doubleLabeling
                    }))
                  }
                  disabled={!state.selectedJobType}
                />
              </>
            )}
            <p>Are you sure?</p>
          </div>
        </div>
      )}
    </ActionModal>
  );
}

ImageImporterModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func
};

ImageImporterModal.defaultProps = {
  showModal: false,
  setShowModal: () => {}
};

export default ImageImporterModal;
