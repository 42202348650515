import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

function ConfirmationModal({ modalAction, showModal, children, disabled }) {
  return (
    <Modal
      className="modal-primary"
      show={showModal}
      centered
      onHide={() => modalAction(false)}
      size="lg">
      <Modal.Body className="text-center">{children}</Modal.Body>
      <div className="modal-footer">
        <Button
          disabled={disabled}
          className="btn"
          type="button"
          variant="link"
          onClick={() => modalAction(false)}>
          Close
        </Button>
        <Button
          disabled={disabled}
          className="btn btn-fill"
          type="button"
          variant="link"
          onClick={() => modalAction(true)}>
          Accept
        </Button>
      </div>
    </Modal>
  );
}

ConfirmationModal.propTypes = {
  modalAction: PropTypes.func,
  showModal: PropTypes.bool
};

ConfirmationModal.defaultProps = {
  modalAction: () => {},
  showModal: false
};

export default ConfirmationModal;
