import { UserType } from 'enums';
import { getData, postDataAuthorized, putDataAuthorized, patchDataAuthorized } from './base';

export const getCrops = (props) => {
  const pathname = 'crop_jobs/';
  return getData(pathname, props);
};

export const createCropJobs = (props) => {
  const pathname = 'crop_jobs_task/';
  return postDataAuthorized(pathname, props);
};

export const createCropJob = (props) => {
  const pathname = 'crop_job/';
  return postDataAuthorized(pathname, props);
};

export const reorderPoints = (clusterId, props) => {
  const pathname = `clusters/${clusterId}/reorder_points/`;
  return postDataAuthorized(pathname, props);
};

export const updateCropStatus = (userType, cropId, props) => {
  let pathname = userType === UserType.LABELER ? 'labeler/crop_jobs/' : 'crop_jobs/';
  pathname = `${pathname}${cropId}/`;
  return putDataAuthorized(pathname, props);
};

export const updateCropReference = (cropId, props) => {
  let pathname = 'crop_jobs/';
  pathname = `${pathname}${cropId}/`;
  return patchDataAuthorized(pathname, props);
};

export const getNextCrop = (userType, props) => {
  const pathname = userType === UserType.LABELER ? 'labeler/crop_jobs/' : 'crop_jobs/';
  return getData(pathname, { ...props, action: 'next' });
};

export const getCropJobsLabels = (props) => {
  const pathname = 'labels/crop_jobs/';
  return getData(pathname, props);
};

export const getSimilarCrops = (props) => {
  const pathname = 'similar_crop_jobs/';
  return getData(pathname, props);
};
