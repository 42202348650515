import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
  return (
    <footer className="footer px-0 px-lg-3">
      <Container fluid>
        <nav />
      </Container>
    </footer>
  );
}

export default Footer;
