export const IS_AUTO_LOAD_ON_KEY = 'isAutoLoadOn';
export const IS_LABELER_MODE_ON_KEY = 'isLabelerModeOn';

export const initialState = {
  isAutoLoadOn: localStorage.getItem(IS_AUTO_LOAD_ON_KEY) === 'true',
  isLabelerMode: localStorage.getItem(IS_LABELER_MODE_ON_KEY) === 'true'
};

export function SettingsReducer(state, action) {
  switch (action.type) {
    case 'AutoLoadValueChanged':
      return { ...state, isAutoLoadOn: action.isAutoLoadOn };
    case 'LabelerModeValueChanged':
      return { ...state, isLabelerMode: action.isLabelerMode };
    default:
      return state;
  }
}
