import { getData, putDataAuthorized, BASE_URL } from './base';

export const getContainers = () => {
  const pathname = 'proxy/featurestore/containers/';
  return getData(pathname);
};

export const searchLabels = (props) => {
  // All of the fields should be compatible with feature store search fields. Django server I use only as a proxy!!!!.
  const pathname = 'proxy/featurestore/search';
  return getData(pathname, props);
};

export const searchCrops = (props) => {
  const pathname = 'proxy/featurestore/search_objects';
  return getData(pathname, props);
};

export const getCrop = (labelId, props) => {
  const pathname = `proxy/featurestore/crop/${labelId}/`;
  return getData(pathname, props);
};

export const getPhoto = (photoId) => {
  const pathname = `proxy/featurestore/photo/${photoId}/`;
  return getData(pathname);
};

export const getPhotoObjects = (photoId, props) => {
  const pathname = `proxy/featurestore/photo_objects/${photoId}/`;
  return getData(pathname, props);
};

export const getFeaturestorePhotoUrl = (photoId) => {
  const pathname = `proxy/featurestore/photo/${photoId}/?photo_as_url=true`;
  return BASE_URL + pathname;
};

export const updateLabelName = (labelId, props) => {
  const pathname = `proxy/featurestore/crop/${labelId}/`;
  return putDataAuthorized(pathname, props);
};
