export const calculateStatePerDay = (params) => {
  const { finishedArr, state } = params;
  const count = {};
  const completed = [];
  const jobType = [];
  const seriesName = [];
  finishedArr.forEach((el) => {
    if (state.includes(el.state)) {
      completed.push(el);
      if (!jobType.includes(el.job_type)) {
        jobType.push(el.job_type);
        seriesName.push({ name: el.job_type, data: [] });
      }
    }
  });
  completed.sort((a, b) => (a.created > b.created ? 1 : -1));
  completed.forEach((i) => {
    const date = i.created.split('T')[0];

    if (!count[date]) {
      count[date] = jobType.reduce((ac, a) => ({ ...ac, [a]: 0 }), {});
    }
    count[date][i.job_type] = (count[date][i.job_type] || 0) + 1;
  });
  return { count, seriesName };
};
