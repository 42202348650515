import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Cookies } from 'react-cookie';

const LayoutContext = React.createContext();

export const useLayoutContext = () => React.useContext(LayoutContext);

class LayoutContextProvider extends Component {
  constructor(props) {
    super(props);
    this.cookies = new Cookies();
    const layout = this.cookies.get('layout');
    this.state = {
      showSidebar: layout !== undefined ? layout.showSidebar : true
    };
  }

  toogleSidebar = () => {
    const { showSidebar } = this.state;
    this.cookies.set('layout', { showSidebar: !showSidebar });
    this.setState((prev) => ({ ...prev, showSidebar: !showSidebar }));
  };

  render() {
    const { children } = this.props;
    const { showSidebar } = this.state;
    return (
      <LayoutContext.Provider
        value={{
          toogleSidebar: this.toogleSidebar,
          showSidebar
        }}
      >
        {children}
      </LayoutContext.Provider>
    );
  }
}

LayoutContextProvider.propTypes = {
  children: PropTypes.node
};

LayoutContextProvider.defaultProps = {
  children: null
};

export default LayoutContextProvider;
