import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, FormControl, Form } from 'react-bootstrap';
import ArrayDropdown from 'components/Buttons/ArrayDropdown';
import { ImageFinderSelectors } from 'enums';

function PineconeFilterSelector({ filter, selectors, description, setFilter }) {
  return (
    <div className="row pb-4">
      <p>{description}</p>
      <div className="row">
        <ArrayDropdown
          items={selectors}
          onClick={(selector) => setFilter({ ...filter, selector })}
          selected={filter.selector}
        />
        <FormControl
          className="col-xl-6"
          disabled={!filter.selector}
          onChange={(event) => setFilter({ ...filter, value: event.target.value })}
          type="text"
          value={filter.value ? filter.value : ''}
        />
      </div>
    </div>
  );
}

PineconeFilterSelector.propTypes = {
  filter: PropTypes.object,
  selectors: PropTypes.array,
  description: PropTypes.string,
  setFilter: PropTypes.func
};

PineconeFilterSelector.defaultProps = {
  filter: {
    selector: ''
  },
  selectors: [],
  description: '',
  setFilter: () => {}
};

function ImageFinderFilterModal({ showModal, setShowModal, setState, state }) {
  const setFilter = (filterName, value) =>
    setState((prev) => ({
      ...prev,
      filter: { ...prev.filter, [filterName]: value }
    }));

  const selectors = Object.keys(ImageFinderSelectors);

  const checkNumber = (value) => value > 1000 || value < 1;
  const { nearestNeighbors } = state;

  return (
    <Modal className="modal-primary" show={showModal} centered onHide={() => setShowModal(false)}>
      <Modal.Body className="modal-body">
        <div className="container">
          <div className="row">
            <p className="">Nearest Neighbors:</p>
          </div>
          <div>
            <div className="row pb-4">
              <Form validated={checkNumber(nearestNeighbors)}>
                <FormControl
                  validated
                  type="number"
                  required
                  min="1"
                  max="100"
                  style={{ height: '45px', width: '150px' }}
                  className="form-range"
                  value={nearestNeighbors}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      nearestNeighbors: e.target.value
                    }))
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {nearestNeighbors < 1 && 'the minimum value is 1'}
                  {nearestNeighbors > 1000 && 'the minimum value is 1000'}
                </Form.Control.Feedback>
              </Form>
            </div>
          </div>
          <PineconeFilterSelector
            filter={state.filter?.name}
            selectors={selectors}
            setFilter={(value) => setFilter('name', value)}
            description="Select crops with specific label name:"
          />
          <PineconeFilterSelector
            filter={state.filter?.uuid}
            selectors={selectors}
            setFilter={(value) => setFilter('uuid', value)}
            description="Select crops with specific uuid:"
          />
        </div>
      </Modal.Body>
      <div className="modal-footer">
        <Button
          disabled={checkNumber(nearestNeighbors)}
          className="btn"
          type="submit"
          variant="link"
          onClick={() => setShowModal(false)}
        >
          Ok
        </Button>
      </div>
    </Modal>
  );
}

ImageFinderFilterModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setState: PropTypes.func,
  state: PropTypes.object
};

ImageFinderFilterModal.defaultProps = {
  showModal: false,
  setShowModal: () => {},
  setState: () => {},
  state: undefined
};

export default ImageFinderFilterModal;
